import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, ProductPriceDefinition} from 'two-core';

class ProductPriceDefinitionsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.productPriceDefinitions ?? '';
  }

  async getProductPriceDefinitions(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateProductPriceDefinition(data: ProductPriceDefinition): Promise<ProductPriceDefinition> {
    return this.patch(`${this.endpoint}/${data.product_id}/${data.revision_id}`, data)
      .then(data => {
        return Promise.resolve(data as ProductPriceDefinition);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProductPriceDefinition(data: ProductPriceDefinition): Promise<ProductPriceDefinition> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as ProductPriceDefinition);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ProductPriceDefinitionsService;
