import React from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import ApplicationsService from '../../services/ApplicationsService';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  applicationId: number | undefined;
}

interface State {
  loading: boolean;
  application: Application | undefined;
}

class AddEditApplicationDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  applicationsService: ApplicationsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      application: undefined,
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.setApplication = this.setApplication.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.applicationsService = this.context.applicationsService;
    this.toastService = this.context.toastService;
  }

  setApplication() {
    const id = this.props.applicationId;
    if (id) {
      this.loadApplication(id);
    } else {
      const emptyApplication: Application = {
        id: 0,
        name: '',
        description: '',
        url: '',
      };
      this.setState({application: emptyApplication});
    }
  }

  hideDialog() {
    this.setState({application: undefined, loading: false});
    this.props.onHide();
  }

  loadApplication(id: number) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.applicationsService
      ?.getApplications(params)
      .then(data => {
        const dataRecords = (data?.records as Application[]) ?? [];
        const application = dataRecords[0];

        this.setState({
          application: application,
          loading: false,
        });
      })
      .catch(error => {
        this.props.toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, Application load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const application = this.state.application;
    if (application) {
      if (application.id) {
        this.updateAplication(application);
      } else {
        this.createAplication(application);
      }
    }
  }

  async createAplication(application: Application) {
    this.setState({loading: true});

    return this.applicationsService
      ?.createApplication(application)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Aplication created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.aplicationUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Aplication create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  async updateAplication(application: Application) {
    this.setState({loading: true});

    return this.applicationsService
      ?.updateApplication(application?.id?.toString() ?? '', application)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Application updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.aplicationUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Application update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  setValue(title: string, value: string) {
    const application = this.state.application;
    if (application) {
      const updatedApplication: Application = {
        ...application,
        [title]: value,
      };
      this.setState({application: updatedApplication});
    }
  }

  render() {
    const {application} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                className="w-100"
                value={application?.name ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('name', value);
                }}
              />
            </span>
          </div>
          <label htmlFor="url" className="p-col-1">
            url
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                className="w-100"
                value={application?.url ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('url', value);
                }}
              />
            </span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="description" className="p-col-1">
            description
          </label>
          <div className="p-col-11 p-p-0">
            <span className="p-fluid">
              <InputTextarea
                rows={5}
                value={application?.description ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('description', value);
                }}
              />
            </span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={application?.id && application.id !== 0 ? 'Edit Application' : 'Add Application'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={50}
        onShow={this.setApplication}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEditApplicationDialog;
