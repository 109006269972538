import React from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, QueryParameter, Role} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import RolesService from '../../services/RolesService';
import {Checkbox, CheckboxChangeParams} from 'primereact/checkbox';
import './AddEditRoleDialog.scss';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  application: Application | undefined;
  roleId: number | undefined;
}

interface State {
  loading: boolean;
  role: Role | undefined;
  permissions: string[];
}

class AddEditRoleDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      role: undefined,
      permissions: [],
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.save = this.save.bind(this);
    this.setAndLoadData = this.setAndLoadData.bind(this);
    this.loadRole = this.loadRole.bind(this);
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
  }

  setAndLoadData() {
    const application = this.props.application;
    const roleId = this.props.roleId;
    if (roleId) {
      this.setState({
        permissions: application?.permissions ?? [],
      });
      this.loadRole(roleId);
    } else {
      const emptyRole: Role = {
        id: 0,
        name: '',
        description: '',
        application_id: application?.id ?? 0,
        permissions: [],
      };
      this.setState({
        role: emptyRole,
        permissions: application?.permissions ?? [],
      });
    }
  }

  hideDialog() {
    this.setState({role: undefined, loading: false});
    this.props.onHide();
  }

  loadRole(id: number) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.rolesService
      ?.getRoles(params)
      .then(data => {
        const dataRecords = (data?.records as Role[]) ?? [];
        const role = dataRecords[0];

        this.setState({
          role: role,
          loading: false,
        });
      })
      .catch(error => {
        this.props.toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, Role load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const role = this.state.role;
    if (role) {
      if (role.id) {
        this.updateRole(role);
      } else {
        this.createRole(role);
      }
    }
  }

  createRole(role: Role) {
    this.setState({loading: true});

    this.rolesService
      ?.createRole(role)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Role created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.roleUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Role create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  updateRole(role: Role) {
    this.setState({loading: true});

    this.rolesService
      ?.updateRole(role?.id?.toString() ?? '', role)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Role updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.roleUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Role update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  setValue(title: string, value: string) {
    const role = this.state.role;
    if (role) {
      const updatedRole: Role = {
        ...role,
        [title]: value,
      };
      this.setState({role: updatedRole});
    }
  }

  onPermisionChange = (e: CheckboxChangeParams) => {
    const role = this.state.role!;
    const selectedPermissions = role?.permissions ? [...role.permissions] : [];

    if (e.checked) {
      selectedPermissions.push(e.value);
    } else {
      const indexToRemove = selectedPermissions.indexOf(e.value, 0);
      if (indexToRemove > -1) {
        selectedPermissions.splice(indexToRemove, 1);
      }
    }
    const updatedRole: Role = {
      ...role,
      permissions: selectedPermissions,
    };

    this.setState({role: updatedRole});
  };

  render() {
    const {application} = this.props;
    const {role, permissions} = this.state;
    const selectedPermissions = role?.permissions ?? [];

    const dialogBody = application ? (
      <>
        <div className="add-edit-role-component p-col-12">
          <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
            <label htmlFor="name" className="p-col-1">
              name
            </label>
            <div className="p-col-3 p-p-0">
              <span className="p-fluid">
                <InputText
                  value={role?.name ?? ''}
                  onChange={e => {
                    const value = e.target.value;
                    this.setValue('name', value);
                  }}
                />
              </span>
            </div>
          </div>
          <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
            <label htmlFor="description" className="p-col-1">
              description
            </label>
            <div className="p-col-11 p-p-0">
              <span className="p-fluid">
                <InputTextarea
                  rows={5}
                  value={role?.description ?? ''}
                  onChange={e => {
                    const value = e.target.value;
                    this.setValue('description', value);
                  }}
                />
              </span>
            </div>
          </div>
          <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
            <label htmlFor="permissions" className="p-d-flex p-col-1">
              permissions
            </label>
            <div className="p-grid w-100">
              {permissions.map(permission => (
                <div className="p-col-6 p-md-3" key={permission}>
                  <Checkbox
                    inputId={permission}
                    key={permission}
                    value={permission}
                    onChange={this.onPermisionChange}
                    checked={selectedPermissions.some(item => item === permission)}
                  />
                  <label className="p-pl-2">{permission}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    ) : (
      <span>Application is not selected. Role without application can not exist.</span>
    );

    return (
      <TwoDialog
        headerTitle={role?.id && role.id !== 0 ? 'Edit Role' : 'Add Role'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={70}
        onShow={this.setAndLoadData}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEditRoleDialog;
