import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, QueryParameter, Role} from 'two-core';
import {Toast} from 'primereact/toast';
import RolesService from '../../services/RolesService';
import {messages} from '../../config/messages';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';

const mergeWithOption = 'Merge With';
const overwriteOption = 'Overwrite';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  role: Role | undefined;
  application: Application;
}

interface State {
  loading: boolean;
  selectedCopyOption: string;
  selectedRole: Role | undefined;
  roles: Role[];
}

class CopyRolePermissionsDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedCopyOption: mergeWithOption,
      selectedRole: undefined,
      roles: [],
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.save = this.save.bind(this);
    this.loadRoles = this.loadRoles.bind(this);
    this.setCopyOptionValue = this.setCopyOptionValue.bind(this);
    this.setRoleValue = this.setRoleValue.bind(this);
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
  }

  loadRoles() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'application_id',
        value: this.props.application?.id ?? 0,
      })
    );

    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.role?.id,
        condition: '!=',
      })
    );

    const params: QueryParameter = {
      filters: filters,
    };

    this.rolesService
      ?.getRoles(params)
      .then(data => {
        const roles = data.records as Role[];

        this.setState({
          roles: roles,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  hideDialog() {
    this.setState({loading: false, selectedRole: undefined});
    this.props.onHide();
  }

  save() {
    const selectedRole = this.state.selectedRole;
    if (selectedRole) {
      const option = this.state.selectedCopyOption;
      const role = this.props.role!;

      if (option === mergeWithOption) {
        const selectedRolePermissions = selectedRole.permissions ?? [];
        const rolePermissions = role.permissions ?? [];
        const mergedPermissions = selectedRolePermissions.concat(
          rolePermissions.filter(item => selectedRolePermissions.indexOf(item) < 0)
        );
        const updatedRole: Role = {
          ...role,
          permissions: mergedPermissions,
        };
        this.updateRole(updatedRole);
      } else if (option === overwriteOption) {
        const overwrittenPermissions = selectedRole.permissions ?? [];
        const updatedRole: Role = {
          ...role,
          permissions: overwrittenPermissions,
        };
        this.updateRole(updatedRole);
      }
    }
  }

  updateRole(role: Role) {
    this.setState({loading: true});

    this.rolesService
      ?.updateRole(role?.id?.toString() ?? '', role)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Role updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.roleUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Role update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  setCopyOptionValue(e: DropdownChangeParams) {
    const value = e.value;
    this.setState({selectedCopyOption: value});
  }

  setRoleValue(e: DropdownChangeParams) {
    const value = e.value;
    this.setState({selectedRole: value});
  }

  render() {
    const {role} = this.props;

    const dialogBody = (
      <>
        <div className="p-col-12 p-d-flex p-ai-center p-pl-0 p-pr-0 p-pt-0">
          <div>
            I want to copy all the permissions from &nbsp;
            <span className="p-text-bold">{` ${role?.name}`}</span>&nbsp;to &nbsp;
          </div>

          <div className="p-col-4 p-p-0">
            <Dropdown
              className="w-100"
              value={this.state.selectedRole}
              options={this.state.roles}
              onChange={this.setRoleValue}
              optionLabel="name"
              placeholder="Select a Role"
            />
          </div>
        </div>
        <div className="p-col-12 p-p-0 p-d-flex p-ai-center">
          <div>And I want these to &nbsp;</div>

          <div className="p-col-4 p-p-0">
            <Dropdown
              className="w-100"
              value={this.state.selectedCopyOption}
              options={[mergeWithOption, overwriteOption]}
              onChange={this.setCopyOptionValue}
              placeholder="Select a Copy Option"
            />
          </div>
          <div>
            &nbsp; the existing permissions in the role
            <span className="p-text-bold">{` ${role?.name}`}.</span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Copy Permissions'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={40}
        onHide={this.hideDialog}
        onSave={this.save}
        onShow={this.loadRoles}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default CopyRolePermissionsDialog;
