import React from 'react';
import {AppContext} from 'two-app-ui';
import {Application} from 'two-core';
import RoleListComponent from '../Roles/RoleListComponent';

interface Props {
  application: Application;
}

class ApplicationRoles extends React.Component<Props> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <RoleListComponent application={this.props.application} />
      </div>
    );
  }
}

export default ApplicationRoles;
