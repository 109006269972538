import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {QueryParameter, SkuDepartment} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import SkuDepartmentService from '../../services/SkuDepartmentService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  sku: string | undefined;
}

interface State {
  loading: boolean;
  saving: boolean;
  skuDepartment: SkuDepartment | undefined;
}

class AddEditSkuDepartmentDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  skuDepartmentService: SkuDepartmentService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      skuDepartment: undefined,
    };

    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setSkuDepartment = this.setSkuDepartment.bind(this);
  }

  componentDidMount() {
    this.skuDepartmentService = this.context.skuDepartmentService;
    this.toastService = this.context.toastService;
  }

  setSkuDepartment() {
    const sku = this.props.sku;
    if (sku) {
      this.loadSkuDepartment(sku);
    } else {
      const empty: SkuDepartment = {
        sku: '',
        department: '',
      };
      this.setState({skuDepartment: empty});
    }
  }

  hideDialog() {
    this.setState({skuDepartment: undefined, loading: false, saving: false});
    this.props.onHide();
  }

  loadSkuDepartment(sku: string) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'sku',
        value: sku,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.skuDepartmentService
      ?.getSkuDepartments(params)
      .then(data => {
        const dataRecords = (data?.records as SkuDepartment[]) ?? [];
        const skuDepartment = dataRecords[0];

        this.setState({
          skuDepartment: skuDepartment,
          loading: false,
        });
      })
      .catch(error => {
        this.props.toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    this.setState({saving: true});
    const skuDepartment = this.state.skuDepartment;
    if (skuDepartment) {
      if (skuDepartment.id) {
        this.updateSkuDepartment(skuDepartment);
      } else {
        this.createSkuDepartment(skuDepartment);
      }
    }
  }

  async createSkuDepartment(skuDepartment: SkuDepartment) {
    return this.skuDepartmentService
      ?.createSkuDepartment(skuDepartment)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.skuDepartmentUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  async updateSkuDepartment(skuDepartment: SkuDepartment) {
    return this.skuDepartmentService
      ?.updateSkuDepartment(skuDepartment?.id ?? 0, skuDepartment)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.skuDepartmentUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  setValue(title: string, value: string | boolean) {
    const skuDepartment = this.state.skuDepartment;
    if (skuDepartment) {
      const updatedSkuDepartment: SkuDepartment = {
        ...skuDepartment,
        [title]: value,
      };
      this.setState({skuDepartment: updatedSkuDepartment});
    }
  }

  render() {
    const {skuDepartment} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="sku" className="p-col-1">
            sku
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={skuDepartment?.sku ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('sku', value);
                }}
              />
            </span>
          </div>
          <label htmlFor="department" className="p-col-1">
            department
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={skuDepartment?.department ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('department', value);
                }}
              />
            </span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={skuDepartment?.sku && skuDepartment?.sku !== '' ? 'Edit ' : 'Add new'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={60}
        onShow={this.setSkuDepartment}
        onHide={this.hideDialog}
        onSave={this.save}
        saving={this.state.saving}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEditSkuDepartmentDialog;
