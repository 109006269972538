import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Company, ProductGroup} from 'two-core';
import {Toast} from 'primereact/toast';
import ProductGroupService from '../../services/ProductGroupService';
import {messages} from '../../config/messages';
import CompanyListComponent from '../Companies/CompanyListComponent';

const currentStartingLocationsOption = 'Their Current Location(s)';
const finalDestinationsOption = 'Their Final Destinations';
const twoWarehouseOption = 'TWO Warehouse';
const differentDestinationsOption = 'Different Destinations';

export const startingLocationOptions: string[] = [currentStartingLocationsOption, twoWarehouseOption];

export const finalLocationOptions: string[] = [
  finalDestinationsOption,
  twoWarehouseOption,
  differentDestinationsOption,
];

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  productGroup: ProductGroup;
}

interface State {
  loading: boolean;
  selectedCustomers: Company[];
}

class AssignProductToProductGroupDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  toastService: ToastService | null = null;
  productGroupService: ProductGroupService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCustomers: [],
      loading: true,
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.saveAssignedCustomers = this.saveAssignedCustomers.bind(this);
    this.setSelectedCustomers = this.setSelectedCustomers.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.productGroupService = this.context.productGroupService;
  }

  componentWillUnmount() {}

  hideDialog() {
    this.setState({
      selectedCustomers: [],
    });
    this.props.onHide();
  }

  saveAssignedCustomers() {
    const productGroupId = this.props.productGroup.id as number;
    const products = this.state.selectedCustomers;
    Promise.all(
      products.map(async product => {
        this.productGroupService
          ?.createProductGroupCustomer(productGroupId, product.id as string)
          .then(() => {
            this.toastService?.showSuccess(this.props.toast, 'Customer to Product Group assigned successfully.');
          })
          .catch(error => {
            this.toastService?.showError(
              this.props.toast,
              'Sorry, assign Customer to Product Group failed, please try again.'
            );
            console.error(error);
          });
      })
    ).then(() => {
      this.hideDialog();
      MessageService.sendMessage(messages.productGroupCustomerUpdated);
    });
  }

  setSelectedCustomers(products: Company[]) {
    this.setState({selectedCustomers: products});
  }

  render() {
    const {productGroup} = this.props;

    const dialogBody = (
      <div className={'assign-production-group-detail'} style={{height: 'calc(100% - 84px)'}}>
        <CompanyListComponent setSelectedCustomers={this.setSelectedCustomers} />
      </div>
    );
    return (
      <>
        <TwoDialog
          headerTitle={`Assign Customer to Group ${productGroup.name}`}
          showDialog={this.props.showDialog}
          visible={this.props.showDialog}
          width={90}
          height={90}
          onHide={this.hideDialog}
          onSave={this.saveAssignedCustomers}
          loading={this.state.loading}
        >
          {dialogBody}
        </TwoDialog>
      </>
    );
  }
}
export default AssignProductToProductGroupDialog;
