import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {
  ToastService,
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {
  QueryParameter,
  PriceDefinitionRevisionStage,
  PriceDefinitionRevision,
  ProductPriceDefinition,
  Product,
} from 'two-core';
import config from '../../config/config';
import {DateTime} from 'luxon';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import formats from '../../config/formats';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import PriceDefinitionRevisionsService from '../../services/PriceDefinitionRevisionsService';
import {Link} from 'react-router-dom';
import ProductPriceDefinitionsService from '../../services/ProductPriceDefinitionsService';
import ReleasePriceDefinitionDialog from './ReleasePriceDefinitionDialog';
import ProductsService from '../../services/ProductsService';

interface State {
  loading: boolean;
  items: PriceDefinitionRevision[];
  selectedItems: PriceDefinitionRevision[];
  totalItems: number;
  activeFilters: {};
  filters: {
    id: string;
    stage: PriceDefinitionRevisionStage | '';
    external_id: string;
    release_notes: string;
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    updated_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    released_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddPriceDefinition: boolean;
  showReleasePriceDefinition: boolean;
  products: Product[];
}

class PriceDefinitionRevisionListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  productsService: ProductsService | null = null;
  priceDefinitionRevisionsService: PriceDefinitionRevisionsService | null = null;
  productPriceDefinitionsService: ProductPriceDefinitionsService | null = null;
  toastService: ToastService | null = null;
  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        id: '',
        stage: '',
        external_id: '',
        release_notes: '',
        created_at: {
          fromDate: null,
          toDate: null,
        },
        updated_at: {
          fromDate: null,
          toDate: null,
        },
        released_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddPriceDefinition: false,
      showReleasePriceDefinition: false,
      products: [],
    };

    this.idBodyTemplate = this.idBodyTemplate.bind(this);
    this.createdOnBodyTemplate = this.createdOnBodyTemplate.bind(this);
    this.releasedOnBodyTemplate = this.releasedOnBodyTemplate.bind(this);
    this.updatedAtBodyTemplate = this.updatedAtBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.productsService = this.context.productsService;
    this.priceDefinitionRevisionsService = this.context.priceDefinitionRevisionsService;
    this.productPriceDefinitionsService = this.context.productPriceDefinitionsService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.priceDefinitionRevisionUpdated) {
        this.loadData();
      }
    });

    this.loadData();
    this.loadProducts();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.addPriceDefinition();
      },
    });

    if (selectedItemsCount === 1) {
      const selectedItem = selectedItems[0];

      menuItems.push({
        label: 'Duplicate',
        faIcon: ['far', 'copy'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.duplicatePriceDefinition();
        },
      });

      menuItems.push({
        separator: true,
      });

      menuItems.push({
        label: 'Test',
        faIcon: ['far', 'question'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => this.testPriceDefinition(),
      });

      if (selectedItem.stage === 'Draft') {
        menuItems.push({
          label: 'Release',
          faIcon: ['far', 'check'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.releasePriceDefinition(),
        });

        menuItems.push({
          label: 'Cancel',
          faIcon: ['far', 'ban'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => this.cancelPriceDefinition(),
        });
      }
    }

    return menuItems;
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.id) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.id,
        })
      );
    }

    if (this.state.filters.stage) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.release_notes) {
      filters.push(
        JSON.stringify({
          field: 'release_notes',
          value: this.state.filters.release_notes,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }

    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.updated_at.fromDate) {
      const fromDate = this.state.filters.updated_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'updated_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }

    if (this.state.filters.updated_at.toDate) {
      const toDate = this.state.filters.updated_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'updated_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.released_at.fromDate) {
      const fromDate = this.state.filters.released_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'released_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }

    if (this.state.filters.released_at.toDate) {
      const toDate = this.state.filters.released_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'released_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'id',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.priceDefinitionRevisionsService
      ?.getPriceDefinitionRevisions(params)
      .then(data => {
        const revisions = data.records as PriceDefinitionRevision[];

        this.setState({
          items: revisions,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadProducts() {
    this.setState({loading: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'enabled',
        value: true,
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.productsService?.getProducts(params).then(data => {
      const products = (data?.records as Product[]) ?? [];
      this.setState({
        loading: false,
        products: products,
      });
    });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  addPriceDefinition() {
    const priceDefinitionRevision: PriceDefinitionRevision = {
      stage: 'Draft',
      created_at: new Date(),
      created_by: this.getCurrentUserId(),
    };
    this.priceDefinitionRevisionsService
      ?.createPriceDefinitionRevision(priceDefinitionRevision)
      .then((data: PriceDefinitionRevision) => {
        Promise.all(
          this.state.products.map(product => {
            const productPriceDefinition: ProductPriceDefinition = {
              product_id: product.id as number,
              revision_id: data.id as number,
              price_definitions: {
                product_price: {
                  sku: '',
                  price_list_type: '',
                  description_script: '',
                  qty_calculation: '',
                  unit_script: '',
                  unit_price_calculation: '',
                  line_total_calculation: '',
                },
                surcharges: [],
                discounts: [],
                freight_surcharge: {
                  sku: '',
                  group_with: [],
                  price_list_type: '',
                  description_script: '',
                  qty_calculation: '',
                  unit_script: '',
                  unit_price_calculation: '',
                  line_total_calculation: '',
                },
              },
            };

            return this.productPriceDefinitionsService?.createProductPriceDefinition(productPriceDefinition);
          })
        )
          .then(() => {
            this.toastService?.showSuccess(this.toast, 'Price definitions created.');
            this.loadData();
          })
          .catch(error => {
            this.toastService?.showError(this.toast, 'Price definitions create failed, please try again.');
            console.error(error);
            this.loadData();
          });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Price definition revision create failed, please try again.');
        console.error(error);
      });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  duplicatePriceDefinition() {
    const priceDefinitionRevision: PriceDefinitionRevision = this.state.selectedItems[0] as PriceDefinitionRevision;
    const priceDefinitionRevisionToDuplicate: PriceDefinitionRevision = {
      stage: 'Draft',
      created_at: new Date(),
      created_by: this.getCurrentUserId(),
    };

    this.priceDefinitionRevisionsService
      ?.createPriceDefinitionRevision(priceDefinitionRevisionToDuplicate)
      .then((data: PriceDefinitionRevision) => {
        priceDefinitionRevision.product_price_definitions?.forEach((productPriceDefinition: ProductPriceDefinition) => {
          const productPriceDefinitionToUpdate = {...productPriceDefinition};
          productPriceDefinitionToUpdate.revision_id = data.id as number;
          this.productPriceDefinitionsService
            ?.createProductPriceDefinition(productPriceDefinitionToUpdate)
            .then(() => {
              this.toastService?.showSuccess(this.toast, 'Price definition duplicated.');
            })
            .catch(error => {
              this.toastService?.showError(this.toast, 'Price definition duplicate failed, please try again.');
              console.error(error);
            });
        });
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Price definition duplicate failed, please try again.');
        console.error(error);
      });
  }

  testPriceDefinition() {}

  releasePriceDefinition() {
    this.setState({showReleasePriceDefinition: true});
  }

  cancelPriceDefinition() {
    const priceDefinitionRevisionToCancel = this.state.selectedItems[0];
    priceDefinitionRevisionToCancel.stage = 'Cancelled';
    priceDefinitionRevisionToCancel.cancelled_at = new Date();
    priceDefinitionRevisionToCancel.cancelled_by = this.getCurrentUserId();
    this.priceDefinitionRevisionsService
      ?.updatePriceDefinitionRevision(priceDefinitionRevisionToCancel.id as number, priceDefinitionRevisionToCancel)
      .then(() => {
        this.loadData();
      });
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  setChangeSelectedItems(items: PriceDefinitionRevision[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: PriceDefinitionRevision) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  idBodyTemplate(rowData: PriceDefinitionRevision) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <Link to={`/price-definition/${rowData?.id}`}>{rowData.id}</Link>
      </AppColumnMenuBodyTemplate>
    );
  }

  createdOnBodyTemplate(rowData: PriceDefinitionRevision) {
    const formattedUpdated = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  updatedAtBodyTemplate(rowData: PriceDefinitionRevision) {
    const formattedUpdated = rowData.updated_at
      ? DateTime.fromISO(rowData.updated_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  releasedOnBodyTemplate(rowData: PriceDefinitionRevision) {
    const formattedUpdated = rowData.released_at
      ? DateTime.fromISO(rowData.released_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  render() {
    const {showReleasePriceDefinition} = this.state;
    const idFilter = (
      <InputText
        name="id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const releaseNotesFilter = (
      <InputText
        name="release_notes"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const stageFilter = (
      <MultiSelect
        value={this.state.filters.stage}
        options={['Draft', 'Current', 'Deprecated', 'Cancelled']}
        name="stage"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
    const createdAtFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const updatedAtFilter = (
      <DateColumnFilter
        name="updated_at"
        value={this.state.filters.updated_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const releasedAtFilter = (
      <DateColumnFilter
        name="released_at"
        value={this.state.filters.released_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    return (
      <div id="price_definition_revision_table_page_container" className="page-container">
        <TwoDataTable
          id={'price_definition_revision_table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'price_definition_revision_page_container'}
          initMenuItems={this.initMenuItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as PriceDefinitionRevision[])}
          selectionMode="multiple"
        >
          <Column
            header="Id"
            field="id"
            body={item => this.idBodyTemplate(item)}
            filter
            filterElement={idFilter}
            style={{width: '100px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            filter
            filterElement={stageFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Created"
            field="created_at"
            filter
            filterElement={createdAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.createdOnBodyTemplate}
          />
          <Column
            header="Last Update"
            field="updated_at"
            filter
            filterElement={updatedAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.updatedAtBodyTemplate}
          />
          <Column
            header="Released"
            field="released_at"
            filter
            filterElement={releasedAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.releasedOnBodyTemplate}
          />
          <Column
            header="Release Notes"
            field="release_notes"
            filter
            filterElement={releaseNotesFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <ReleasePriceDefinitionDialog
          showDialog={showReleasePriceDefinition}
          onHide={() => this.setState({showReleasePriceDefinition: false})}
          toast={this.toast}
          priceDefinition={this.state.selectedItems[0]}
        />
      </div>
    );
  }
}

export default PriceDefinitionRevisionListComponent;
