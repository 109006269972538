import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {AppContext, MessageService, TwoToast} from 'two-app-ui';
import {ApiListResponse, ProductDefinitionRevision, QueryParameter} from 'two-core';
import {InputTextarea} from 'primereact/inputtextarea';
import ProductDefinitionRevisionsService from '../../services/ProductDefinitionRevisionsService';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  productDefinitionRevision: ProductDefinitionRevision;
}

interface State {
  releaseNote: string;
}

class ReleaseRevisionDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  productDefinitionRevisionsService: ProductDefinitionRevisionsService | null = null;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);
    this.state = {releaseNote: ''};

    this.renderFooter = this.renderFooter.bind(this);
    this.release = this.release.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.productDefinitionRevisionsService = this.context.productDefinitionRevisionsService;
    this.twoToast = this.context.twoToast;
  }

  hideDialog() {
    this.setState({releaseNote: ''});
    this.props.onHide();
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async release() {
    const productDefinitionRevisionToRelease = this.props.productDefinitionRevision;
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'stage',
        value: 'Released',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    const data: ApiListResponse = (await this.productDefinitionRevisionsService?.getProductDefinitionRevisions(
      params
    )) as ApiListResponse;
    const revisionToDeprecate = (data.records as ProductDefinitionRevision[])[0];

    // there is a chance that there is no revision to deprecate, it means: no released revision.
    if (revisionToDeprecate) {
      await this.productDefinitionRevisionsService?.updateProductDefinitionRevision(revisionToDeprecate.id as number, {
        stage: 'Deprecated',
        updated_at: new Date(),
      });
    }

    const revision: ProductDefinitionRevision = {
      stage: 'Released',
      released_at: new Date(),
      released_by: this.getCurrentUserId(),
      release_notes: this.state.releaseNote,
    };

    return this.productDefinitionRevisionsService
      ?.updateProductDefinitionRevision(productDefinitionRevisionToRelease.id as number, revision)
      .then(() => {
        MessageService.sendMessage(messages.revisionUpdated);
        this.twoToast?.showSuccess('Price definition Released.');
        this.hideDialog();
      });
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="release"
          className={'p-mr-2'}
          onClick={() => {
            this.release();
          }}
          autoFocus
        />
      </div>
    );
  }

  render() {
    const {releaseNote} = this.state;
    const id = this.props.productDefinitionRevision?.id ?? '';
    return (
      <Dialog
        header={'You are about to release the revision ' + id}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '70%'}}
        modal
        onHide={this.hideDialog}
        closable={false}
      >
        {
          <>
            <div className="p-grid">
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="releaseNote" className="p-col-1 p-p-0">
                  Release note
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="releaseNote"
                      name="releaseNote"
                      value={releaseNote}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setState({releaseNote: value});
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </>
        }
      </Dialog>
    );
  }
}
export default ReleaseRevisionDialog;
