import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, Role, User} from 'two-core';
import {messages} from '../../config/messages';
import RolesService from '../../services/RolesService';
import RoleListComponent from '../Roles/RoleListComponent';

interface Props {
  application: Application | undefined;
  users: User[];
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  selectedRoles: Role[];
}

class ManageRolesDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;

  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedRoles: [],
    };

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onShow = this.onShow.bind(this);
    this.setSelectedRoles = this.setSelectedRoles.bind(this);
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  onHide() {
    this.setState({loading: false});
    this.props.onHide();
  }

  onShow() {
    //select all roles from all users
    const selectedRoles: Role[] = [];
    for (const user of this.props.users) {
      const roles = user.roles ?? [];
      for (const role of roles) {
        if (!selectedRoles.some(selectedRole => selectedRole.id === role.id)) {
          selectedRoles.push(role);
        }
      }
    }
    this.setState({loading: false, selectedRoles: selectedRoles});
  }

  setSelectedRoles(items: Role[]) {
    this.setState({selectedRoles: items});
  }

  onSave() {
    this.setState({loading: true});

    const users = this.props.users;
    const selectedRolesIds = this.state.selectedRoles.map(role => role.id);
    const app = this.props.application;
    //send request only for changes
    const promises = [];
    for (const user of users) {
      const currentAssignedRoleIds =
        user.roles?.filter(role => role.application_id === app?.id).map(role => role.id) ?? [];
      //new assignments
      const userSelectedRoleIds = selectedRolesIds.filter(roleId => !currentAssignedRoleIds.includes(roleId));
      //assignments for delete
      const userUnSelectedRolesIds = currentAssignedRoleIds.filter(roleId => !selectedRolesIds.includes(roleId));
      //send requests
      promises.push(
        ...userSelectedRoleIds.map(roleId => {
          return this.rolesService?.assignRole(user.id!, roleId);
        })
      );
      promises.push(
        ...userUnSelectedRolesIds.map(roleId => {
          return this.rolesService?.unassignRole(user.id!, roleId);
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Roles changed successfully.');
        this.setState({loading: false});
        this.onHide();
        MessageService.sendMessage(messages.userRoleUpdated);
        MessageService.sendMessage(messages.userUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Roles change failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  render() {
    const dialogBody = (
      <>
        <div className="p-mb-3">
          <span className="p-pr-1">Which roles do you want to grant to</span>
          <span className="p-text-bold">{this.props.users.map(u => u.full_name ?? '').join(', ')}</span>
          <span className="p-px-1">in</span>
          <span className="p-text-bold">{`${this.props.application?.name}`}</span>
        </div>
        <div className="p-d-flex" style={{height: '100%', width: '100%'}}>
          <RoleListComponent
            application={this.props.application}
            hideMenu={true}
            multipleSelection={true}
            selectedRoles={this.state.selectedRoles}
            setSelectedRoles={roles => this.setSelectedRoles(roles)}
          />
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Manage Roles'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={80}
        onHide={this.onHide}
        onSave={this.onSave}
        onShow={this.onShow}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default ManageRolesDialog;
