import React from 'react';
import {withRouter, RouteComponentProps, Redirect} from 'react-router-dom';
import {TwoEntityComponent, AppContext, TwoAction, TwoEntityPanel, ToastService, MessageService} from 'two-app-ui';
import {faPencil, faList, faTrash, faBuilding} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {Toast} from 'primereact/toast';
import {ProductGroup, QueryParameter} from 'two-core';
import ProductsService from '../../services/ProductsService';
import {Subscription} from 'rxjs';
import CompanyListComponent from '../Companies/CompanyListComponent';
import {ProgressSpinner} from 'primereact/progressspinner';
import ProductGroupService from '../../services/ProductGroupService';
import ProductListComponent from '../Products/ProductListComponent';
import AddEditProductGroupDialog from '../ProductGroups/AddEditProductGroupDialog';
import AssignProductToProductGroupDialog from './AssignProductToProductGroupDialog';
import AssignCustomerToProductGroupDialog from './AssignCustomerToProductGroupDialog';
import {messages} from '../../config/messages';

library.add(faPencil, faList, faTrash, faBuilding);

interface RouteProps {
  id: string;
}

interface State {
  productGroup: ProductGroup;
  productIds: number[] | undefined;
  customersIds: string[] | undefined;
  loadingProductGroup: boolean;
  productGroupDeleted: boolean;
  showEditDialog: boolean;
  showAssignProductDialog: boolean;
  showAssignCustomerDialog: boolean;
  filters: {name: string; search: string};
}

class ProductGroupComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  productGroupService: ProductGroupService | null = null;
  toastService: ToastService | null = null;
  productsService: ProductsService | null = null;
  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      productGroup: {name: ''},
      productIds: [],
      customersIds: [],
      loadingProductGroup: true,
      productGroupDeleted: false,
      showEditDialog: false,
      showAssignProductDialog: false,
      showAssignCustomerDialog: false,
      filters: {name: '', search: ''},
    };

    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.hideAssignProductDialog = this.hideAssignProductDialog.bind(this);
    this.showAssingProductToProductGroupDialog = this.showAssingProductToProductGroupDialog.bind(this);
    this.hideAssignCustomerDialog = this.hideAssignCustomerDialog.bind(this);
    this.showAssingCustomerToProductGroupDialog = this.showAssingCustomerToProductGroupDialog.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.productsService = this.context.productsService;
    this.productGroupService = this.context.productGroupService;
    this.toastService = this.context.toastService;
    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (
        message === messages.productGroupProductUpdated ||
        message === messages.productGroupCustomerUpdated ||
        message === messages.productGroupUpdated
      ) {
        this.loadData();
      }
    });
    this.loadData();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();
  }

  showAssingProductToProductGroupDialog() {
    this.setState({
      showAssignProductDialog: true,
    });
  }

  showAssingCustomerToProductGroupDialog() {
    this.setState({
      showAssignCustomerDialog: true,
    });
  }

  hideAddEditDialog() {
    this.setState({showEditDialog: false});
  }
  hideAssignProductDialog() {
    this.setState({showAssignProductDialog: false});
  }
  hideAssignCustomerDialog() {
    this.setState({showAssignCustomerDialog: false});
  }

  deleteProductGroup() {
    const productGroup = this.state.productGroup;
    if (
      (productGroup.customers && productGroup.customers.length > 0) ||
      (productGroup.products && productGroup.products.length > 0)
    ) {
      this.toastService?.showWarn(this.toast, 'Please remove all assigned products and companies first.');
      return;
    }

    this.productGroupService
      ?.deleteProductGroup(productGroup.id as number)
      .then(() => {
        this.setState({
          productGroupDeleted: true,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records delete failed, please try again.');
        console.error(error);
      });
  }

  async loadData() {
    const productGroupId = this.props.match.params.id;
    this.setState({loadingProductGroup: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: productGroupId,
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.productGroupService
      ?.getProductGroups(params)
      .then(data => {
        const dataRecords = (data.records as ProductGroup[]) ?? [];

        const productGroup = dataRecords ? dataRecords[0] : undefined;
        if (productGroup) {
          const productIds = productGroup.products ? productGroup.products?.map(p => p?.id as number) : undefined;
          const customersIds = productGroup.customers ? productGroup.customers?.map(p => p?.id as string) : undefined;
          this.setState({
            productGroup: productGroup as ProductGroup,
            productIds: productIds,
            customersIds: customersIds,
          });
        }
        this.setState({
          loadingProductGroup: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loadingProductGroup: false});
      });
  }

  getActions(): TwoAction[] {
    const editDetailAction = [];
    editDetailAction.push({
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.setState({showEditDialog: true});
      },
    });
    editDetailAction.push({
      icon: faTrash,
      label: 'Delete',
      main: false,
      action: () => {
        this.deleteProductGroup();
      },
    });

    return editDetailAction;
  }

  render() {
    const {productGroup, loadingProductGroup, productGroupDeleted, showAssignProductDialog, showAssignCustomerDialog} =
      this.state;
    return productGroup ? (
      <>
        <TwoEntityComponent title={productGroup.name} actions={this.getActions()} showDetail={false}>
          <TwoEntityPanel />
          <TwoEntityPanel label="Products" icon={faList} tooltip="Products">
            {!loadingProductGroup ? (
              <ProductListComponent
                productGroupId={productGroup.id}
                showAssingProductDialog={this.showAssingProductToProductGroupDialog}
              />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>

          <TwoEntityPanel label="Companies" icon={faBuilding} tooltip="Companies">
            {!loadingProductGroup ? (
              <CompanyListComponent
                productGroupId={productGroup.id}
                showAssingCustomerDialog={this.showAssingCustomerToProductGroupDialog}
              />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>
        {productGroupDeleted && <Redirect to="/product-groups" />}

        <AddEditProductGroupDialog
          toast={this.toast}
          productGroupId={this.state.productGroup.id}
          showDialog={this.state.showEditDialog}
          onHide={this.hideAddEditDialog}
        />
        <AssignProductToProductGroupDialog
          showDialog={showAssignProductDialog}
          onHide={this.hideAssignProductDialog}
          toast={this.toast}
          productGroup={this.state.productGroup}
        />
        <AssignCustomerToProductGroupDialog
          showDialog={showAssignCustomerDialog}
          onHide={this.hideAssignCustomerDialog}
          toast={this.toast}
          productGroup={this.state.productGroup}
        />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}
export default withRouter(ProductGroupComponent);
