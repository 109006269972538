import React from 'react';
import {Column} from 'primereact/column';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  UsersService,
} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {Application, User, QueryParameter} from 'two-core';
import ApplicationsService from '../../services/ApplicationsService';
import {MenuItemOptions} from 'primereact/menuitem';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import RolesService from '../../services/RolesService';
import {Tooltip} from 'primereact/tooltip';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import ManageRolesDialog from '../Application/ManageRolesDialog';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faPencil} from '@fortawesome/pro-regular-svg-icons';
import GrantAccessDialog from './GrantAccessDialog';

library.add(faPencil);

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  showEditApplicationDialog: boolean;
  showRevokeAccessDialog: boolean;
  showAssignAccessDialog: boolean;
  showGrantAccessDialog: boolean;
  user: User;
  apps: Application[];
  selectedApp: Application | undefined;
}

class UserApplicationList extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  usersService: UsersService | null = null;
  applicationsService: ApplicationsService | null = null;
  toastService: ToastService | null = null;
  rolesService: RolesService | null = null;

  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: false,
      showEditApplicationDialog: false,
      showAssignAccessDialog: false,
      showRevokeAccessDialog: false,
      showGrantAccessDialog: false,
      user: {
        username: '',
      },
      apps: [],
      selectedApp: undefined,
    };

    this.appsBodyTemplate = this.appsBodyTemplate.bind(this);
    this.rolesBodyTemplate = this.rolesBodyTemplate.bind(this);
    this.initTopMenuItems = this.initTopMenuItems.bind(this);
    this.showGrantAccessDialog = this.showGrantAccessDialog.bind(this);
    this.hideGrantAccessDialog = this.hideGrantAccessDialog.bind(this);

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.applicationsService = this.context.applicationsService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
    this.rolesService = this.context.rolesService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.userApplicationUpdated || messages.userRoleUpdated) {
        this.loadUser();
      }
    });

    this.loadData();
  }

  loadData() {
    this.setState({loading: true});

    const promises = [];
    promises.push(this.loadUser());
    promises.push(this.loadApplications());
    Promise.all(promises);

    this.setState({loading: false});
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  loadUser() {
    const id = this.props.match.params.id;

    this.setState({loading: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.usersService
      ?.getUsers(params)
      .then(data => {
        const users = data.records as User[];
        const user = users[0];

        this.setState({
          user: user,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  loadApplications() {
    const params: QueryParameter = {
      //      offset: this.state.pagination.offset,
      //      page_size: this.state.pagination.pageSize,
      aggregate: true,
    };

    return this.applicationsService
      ?.getApplications(params)
      .then(data => {
        const applications = data.records as Application[];

        this.setState({
          apps: applications,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  showEditApplicationDialog() {
    this.setState({showEditApplicationDialog: true});
  }

  hideEditApplicationDialog() {
    this.setState({showEditApplicationDialog: false});
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    menuItems.push({
      label: 'Manage Roles',
      faIcon: faPencil,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({showAssignAccessDialog: true});
      },
    });

    return menuItems;
  }

  initTopMenuItems(): AppMenuItem[] {
    const actions: AppMenuItem[] = [];
    actions.push({
      label: 'Grant Access',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showGrantAccessDialog();
      },
    });

    return actions;
  }

  showGrantAccessDialog() {
    this.setState({showGrantAccessDialog: true});
  }

  hideGrantAccessDialog() {
    this.setState({showGrantAccessDialog: false});
  }

  async setChangeSelectedItem(app: Application) {
    this.setState({selectedApp: app});
  }

  appsBodyTemplate(rowData: Application) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData.name ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems()}
          selectedItems={[]}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'/app/' + rowData.id}>{rowData.name}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  rolesBodyTemplate(rowData: Application) {
    const roles = this.state.user.roles?.filter(role => role.application_id === rowData.id) ?? [];
    return (
      <>
        {roles.map((role, index) => {
          return (
            <div key={role.id}>
              <Tooltip target={`#role-${role.id}`} position="bottom">
                <div className="p-d-flex p-flex-column">
                  <span>{role.description}</span>
                  <span>{role.permissions?.join(', ')}</span>
                </div>
              </Tooltip>
              {!!index && <span>{', '}</span>}
              <span id={`role-${role.id}`}>{role.name}</span>
            </div>
          );
        })}
      </>
    );
  }
  /*
  menuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    menuItems.push({
      label: 'Grant Access',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {},
    });

    if (this.state.selectedApps.length === 1) {
      menuItems.push({
        label: 'Manage Access',
        faIcon: ['far', 'pencil'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.setState({showAssignAccessDialog: true});
        },
      });
    }

    if (this.state.selectedApps.length > 0) {
      menuItems.push({
        label: 'Revoke Access',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.setState({showRevokeAccessDialog: true});
        },
      });
    }
    return menuItems;
  }
*/
  render() {
    const {selectedApp, loading, apps, user, showAssignAccessDialog, showGrantAccessDialog} = this.state;
    return (
      <div id="user_apps_page_container" className="page-container">
        <TwoDataTable
          style={{height: '100%'}}
          pageSizeIdentifier={'user_apps_page_container'}
          id={'user-apps-table'}
          loading={loading}
          value={apps}
          activeFilters={{}}
          selectedItems={[]}
          showPaging={false}
          initMenuItems={this.initTopMenuItems}
        >
          <Column header="Name" body={this.appsBodyTemplate} className={'p-col-2'} />
          <Column header="Roles" body={this.rolesBodyTemplate} className={'p-col-10'} />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <ManageRolesDialog
          application={selectedApp}
          showDialog={showAssignAccessDialog}
          onHide={() => this.setState({showAssignAccessDialog: false})}
          toast={this.toast}
          users={[user]}
        />
        <GrantAccessDialog
          user={user}
          showDialog={showGrantAccessDialog}
          onHide={this.hideGrantAccessDialog}
          toast={this.toast}
        />
        {/**
        <RevokeAccessDialog
          showDialog={showRevokeAccessDialog}
          onHide={() => this.setState({showRevokeAccessDialog: false})}
          toast={this.toast}
          applications={selectedApps}
          user={user}
        />
         */}
      </div>
    );
  }
}

export default withRouter(UserApplicationList);
