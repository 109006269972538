import React from 'react';
import {PriceDefinitionRevision, User} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {AppContext, UsersService} from 'two-app-ui';

interface Props {
  priceDefinitionRevision: PriceDefinitionRevision;
}

interface State {
  priceDefinitionRevision: PriceDefinitionRevision;
  user: User | undefined;
}

class PriceDefinitionRevisionDetail extends React.Component<Props, State> {
  static contextType = AppContext;

  usersService: UsersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      priceDefinitionRevision: {
        stage: 'Draft',
        updated_at: new Date(),
        created_at: new Date(),
        created_by: '',
      },
      user: undefined,
    };
  }

  componentDidMount() {
    this.usersService = this.context.usersService;
    this.setPriceDefinitionRevision(this.props.priceDefinitionRevision);
    this.loadUserData(this.props.priceDefinitionRevision.released_by as string);
  }

  setPriceDefinitionRevision(priceDefinitionRevision: PriceDefinitionRevision) {
    this.setState({
      priceDefinitionRevision: priceDefinitionRevision,
    });
  }

  loadUserData(uuid: string) {
    this.usersService?.getUser(uuid).then((user: User) => {
      this.setState({user: user});
    });
  }

  render() {
    const {priceDefinitionRevision, user} = this.state;
    return (
      <>
        <div className="p-d-flex">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{priceDefinitionRevision.stage}</span>
          </div>
        </div>

        {priceDefinitionRevision.created_at && (
          <div className="p-d-flex">
            <label htmlFor="delivery_date" className="p-col-12 p-md-3">
              created
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(priceDefinitionRevision.created_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}

        {priceDefinitionRevision.updated_at && (
          <div className="p-d-flex">
            <label htmlFor="delivery_date" className="p-col-12 p-md-3">
              last update
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(priceDefinitionRevision.updated_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}

        {priceDefinitionRevision.released_at && (
          <div className="p-d-flex">
            <label htmlFor="delivery_date" className="p-col-12 p-md-3">
              released
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(priceDefinitionRevision.released_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}

        <div className="p-d-flex">
          <label htmlFor="supplier" className="p-col-12 p-md-3">
            released by
          </label>
          <div className="p-col-12 p-md-9">
            <span>{user ? user.full_name : ''}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="warehouse" className="p-col-12 p-md-3">
            release notes
          </label>
          <div className="p-col-12 p-md-9">
            <span>{priceDefinitionRevision.release_notes}</span>
          </div>
        </div>
        {priceDefinitionRevision.products?.map((product, index) => {
          return (
            <div key={index} className="p-d-flex">
              <label htmlFor="warehouse" className="p-col-12 p-md-3">
                {index > 0 ? '' : 'products'}
              </label>
              <div className="p-col-12 p-md-9">
                <span>{product.name}</span>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default PriceDefinitionRevisionDetail;
