import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, QueryParameter, Role, User} from 'two-core';
import {messages} from '../../config/messages';
import RolesService from '../../services/RolesService';
import RoleListComponent from '../Roles/RoleListComponent';
import ApplicationsService from '../../services/ApplicationsService';

interface Props {
  user: User;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  selectedApp?: Application;
  selectedRoles: Role[];
  apps: Application[];
}

class GrantAccessDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;
  applicationsService: ApplicationsService | null = null;

  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedRoles: [],
      apps: [],
    };

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onShow = this.onShow.bind(this);
    this.setSelectedRoles = this.setSelectedRoles.bind(this);
    this.loadApplications = this.loadApplications.bind(this);
    this.onAppChange = this.onAppChange.bind(this);
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
    this.applicationsService = this.context.applicationsService;
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadApplications() {
    this.setState({loading: true});
    const filters = [
      JSON.stringify({
        field: 'id',
        value: this.props.user.applications?.map(app => app.id) ?? [],
        condition: 'notIn',
      }),
    ];
    const sortByStringyfied = JSON.stringify({
      field: 'name',
      direction: 'ASC',
    });

    const params: QueryParameter = {
      orderBys: [sortByStringyfied],
      aggregate: true,
      filters: filters,
    };

    this.applicationsService
      ?.getApplications(params)
      .then(data => {
        const apps = data.records as Application[];

        this.setState({
          apps: apps,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, applications load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  onHide() {
    this.setState({
      loading: false,
      selectedApp: undefined,
      selectedRoles: [],
      apps: [],
    });
    this.props.onHide();
  }

  onShow() {
    this.loadApplications();
  }

  setSelectedRoles(items: Role[]) {
    this.setState({selectedRoles: items});
  }

  onAppChange(e: DropdownChangeParams) {
    this.setState({selectedApp: e.value, selectedRoles: []});
    MessageService.sendMessage(messages.reloadRoles);
  }

  onSave() {
    this.setState({loading: true});

    const user = this.props.user;
    const selectedRolesIds = this.state.selectedRoles.map(role => role.id);
    //send request only for changes
    const promises = [];
    //send requests
    promises.push(
      ...selectedRolesIds.map(roleId => {
        return this.rolesService?.assignRole(user.id!, roleId);
      })
    );

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Roles changed successfully.');
        this.setState({loading: false});
        this.onHide();
        MessageService.sendMessage(messages.userUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Roles change failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  render() {
    const dialogBody = (
      <>
        <div className="p-mb-3">
          <span className="p-pr-1">I want to grant access for</span>
          <span className="p-text-bold">{this.props.user.full_name ?? ''}</span>
          <span className="p-px-1">to</span>
          <Dropdown
            style={{width: '200px'}}
            value={this.state.selectedApp}
            options={this.state.apps}
            onChange={this.onAppChange}
            optionLabel="name"
            dataKey="id"
          />
        </div>
        {this.state.selectedApp && (
          <div className="p-d-flex" style={{height: '100%', width: '100%'}}>
            <RoleListComponent
              application={this.state.selectedApp}
              hideMenu={true}
              multipleSelection={true}
              selectedRoles={this.state.selectedRoles}
              setSelectedRoles={roles => this.setSelectedRoles(roles)}
            />
          </div>
        )}
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Manage Roles'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={80}
        onHide={this.onHide}
        onSave={this.onSave}
        onShow={this.onShow}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default GrantAccessDialog;
