import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {ApiListResponse, PriceDefinitionRevision, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import PriceDefinitionRevisionsService from '../../services/PriceDefinitionRevisionsService';
import {InputTextarea} from 'primereact/inputtextarea';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  priceDefinition: PriceDefinitionRevision;
}

interface State {
  releaseNote: string;
}

class ReleasePriceDefinitionDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  priceDefinitionRevisionsService: PriceDefinitionRevisionsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {releaseNote: ''};

    this.renderFooter = this.renderFooter.bind(this);
    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.priceDefinitionRevisionsService = this.context.priceDefinitionRevisionsService;
    this.toastService = this.context.toastService;
  }

  hideDialog() {
    this.setState({releaseNote: ''});
    this.props.onHide();
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async save() {
    const priceDefinitionRevisionToRelease = this.props.priceDefinition;
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'stage',
        value: 'Current',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    const data: ApiListResponse = (await this.priceDefinitionRevisionsService?.getPriceDefinitionRevisions(
      params
    )) as ApiListResponse;
    const dataRecords = (data.records as PriceDefinitionRevision[]) ?? [];
    if (dataRecords && dataRecords.length > 0) {
      const priceDefinitionRevision: PriceDefinitionRevision = dataRecords[0];
      const priceDefinitionRevisionDeprecated: PriceDefinitionRevision =
        (await this.priceDefinitionRevisionsService?.updatePriceDefinitionRevision(
          priceDefinitionRevision.id as number,
          {
            stage: 'Deprecated',
            deprecated_at: new Date(),
            deprecated_by: this.getCurrentUserId(),
          }
        )) as PriceDefinitionRevision;
      if (!priceDefinitionRevisionDeprecated) {
        return;
      }
    }
    this.priceDefinitionRevisionsService
      ?.updatePriceDefinitionRevision(priceDefinitionRevisionToRelease.id as number, {
        stage: 'Current',
        released_at: new Date(),
        released_by: this.getCurrentUserId(),
        release_notes: this.state.releaseNote,
      })
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Price definition Released.');
        this.hideDialog();
        MessageService.sendMessage(messages.priceDefinitionRevisionUpdated);
      });
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="release"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    );
  }

  render() {
    const {releaseNote} = this.state;
    return (
      <Dialog
        header={'Release price definition'}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '70%'}}
        modal
        onHide={this.hideDialog}
        closable={false}
      >
        {
          <>
            <div className="p-grid">
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="releaseNote" className="p-col-1 p-p-0">
                  Release note
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="releaseNote"
                      name="releaseNote"
                      value={releaseNote}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setState({releaseNote: value});
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </>
        }
      </Dialog>
    );
  }
}
export default ReleasePriceDefinitionDialog;
