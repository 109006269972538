import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {Application, QueryParameter, Role} from 'two-core';
import config from '../../config/config';
import RolesService from '../../services/RolesService';
import AddEditRoleDialog from './AddEditRoleDialog';
import ApplicationsService from '../../services/ApplicationsService';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import DeleteRoleDialog from './DeleteRoleDialog';
import CopyRolePermissionsDialog from './CopyRolePermissionsDialog';

interface Props {
  application?: Application | undefined;
  hideMenu?: boolean;
  multipleSelection?: boolean;
  selectedRoles?: Role[];
  setSelectedRoles?: (roles: Role[]) => void;
}

interface State {
  loading: boolean;
  items: Role[];
  selectedItems: Role[];
  totalItems: number;
  activeFilters: {};
  filters: {
    name: string;
    description: string;
    permissions: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
  showDeleteDialog: boolean;
  showCopyDialog: boolean;
  roleId: number | undefined;
  application: Application | undefined;
  applications: Application[];
}

class RoleListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  applicationsService: ApplicationsService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        name: '',
        description: '',
        permissions: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
      showCopyDialog: false,
      showDeleteDialog: false,
      roleId: undefined,
      application: undefined,
      applications: [],
    };

    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.permissionsBodyTemplate = this.permissionsBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.initTopMenuItems = this.initTopMenuItems.bind(this);
    this.showAddDialog = this.showAddDialog.bind(this);
    this.showEditDialog = this.showEditDialog.bind(this);
    this.showDeleteDialog = this.showDeleteDialog.bind(this);
    this.showCopyDialog = this.showCopyDialog.bind(this);
    this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.hideCopyDialog = this.hideCopyDialog.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.applicationsService = this.context.applicationsService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.roleUpdated || message === messages.reloadRoles) {
        const appId = this.props.application?.id ?? this.state.application?.id ?? 0;
        this.loadData(appId);
      }
    });

    this.loadApplications();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initTopMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const addMenuItem: AppMenuItem = {
      label: 'Add Role',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddDialog();
      },
    };

    menuItems.push(addMenuItem);

    return menuItems;
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    const menuItems: AppMenuItem[] = [];

    const duplicateMenuItem: AppMenuItem = {
      label: 'Duplicate',
      faIcon: ['far', 'clone'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.duplicateRole();
      },
    };

    const copyMenuItem: AppMenuItem = {
      label: 'Copy Permissions Over',
      faIcon: ['far', 'copy'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showCopyDialog();
      },
    };

    const deleteMenuItem: AppMenuItem = {
      label: 'Remove Role',
      faIcon: ['far', 'minus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.removeRole();
      },
    };

    if (selectedItemsCount === 1) {
      menuItems.push(duplicateMenuItem);
      menuItems.push(copyMenuItem);
      menuItems.push(deleteMenuItem);
    }

    return menuItems;
  }

  loadData(applicationId?: number) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'application_id',
        value: applicationId ?? this.state.application?.id ?? 0,
      })
    );

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.description) {
      filters.push(
        JSON.stringify({
          field: 'description',
          value: this.state.filters.description,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.permissions) {
      filters.push(
        JSON.stringify({
          field: 'permissions',
          value: this.state.filters.permissions,
          condition: 'iLike',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'name',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.rolesService
      ?.getRoles(params)
      .then(data => {
        const roles = data.records as Role[];
        this.handleSelectedItems(roles);

        this.setState({
          items: roles,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async loadApplications() {
    const application = this.props.application;
    if (application) {
      const applications = [application];
      this.setState({
        application: application,
        applications: applications,
      });
      this.loadData(application?.id);
    } else {
      this.setState({loading: true});

      const sortByStringyfied = JSON.stringify({
        field: 'name',
        direction: 'ASC',
      });

      const params: QueryParameter = {
        orderBys: [sortByStringyfied],
        aggregate: true,
      };

      this.applicationsService
        ?.getApplications(params)
        .then(data => {
          const applications = data.records as Application[];
          const application = applications[0];

          this.setState({
            applications: applications,
            application: application,
            loading: false,
          });
          this.loadData(application?.id);
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, applications load failed, please try again.');
          console.error(error);
          this.setState({loading: false});
        });
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  showAddDialog() {
    this.setState({roleId: undefined, showAddEditDialog: true});
  }

  showEditDialog(role: Role) {
    this.setState({roleId: role.id, showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({roleId: undefined, showAddEditDialog: false});
  }

  showDeleteDialog() {
    this.setState({showDeleteDialog: true});
  }

  hideDeleteDialog() {
    this.setState({showDeleteDialog: false});
  }

  showCopyDialog() {
    this.setState({showCopyDialog: true});
  }

  hideCopyDialog() {
    this.setState({showCopyDialog: false});
  }

  duplicateRole() {
    const selectedRole = this.state.selectedItems[0];
    const newRole = {...selectedRole, name: `${selectedRole.name} copy`};
    this.createRole(newRole);
  }

  removeRole() {
    const selectedRole = this.state.selectedItems[0];
    const hasUsers = (selectedRole.users?.filter(u => u !== null) ?? []).length > 0;
    if (hasUsers) {
      this.showDeleteDialog();
    } else {
      this.deleteRole(selectedRole);
    }
  }

  deleteRole(role: Role) {
    this.setState({loading: true});

    this.rolesService
      ?.deleteRole(role.id)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Role deleted successfully.');
        MessageService.sendMessage(messages.roleUpdated);
        this.setState({loading: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Role delete failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  createRole(role: Role) {
    this.setState({loading: true});

    this.rolesService
      ?.createRole(role)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Role created successfully.');
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Role create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  nameBodyTemplate(rowData: Role) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={this.props.hideMenu ? undefined : this.initMenuItems}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <a href={'#/'} onClick={() => this.showEditDialog(rowData)}>
          {rowData.name}
        </a>
      </AppColumnMenuBodyTemplate>
    );
  }

  permissionsBodyTemplate(rowData: Role) {
    const permissions = rowData.permissions ?? [];
    const permissionsLenght = permissions.length;
    const text =
      permissionsLenght > config().system.max_permissions_text_lenght
        ? `${permissionsLenght}+`
        : permissions.join(', ');

    return <span>{text}</span>;
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  handleSelectedItems(allItems: Role[]) {
    const selectedItems = this.props.selectedRoles ?? this.state.selectedItems;
    const items: Role[] = allItems.filter(item => selectedItems.some(selectedItem => selectedItem.id === item.id));

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Role[]) {
    if (this.props.setSelectedRoles) {
      this.props.setSelectedRoles(items);
    } else {
      this.setState({selectedItems: items});
    }
  }

  async setChangeSelectedItem(item: Role) {
    const items = [item];
    await this.setState({selectedItems: items});
  }

  render() {
    const nameFilter = (
      <InputText
        name="detail"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const descriptionFilter = (
      <InputText
        name="closed_by"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const permissionsFilter = (
      <InputText
        name="permissions"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedRoles = this.props.selectedRoles ?? this.state.selectedItems;

    return (
      <div id="roles_page_container" className="page-container">
        <TwoDataTable
          id={'roles-table'}
          selectedItems={selectedRoles}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={this.props.application ? undefined : 'roles_page_container'}
          sizeIdentifiers={this.props.application ? undefined : ['application_selection']}
          initMenuItems={this.props.hideMenu ? undefined : this.initTopMenuItems}
          selectionMode={this.props.multipleSelection ? 'multiple' : 'single'}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Role[])}
          heightToScroll={undefined}
        >
          <Column
            header="Name"
            field="name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={nameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />

          <Column
            header="Description"
            field="description"
            filter
            filterElement={descriptionFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />

          <Column
            header="Permissions"
            style={{width: '180px'}}
            field="permissions"
            filter
            filterElement={permissionsFilter}
            sortable
            showFilterMenu={false}
            body={item => this.permissionsBodyTemplate(item)}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditRoleDialog
          toast={this.toast}
          application={this.state.application}
          roleId={this.state.roleId}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
        />
        {selectedRoles[0] && (
          <>
            <DeleteRoleDialog
              toast={this.toast}
              application={this.state.application}
              role={selectedRoles[0]}
              showDialog={this.state.showDeleteDialog}
              onHide={this.hideDeleteDialog}
            />
            <CopyRolePermissionsDialog
              toast={this.toast}
              application={this.state.application!}
              role={selectedRoles[0]}
              showDialog={this.state.showCopyDialog}
              onHide={this.hideCopyDialog}
            />
          </>
        )}
      </div>
    );
  }
}

export default RoleListComponent;
