export interface DropdownOption {
  label: string;
  value: string;
}

export const stages: DropdownOption[] = [
  {label: 'New', value: 'New'},
  {label: 'Ready', value: 'Ready'},
  {label: 'Waiting For Return', value: 'Waiting For Return'},
  {label: 'Alu Required', value: 'Alu Required'},
  {label: 'Alu Ordered', value: 'Alu Ordered'},
  {label: 'Alu Needs Cutting', value: 'Alu Needs Cutting'},
  {label: 'Alu Cutting', value: 'Alu Cutting'},
  {label: 'Powder Required', value: 'Powder Required'},
  {label: 'Powder Ordered', value: 'Powder Ordered'},
  {label: 'Sent To Floor', value: 'Sent To Floor'},
  {label: 'In Production', value: 'In Production'},
  {label: 'Post Fix Delivery', value: 'Post Fix Delivery'},
  {label: 'Done', value: 'Done'},
  {label: 'Cancelled', value: 'Cancelled'},
];
