import {DropdownOption} from 'two-core';

export const priceListTypes: DropdownOption[] = [
  {label: 'Number', value: 'number'},
  {label: 'Pick list', value: 'pick-list'},
];

export const priceListScope: DropdownOption[] = [
  {label: 'Item', value: 'item'},
  {label: 'Order', value: 'order'},
];
