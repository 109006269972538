import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, ProductDefinitionRevision} from 'two-core';

class ProductDefinitionRevisionsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.productsDefinitionRevisions ?? '';
  }

  async getProductDefinitionRevisions(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateProductDefinitionRevision(
    id: number,
    data: ProductDefinitionRevision
  ): Promise<ProductDefinitionRevision> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as ProductDefinitionRevision);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProductDefinitionRevision(data: ProductDefinitionRevision): Promise<ProductDefinitionRevision> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as ProductDefinitionRevision);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ProductDefinitionRevisionsService;
