import {DateTime} from 'luxon';
import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, TwoDataTable, ToastService} from 'two-app-ui';
import {DropdownOption, Order, QueryParameter} from 'two-core';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCalendarExclamation, faCalendar} from '@fortawesome/pro-regular-svg-icons';
import {ProductDefinitionRevision} from 'two-core/build/cjs/src/product-definition';
import OrdersService from '../../services/OrdersService';
import values from '../../config/values';
import formats from '../../config/formats';
import {stages} from '../Order/constants/constants';

library.add(faCalendarExclamation, faCalendar);

interface Props {
  revision: ProductDefinitionRevision;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  items: Order[];
  totalItems: number;
  selectedItems: Order[];
  activeFilters: {};
  filters: {
    order_code: string;
    distributor: string;
    reference: string;
    priority: string;
    type: string;
    stage: string;
    product_line: string;
    submitted_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    submitted_by: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}
class RevisionOrders extends React.Component<Props, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;

  typingTimer: NodeJS.Timeout | undefined = undefined;
  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      activeFilters: {},
      items: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      filters: {
        order_code: '',
        distributor: '',
        reference: '',
        priority: '',
        type: '',
        stage: '',
        product_line: '',
        submitted_at: {
          fromDate: null,
          toDate: null,
        },
        submitted_by: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
    };

    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    // this.factoryOrdersService = this.context.factoryOrdersService;
    this.toastService = this.context.toastService;

    this.loadOrders();
  }

  loadOrders() {
    const revisionId = this.props.revision.id!;
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'revision_id',
        value: revisionId,
      })
    );

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.distributor) {
      filters.push(
        JSON.stringify({
          field: 'distributor',
          value: this.state.filters.distributor,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.priority) {
      filters.push(
        JSON.stringify({
          field: 'priority',
          value: this.state.filters.priority,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.submitted_at.fromDate) {
      const fromDate = this.state.filters.submitted_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.submitted_at.toDate) {
      const toDate = this.state.filters.submitted_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.submitted_by) {
      filters.push(
        JSON.stringify({
          field: 'submitted_by',
          value: this.state.filters.submitted_by,
          condition: 'like',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    //some columns have different name in database and map for server
    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'order_code':
        sortBy.field = 'id';
        break;
      case 'owner_company.account_number':
        sortBy.field = 'company.account_number';
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy);

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: this.state.sortBy ? [sortByStringyField] : [],
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then(data => {
        const dataRecords = (data.records as Order[]) ?? [];

        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadOrders();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadOrders();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadOrders();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  stageBodyTemplate(rowData: Order) {
    return (
      <span
        className={`stage-badge stage-${(rowData.factory_order?.production_stage ?? '')
          .toLowerCase()
          .replaceAll(' ', '-')}`}
      >
        {rowData.factory_order?.production_stage}
      </span>
    );
  }

  submittedAtBodyTemplate(rowData: Order) {
    const submittedAt = rowData.submitted_at
      ? DateTime.fromISO(rowData.submitted_at.toString()).toFormat(formats.date)
      : '';
    return <span>{submittedAt}</span>;
  }

  render() {
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span className={`stage-badge stage-${option.value.toString().toLowerCase().replaceAll(' ', '-')}`}>
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={stages}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const customerFilter = (
      <InputText
        name="customer_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const submittedFilter = (
      <DateColumnFilter
        name="submitted_at"
        value={this.state.filters.submitted_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const submitterFilter = (
      <InputText
        name="submitted_by"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="oos_orders_page_container" className="page-container" style={{width: '100%'}}>
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'oos_orders_page_container'}
            heightToScroll={undefined}
            selectionMode="multiple"
            selectedItems={this.state.selectedItems}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            loading={this.state.loading}
            value={this.state.items}
            totalRecords={this.state.totalItems}
            activeFilters={this.state.activeFilters}
          >
            <Column
              header="Order Code"
              field="id"
              filter
              filterElement={codeFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Reference"
              field="reference"
              filter
              filterElement={referenceFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Customer"
              field="owner_company.account_number"
              filter
              filterElement={customerFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Stage"
              field="stage"
              body={this.stageBodyTemplate}
              filter
              filterElement={stageFilter}
              sortable
              style={{width: '180px'}}
              showFilterMenu={false}
            />
            <Column
              header="Submitted"
              field="submitted_at"
              body={this.submittedAtBodyTemplate}
              style={{width: '180px'}}
              showFilterMenu={false}
              filter
              filterElement={submittedFilter}
              sortable
            />
            <Column
              header="Submitted By"
              field="submitted_by"
              style={{width: '180px'}}
              showFilterMenu={false}
              filter
              filterElement={submitterFilter}
              sortable
            />
          </TwoDataTable>
        </div>
      </div>
    );
  }
}

export default RevisionOrders;
