import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {QueryParameter, ProductDefinitionRevisionStage, ProductDefinitionRevision} from 'two-core';
import config from '../../config/config';
import {DateTime} from 'luxon';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import formats from '../../config/formats';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import ProductDefinitionRevisionsService from '../../services/ProductDefinitionRevisionsService';
import {NavLink} from 'react-router-dom';
import ReleaseRevisionDialog from './ReleaseRevisionDialog';

interface State {
  loading: boolean;
  items: ProductDefinitionRevision[];
  selectedItems: ProductDefinitionRevision[];
  totalItems: number;
  activeFilters: {};
  filters: {
    id: string;
    stage: ProductDefinitionRevisionStage | '';
    external_id: string;
    release_notes: string;
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    updated_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    released_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
  revisionId: number | undefined;
  showReleaseProductDefinition: boolean;
}

class ProductDefinitionRevisionListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  productDefinitionRevisionsService: ProductDefinitionRevisionsService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        id: '',
        stage: '',
        external_id: '',
        release_notes: '',
        created_at: {
          fromDate: null,
          toDate: null,
        },
        updated_at: {
          fromDate: null,
          toDate: null,
        },
        released_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
      revisionId: undefined,
      showReleaseProductDefinition: false,
    };

    this.idBodyTemplate = this.idBodyTemplate.bind(this);
    this.createdOnBodyTemplate = this.createdOnBodyTemplate.bind(this);
    this.releasedOnBodyTemplate = this.releasedOnBodyTemplate.bind(this);
    this.updatedAtBodyTemplate = this.updatedAtBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.showAddDialog = this.showAddDialog.bind(this);
    this.showEditDialog = this.showEditDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.productDefinitionRevisionsService = this.context.productDefinitionRevisionsService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.revisionUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddDialog();
      },
    });

    if (selectedItemsCount === 1) {
      menuItems.push({
        label: 'Duplicate',
        faIcon: ['far', 'copy'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.duplicateRevision();
        },
      });

      menuItems.push({
        separator: true,
      });

      menuItems.push({
        label: 'Test',
        faIcon: ['far', 'question'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          window.location.href = `/revision-test/${this.state.selectedItems[0].id}`;
        },
      });

      menuItems.push({
        label: 'Release',
        faIcon: ['far', 'question'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.setState({showReleaseProductDefinition: true});
        },
      });
    }

    return menuItems;
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.id) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.id,
        })
      );
    }
    if (this.state.filters.stage) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.external_id) {
      filters.push(
        JSON.stringify({
          field: 'external_id',
          value: this.state.filters.external_id,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.release_notes) {
      filters.push(
        JSON.stringify({
          field: 'release_notes',
          value: this.state.filters.release_notes,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.updated_at.fromDate) {
      const fromDate = this.state.filters.updated_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'updated_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.updated_at.toDate) {
      const toDate = this.state.filters.updated_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'updated_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.released_at.fromDate) {
      const fromDate = this.state.filters.released_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'released_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.released_at.toDate) {
      const toDate = this.state.filters.released_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'released_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'id',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.productDefinitionRevisionsService
      ?.getProductDefinitionRevisions(params)
      .then(data => {
        const revisions = data.records as ProductDefinitionRevision[];

        this.setState({
          items: revisions,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  showAddDialog() {
    this.setState({revisionId: undefined, showAddEditDialog: true});
  }

  showEditDialog(revision: ProductDefinitionRevision) {
    this.setState({revisionId: revision.id, showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({
      revisionId: undefined,
      showAddEditDialog: false,
      selectedItems: [],
    });
  }

  duplicateRevision() {
    const items = this.state.selectedItems;
    const revision = items[0];
    if (revision) {
      const newRevision = {
        ...revision,
        id: undefined,
      };
      this.createRevision(newRevision);
    } else {
      this.toastService?.showError(this.toast, 'Sorry, Revision duplicate failed, please try again.');
    }
  }

  async createRevision(revision: ProductDefinitionRevision) {
    this.setState({loading: true});

    return this.productDefinitionRevisionsService
      ?.createProductDefinitionRevision(revision)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Product created successfully.');
        MessageService.sendMessage(messages.revisionUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Product create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  async saveProductDefinitionRevision(revision: ProductDefinitionRevision) {
    return this.productDefinitionRevisionsService
      ?.updateProductDefinitionRevision(revision?.id ?? 0, revision)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Product definition revision update successfully.');
        this.setState({loading: false, selectedItems: []});
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Product definition revision update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  setChangeSelectedItems(items: ProductDefinitionRevision[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: ProductDefinitionRevision) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  idBodyTemplate(rowData: ProductDefinitionRevision) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'revision/' + rowData.id}>{rowData.id}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  createdOnBodyTemplate(rowData: ProductDefinitionRevision) {
    const formattedUpdated = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  updatedAtBodyTemplate(rowData: ProductDefinitionRevision) {
    const formattedUpdated = rowData.updated_at
      ? DateTime.fromISO(rowData.updated_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  releasedOnBodyTemplate(rowData: ProductDefinitionRevision) {
    const formattedUpdated = rowData.released_at
      ? DateTime.fromISO(rowData.released_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  render() {
    const {showReleaseProductDefinition, selectedItems} = this.state;
    const idFilter = (
      <InputText
        name="id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const externalIdFilter = (
      <InputText
        name="external_id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const releaseNotesFilter = (
      <InputText
        name="release_notes"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const stageFilter = (
      <MultiSelect
        value={this.state.filters.stage}
        options={['Draft', 'Released', 'Deprecated', 'Cancelled']}
        name="stage"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );
    const createdAtFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const updatedAtFilter = (
      <DateColumnFilter
        name="updated_at"
        value={this.state.filters.updated_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const releasedAtFilter = (
      <DateColumnFilter
        name="released_at"
        value={this.state.filters.released_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    return (
      <div id="product_definition_revision_table_page_container" className="page-container">
        <TwoDataTable
          id={'product_definition_revision_table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'product_definition_revision_page_container'}
          initMenuItems={this.initMenuItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as ProductDefinitionRevision[])}
          selectionMode="multiple"
        >
          <Column
            header="Id"
            field="id"
            body={item => this.idBodyTemplate(item)}
            filter
            filterElement={idFilter}
            style={{width: '100px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            filter
            filterElement={stageFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Created"
            field="created_at"
            filter
            filterElement={createdAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.createdOnBodyTemplate}
          />
          <Column
            header="Last Update"
            field="updated_at"
            filter
            filterElement={updatedAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.updatedAtBodyTemplate}
          />
          <Column
            header="Released"
            field="released_at"
            filter
            filterElement={releasedAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.releasedOnBodyTemplate}
          />
          <Column
            header="External Id"
            field="external_id"
            filter
            filterElement={externalIdFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Release Notes"
            field="release_notes"
            filter
            filterElement={releaseNotesFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        {/*todo preparation for dialog*/}
        {/*<AddEditProductDefinitionRevisionDialog*/}
        {/*  toast={this.toast}*/}
        {/*  productId={this.state.revisionId}*/}
        {/*  showDialog={this.state.showAddEditDialog}*/}
        {/*  onHide={this.hideAddEditDialog}*/}
        {/*/>*/}
        <ReleaseRevisionDialog
          showDialog={showReleaseProductDefinition}
          onHide={() => this.setState({showReleaseProductDefinition: false})}
          productDefinitionRevision={selectedItems[0]}
        />
      </div>
    );
  }
}

export default ProductDefinitionRevisionListComponent;
