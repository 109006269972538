import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {ProgressSpinner} from 'primereact/progressspinner';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Product, ProductPriceDefinition, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import {ProductPriceFreightSurcharge, SurchargeProduct} from 'two-core';
import ProductPriceDefinitionsService from '../../services/ProductPriceDefinitionsService';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown, DropdownFilterParams} from 'primereact/dropdown';
import {priceListTypes} from '../../config/priceListConstants';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import ProductsService from '../../services/ProductsService';
import {ProductDropdownOption} from './Models/ProductDropdownOption';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  currentProductPriceDefinition: ProductPriceDefinition;
  productPriceFreightSurcharge: ProductPriceFreightSurcharge;
}

interface State {
  loading: boolean;
  updatedProductPriceFreightSurcharge: ProductPriceFreightSurcharge | undefined;
  ProductDropdownOptionList: ProductDropdownOption[];
}

class EditFreightSurchargeDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  productPriceDefinitionsService: ProductPriceDefinitionsService | null = null;
  productsService: ProductsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      updatedProductPriceFreightSurcharge: this.props.productPriceFreightSurcharge,
      ProductDropdownOptionList: [],
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onProductDropdownFilter = this.onProductDropdownFilter.bind(this);
    this.onProductDropdownChange = this.onProductDropdownChange.bind(this);
    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.productPriceDefinitionsService = this.context.productPriceDefinitionsService;
    this.productsService = this.context.productsService;
    this.toastService = this.context.toastService;

    this.prepareDropdownData('');
  }

  onShow() {
    const productPriceFreightSurcharge = this.props.productPriceFreightSurcharge;
    if (productPriceFreightSurcharge) {
      this.setState({
        updatedProductPriceFreightSurcharge: productPriceFreightSurcharge,
      });
    }
  }

  hideDialog() {
    this.setState({loading: false});
    this.props.onHide();
  }

  async save() {
    const productPriceDefinition: ProductPriceDefinition = this.props.currentProductPriceDefinition;
    productPriceDefinition.price_definitions.freight_surcharge = this.state
      .updatedProductPriceFreightSurcharge as ProductPriceFreightSurcharge;
    this.productPriceDefinitionsService
      ?.updateProductPriceDefinition(productPriceDefinition)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Freight Surcharge updated.');
        MessageService.sendMessage(messages.productPriceDefinitionUpdated);
        this.hideDialog();
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Freight Surcharge update failed, please try again.');
        console.error(error);
      });
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    );
  }

  async handleChange(title: string, value: string) {
    const freightSurcharge = this.state.updatedProductPriceFreightSurcharge ?? this.props.productPriceFreightSurcharge;
    if (freightSurcharge) {
      const updatedProductPriceItem: ProductPriceFreightSurcharge = {
        ...freightSurcharge,
        [title]: value,
      };
      await this.setState({
        updatedProductPriceFreightSurcharge: updatedProductPriceItem,
      });
    }
  }

  async prepareDropdownData(productName: string) {
    const dropdownProducts: ProductDropdownOption[] = [];
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'name',
        value: productName,
        condition: 'iLike',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.productsService
      ?.getProducts(params)
      .then(data => {
        const products = (data?.records as Product[]) ?? [];
        products.map((product: Product) =>
          dropdownProducts.push({
            label: product.name,
            product: {
              product_id: product.id as number,
              product_key: product.interpreter_key,
            },
          })
        );

        this.setState({
          loading: false,
          ProductDropdownOptionList: dropdownProducts,
        });
      })
      .catch(error => {
        this.props.toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, Product load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loading: false});
        console.error(error);
      });
  }

  onProductDropdownFilter(e: DropdownFilterParams) {
    this.prepareDropdownData(e.filter);
  }
  onProductDropdownChange(e: MultiSelectChangeParams) {
    const updatedProductPriceFreightSurcharge = this.state
      .updatedProductPriceFreightSurcharge as ProductPriceFreightSurcharge;
    updatedProductPriceFreightSurcharge.group_with = e.value as SurchargeProduct[];
    this.setState({
      updatedProductPriceFreightSurcharge: updatedProductPriceFreightSurcharge,
    });
  }

  render() {
    const {loading, updatedProductPriceFreightSurcharge, ProductDropdownOptionList} = this.state;

    return (
      <Dialog
        header={'Edit Freight Surcharge'}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '70%'}}
        modal
        onHide={() => this.hideDialog()}
        closable={false}
        onShow={this.onShow}
      >
        {!loading ? (
          <>
            <div className="p-grid">
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="sku" className="p-col-1 p-p-0">
                  sku
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <InputText
                      value={updatedProductPriceFreightSurcharge?.sku ?? ''}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('sku', value);
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="price_list_type" className="p-col-1 p-p-0">
                  price list type
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      showClear
                      value={updatedProductPriceFreightSurcharge?.price_list_type}
                      options={priceListTypes}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('price_list_type', value);
                      }}
                      placeholder="Pick price list type"
                      optionLabel="label"
                      optionValue="value"
                    />
                  </span>
                </div>
                <label htmlFor="group_with" className="p-col-1 p-p-0">
                  group with
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <MultiSelect
                      filter
                      display="chip"
                      onFilter={this.onProductDropdownFilter}
                      options={ProductDropdownOptionList}
                      showClear
                      value={updatedProductPriceFreightSurcharge?.group_with}
                      onChange={this.onProductDropdownChange}
                      placeholder="group with"
                      optionLabel="label"
                      optionValue="product"
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="description_script" className="p-col-1 p-p-0">
                  description script
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="description_script"
                      name="description_script"
                      value={updatedProductPriceFreightSurcharge?.description_script ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('description_script', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="qty_calculation" className="p-col-1 p-p-0">
                  qty calculation
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="qty_calculation"
                      name="qty_calculation"
                      value={updatedProductPriceFreightSurcharge?.qty_calculation ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('qty_calculation', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="unit_script" className="p-col-1 p-p-0">
                  unit script
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="unit_script"
                      name="unit_script"
                      value={updatedProductPriceFreightSurcharge?.unit_script ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('unit_script', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="unit_price_calculation" className="p-col-1 p-p-0">
                  unit price calculation
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="unit_price_calculation"
                      name="unit_price_calculation"
                      value={updatedProductPriceFreightSurcharge?.unit_price_calculation ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('unit_price_calculation', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="line_total_calculation" className="p-col-1 p-p-0">
                  line total calculation
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="line_total_calculation"
                      name="line_total_calculation"
                      value={updatedProductPriceFreightSurcharge?.line_total_calculation ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.handleChange('line_total_calculation', value);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}
export default EditFreightSurchargeDialog;
