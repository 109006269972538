import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {MultiSelectChangeParams} from 'primereact/multiselect';
import {InputText} from 'primereact/inputtext';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {QueryParameter, ProductGroup} from 'two-core';
import config from '../../config/config';
import {NavLink} from 'react-router-dom';
import ProductGroupService from '../../services/ProductGroupService';
import AddEditProductGroupDialog from './AddEditProductGroupDialog';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';

interface State {
  loading: boolean;
  items: ProductGroup[];
  selectedItems: ProductGroup[];
  totalItems: number;
  productGroupId: number;
  activeFilters: {};
  productsMaxLength: number;
  filters: {name: string; search: string};
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
}

class ProductGroupListComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  productGroupService: ProductGroupService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor() {
    super({});

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      productGroupId: 0,
      loading: false,
      activeFilters: {},
      productsMaxLength: 90,
      filters: {name: '', search: ''},
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
    };

    this.toast = React.createRef();

    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.showEditDialog = this.showEditDialog.bind(this);
    this.showAddDialog = this.showAddDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.deleteProductGroupItems = this.deleteProductGroupItems.bind(this);
  }

  componentDidMount() {
    this.productGroupService = this.context.productGroupService;
    this.toastService = this.context.toastService;
    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.productGroupUpdated) {
        this.loadData();
      }
    });
    this.loadData();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.subscription.unsubscribe();
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedItems;
    const selectedItemsLenght = selectedItems.length;

    const addNewMenuItem: AppMenuItem = {
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddDialog();
      },
    };
    menuItems.push(addNewMenuItem);

    if (selectedItemsLenght === 1) {
      const editMenuItem: AppMenuItem = {
        label: 'Edit',
        faIcon: ['far', 'pencil'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showEditDialog(selectedItems[0]);
        },
      };
      menuItems.push(editMenuItem);
    }

    if (selectedItemsLenght > 0) {
      const deleteMenuItem: AppMenuItem = {
        label: 'Delete',
        faIcon: ['far', 'trash'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.deleteProductGroupItems().then(() => {
            this.loadData();
          });
        },
      };
      menuItems.push(deleteMenuItem);
    }
    return menuItems;
  }

  showAddDialog() {
    this.setState({
      productGroupId: 0,
      showAddEditDialog: true,
    });
  }
  showEditDialog(productGroup: ProductGroup) {
    this.setState({
      productGroupId: productGroup.id as number,
      showAddEditDialog: true,
    });
  }
  hideAddEditDialog() {
    this.setState({productGroupId: 0, showAddEditDialog: false});
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }

    const sortByFieldOrg = this.state.sortBy?.field;
    const sortByField = sortByFieldOrg ?? 'id';

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
      search: this.state.filters.search ? this.state.filters.search : undefined,
    };

    this.setState({activeFilters: {...filters}});

    this.productGroupService
      ?.getProductGroups(params)
      .then(data => {
        const dataRecords = (data.records as ProductGroup[]) ?? [];

        this.handleSelectedItems(dataRecords);

        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async deleteProductGroupItems() {
    const productGroups = this.state.selectedItems;
    return Promise.all(
      productGroups.map(async productGroup => {
        if (
          (productGroup.customers && productGroup.customers.length > 0) ||
          (productGroup.products && productGroup.products.length > 0)
        ) {
          this.toastService?.showWarn(
            this.toast,
            `Please remove all assigned products and companies for group ${productGroup.name}.`
          );
          return;
        }
        return this.productGroupService
          ?.deleteProductGroup(productGroup.id as number)
          .then(() => {
            this.toastService?.showSuccess(this.toast, 'Product Group deleted successfully.');
          })
          .catch(error => {
            this.toastService?.showError(this.toast, 'Sorry, records delete failed, please try again.');
            console.error(error);
          });
      })
    );
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleSelectedItems(allItems: ProductGroup[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: ProductGroup[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: ProductGroup[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: ProductGroup) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  nameBodyTemplate(rowData: ProductGroup) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'product-group/' + rowData.id}>{`${rowData.name}`}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  productsBodyTemplate(rowData: ProductGroup) {
    let products = '0';
    if (rowData.products && rowData.products.length > 0) {
      products = rowData.products.map(p => p.name).join(', ');
      if (products.length > this.state.productsMaxLength) {
        products = rowData.products.length.toString();
      }
    }

    return <span>{products}</span>;
  }

  companiesBodyTemplate(rowData: ProductGroup) {
    return <span>{rowData.customers ? rowData.customers.length : 0}</span>;
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="product_groups_page_container" className="page-container">
        <TwoDataTable
          id={'product-groups-table'}
          sizeIdentifiers={['filter-box']}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'product_groups_page_container'}
          initMenuItems={() => this.initMenuItems()}
          addNewItemEvent={() => this.showAddDialog()}
          selectionMode="multiple"
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as ProductGroup[])}
        >
          <Column
            header="Name"
            field="name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={nameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Products"
            field="products"
            body={item => this.productsBodyTemplate(item)}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Companies"
            field="companies"
            body={item => this.companiesBodyTemplate(item)}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditProductGroupDialog
          toast={this.toast}
          productGroupId={this.state.productGroupId}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
        />
      </div>
    );
  }
}

export default ProductGroupListComponent;
