import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {QueryParameter, Product} from 'two-core';
import {Toast} from 'primereact/toast';
import {InputSwitch} from 'primereact/inputswitch';
import {messages} from '../../config/messages';
import ProductsService from '../../services/ProductsService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  productId: number | undefined;
}

interface State {
  loading: boolean;
  product: Product | undefined;
}

class AddEditProductDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  productsService: ProductsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      product: undefined,
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.setRole = this.setRole.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.productsService = this.context.productsService;
    this.toastService = this.context.toastService;
  }

  setRole() {
    const productId = this.props.productId;
    if (productId) {
      this.loadProduct(productId);
    } else {
      const emptyProduct: Product = {
        id: 0,
        name: '',
        interpreter_key: '',
        external_id: '',
        enabled: false,
        deleted: false,
      };
      this.setState({product: emptyProduct});
    }
  }

  hideDialog() {
    this.setState({product: undefined, loading: false});
    this.props.onHide();
  }

  loadProduct(id: number) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.productsService
      ?.getProducts(params)
      .then(data => {
        const dataRecords = (data?.records as Product[]) ?? [];
        const product = dataRecords[0];

        this.setState({
          product: product,
          loading: false,
        });
      })
      .catch(error => {
        this.props.toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, Product load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const product = this.state.product;
    if (product) {
      if (product.id) {
        this.updateProduct(product);
      } else {
        this.createProduct(product);
      }
    }
  }

  async createProduct(product: Product) {
    this.setState({loading: true});

    return this.productsService
      ?.createProduct(product)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Product created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.productUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Product create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  async updateProduct(product: Product) {
    this.setState({loading: true});

    return this.productsService
      ?.updateProduct(product?.id?.toString() ?? '', product)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Product updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.productUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Product update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  setValue(title: string, value: string | boolean) {
    const product = this.state.product;
    if (product) {
      const updatedProduct: Product = {
        ...product,
        [title]: value,
      };
      this.setState({product: updatedProduct});
    }
  }

  render() {
    const {product} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={product?.name ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('name', value);
                }}
              />
            </span>
          </div>
          <label htmlFor="interpreter_key" className="p-col-1">
            key
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={product?.interpreter_key ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('interpreter_key', value);
                }}
              />
            </span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="enabled" className="p-col-1">
            enabled
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputSwitch
                checked={product?.enabled}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('enabled', value);
                }}
              />
            </span>
          </div>
          <label htmlFor="external_id" className="p-col-1">
            external id
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={product?.external_id ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('external_id', value);
                }}
              />
            </span>
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle={product?.id && product.id !== 0 ? 'Edit Product' : 'Add Product'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={60}
        onShow={this.setRole}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEditProductDialog;
