import React from 'react';
import {Fieldset} from 'primereact/fieldset';
import {InputText} from 'primereact/inputtext';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';
import './FilterBoxComponent.scss';

interface Props {
  onboarding: number;
  totalActive: number;
  totalActiveFiltered: number;
  showDeactivatedCompanies: boolean;
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement> | InputSwitchChangeParams) => void;
}

const FilterboxComponent = ({
  onboarding,
  totalActive,
  totalActiveFiltered,
  showDeactivatedCompanies,
  handleFilterChange,
  onFilterChange,
}: Props) => {
  return (
    <Fieldset id="filter-box" className="line-filter p-mb-3 p-p-1 w-100">
      <div className="p-d-flex p-flex-row p-flex-wrap">
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <label className="p-col-12 p-md-4 p-lg-4 p-as-center p-p-1">Search</label>
          <div className="p-col-12 p-md-8 p-lg-8 p-p-0 p-as-center">
            <InputText
              name="search"
              className="w-100"
              onChange={e => handleFilterChange(e)}
              placeholder="emails, names, addresses"
            />
          </div>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <label className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1">Active Dealers</label>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">
            {`${totalActive} (filtered to: ${totalActiveFiltered})`}
          </span>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <label className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1">Onboarding</label>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">{`${onboarding}`}</span>
        </div>
        <div className="p-d-flex p-xs-12 p-sm-12 p-col-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <label className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1">Show De-activate</label>
          <div className="p-col-12 p-md-6 p-lg-6 p-p-0 p-as-center">
            <InputSwitch
              name="showDeactivatedCompanies"
              className={'p-as-center'}
              checked={showDeactivatedCompanies}
              onChange={e => onFilterChange(e)}
            />
          </div>
        </div>
      </div>
    </Fieldset>
  );
};

export default FilterboxComponent;
