import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, ToastService, TwoDialog} from 'two-app-ui';
import {User} from 'two-core';
import AdminUsersService from '../../services/AdminUserService';
import {InputText} from 'primereact/inputtext';
import {ProgressSpinner} from 'primereact/progressspinner';

interface Props {
  user: User;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  newPassword: string;
  passwordConfirm: string;
}

class SetPasswordDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  adminUsersService: AdminUsersService | null = null;
  toastService: ToastService | null = null;
  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      newPassword: '',
      passwordConfirm: '',
    };
    this.toast = React.createRef();
    this.onHide = this.onHide.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  componentDidMount() {
    this.adminUsersService = this.context.adminUsersService;
    this.toastService = this.context.toastService;
  }

  onHide() {
    this.setState({
      loading: false,
    });
    this.props.onHide();
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  }

  async setPassword() {
    if (this.state.newPassword.length < 6) {
      this.toastService?.showError(this.toast, 'Password needs to be at least 6 characters, please try again.');
      return;
    }
    if (this.state.newPassword !== this.state.passwordConfirm) {
      this.toastService?.showError(this.toast, 'Password does not match confirmation, please try again.');
      return;
    }
    this.setState({
      loading: true,
    });
    this.adminUsersService
      ?.setUserPassword(this.props.user.id!, this.state.newPassword)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'User password Set.');
        this.onHide();
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Could not set user password, please try again.');
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const dialogBody = (
      <>
        {!this.state.loading ? (
          <>
            <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
              <label htmlFor="newPassword" className="p-col-3">
                New Password
              </label>
              <div className="p-col-9">
                <InputText
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e)}
                />
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
              <label htmlFor="passwordConfirm" className="p-col-3">
                Repeat Password
              </label>
              <div className="p-col-9">
                <InputText
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <ProgressSpinner />
        )}

        <Toast ref={this.toast} />
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Set User Password'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={50}
        onHide={this.onHide}
        onSave={this.setPassword}
        loading={false}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default SetPasswordDialog;
