import {InputSwitch} from 'primereact/inputswitch';
import React from 'react';
import {User} from 'two-core';

interface Props {
  user: User;
}

interface State {
  user: User;
}

class UserDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        username: '',
      },
    };
  }

  componentDidMount() {
    this.setUser(this.props.user);
  }

  setUser(user: User) {
    this.setState({
      user: user,
    });
  }

  render() {
    const {user} = this.state;
    return (
      <>
        <div className="p-d-flex">
          <label htmlFor="username" className="p-col-12 p-md-3">
            username
          </label>
          <div className="p-col-12 p-md-9">
            <span>{user.username}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="email" className="p-col-12 p-md-3">
            email
          </label>
          <div className="p-col-12 p-md-9">
            <span>{user.email ?? ''}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{user.type ?? ''}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="enabled" className="p-col-12 p-md-3">
            enabled
          </label>
          <div className="p-col-12 p-md-9">
            <InputSwitch checked={user.enabled ?? false} />
          </div>
        </div>
      </>
    );
  }
}

export default UserDetail;
