import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ToastService, AppContext, AppMenuItem, TwoDataTable} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {PriceDefinitionRevision} from 'two-core';
import {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {Subscription} from 'rxjs';

interface State {
  loading: boolean;
  items: PriceDefinitionRevision[];
  selectedItems: PriceDefinitionRevision[];
  totalItems: number;
  activeFilters: {};
  filters: {};
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class InvoiceListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  toastService: ToastService | null = null;
  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor() {
    super({});

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {},
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    return menuItems;
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  setChangeSelectedItems(items: PriceDefinitionRevision[]) {
    this.setState({selectedItems: items});
  }

  loadData() {
    //todo preparation for invoices -> there is no invoices table in the current db schema
    //this.setState({loading: true});
    const filters: string[] = [];
    /*
    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'id',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });*/
    /*
    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };
*/
    this.setState({activeFilters: {...filters}});
  }

  render() {
    return (
      <div id="invoices_table_page_container" className="page-container">
        <TwoDataTable
          id={'invoices_table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'invoices_page_container'}
          initMenuItems={this.initMenuItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as PriceDefinitionRevision[])}
          selectionMode="multiple"
        >
          <Column header="Invoice number" field="id" filter style={{width: '100px'}} sortable showFilterMenu={false} />
          <Column
            header="Reference"
            field="reference"
            filter
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column header="Stage" field="stage" filter style={{width: '180px'}} sortable showFilterMenu={false} />
          <Column
            header="Customer"
            field="owner.trading_as"
            filter
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Approved"
            field="approved_at"
            filter
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Approved by"
            field="approved.full_name"
            filter
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default InvoiceListComponent;
