import React from 'react';
import {Application} from 'two-core';

interface Props {
  application: Application;
}

class ApplicationDetail extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {application} = this.props;
    return (
      <>
        <div className="p-d-flex">
          <label htmlFor="username" className="p-col-12 p-md-3">
            url
          </label>
          <div className="p-col-12 p-md-9">
            <span>{application.url}</span>
          </div>
        </div>

        <div className="p-d-flex">
          <label htmlFor="description" className="p-col-12 p-md-3">
            description
          </label>
          <div className="p-col-12 p-md-9">
            <span>{application.description ?? ''}</span>
          </div>
        </div>
      </>
    );
  }
}

export default ApplicationDetail;
