import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {QueryParameter, User} from 'two-core';
import {Toast} from 'primereact/toast';
import {InputSwitch} from 'primereact/inputswitch';
import {messages} from '../../config/messages';
import {confirmDialog} from 'primereact/confirmdialog';
import AdminUsersService from '../../services/AdminUserService';

const timezoneOptions = ['Adelaide', 'Brisbane', 'Melbourne', 'Perth', 'Sydney'];

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  userId: string | undefined;
}

interface State {
  loading: boolean;
  user: User | undefined;
}

class AddEditUserDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  adminUsersService: AdminUsersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      user: undefined,
    };

    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.setUser = this.setUser.bind(this);
  }

  componentDidMount() {
    this.adminUsersService = this.context.adminUsersService;
    this.toastService = this.context.toastService;
  }

  setUser() {
    const userId = this.props.userId;
    if (userId) {
      this.loadUser(userId);
    } else {
      const emptyUser: User = {
        full_name: '',
        username: '',
        enabled: false,
      };
      this.setState({user: emptyUser});
    }
  }

  hideDialog() {
    this.setState({user: undefined, loading: false});
    this.props.onHide();
  }

  loadUser(id: string) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.adminUsersService
      ?.getUsers(params)
      .then(data => {
        const dataRecords = (data?.records as User[]) ?? [];
        const user = dataRecords[0];

        this.setState({
          user: user,
          loading: false,
        });
      })
      .catch(error => {
        this.props.toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, User load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const user = this.state.user;
    if (user) {
      if (user.id) {
        this.updateUser(user);
      } else {
        this.createUser(user);
      }
    }
  }

  async createUser(user: User) {
    this.setState({loading: true});

    return this.adminUsersService
      ?.createUser(user)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'User created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.userUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, User create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  confirmDisableEnableUser(user: User) {
    const action = user!.enabled ? 'Disable' : 'Enable';
    confirmDialog({
      message: `Are you sure you want to ${action} this user?`,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.updateUser(user);
      },
    });
  }

  async updateUser(user: User) {
    this.setState({loading: true});

    return this.adminUsersService
      ?.saveUser(user?.id ?? '', user)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'User updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.userUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, User update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  setValue(title: string, value: string | boolean) {
    const user = this.state.user;
    if (user) {
      const updatedUser: User = {
        ...user,
        [title]: value,
      };
      this.setState({user: updatedUser});
    }
  }

  setTimeZoneValue(value: string) {
    const user = this.state.user;
    if (user) {
      const userSettings = user.settings;
      const updatedSettings = {...userSettings, default_timezone: value};

      const updatedUser = Object.assign(user, {
        ...user,
        settings: updatedSettings,
      });

      this.setState({user: updatedUser});
    }
  }

  render() {
    const {user} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="full_name" className="p-col-1">
            full name
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={user?.full_name ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('full_name', value);
                }}
              />
            </span>
          </div>
          <label htmlFor="username" className="p-col-1">
            username
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={user?.username ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('username', value);
                }}
              />
            </span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="email" className="p-col-1">
            email
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputText
                value={user?.email ?? ''}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('email', value);
                }}
              />
            </span>
          </div>
          <label htmlFor="default_time_zone" className="p-col-1">
            time zone
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <Dropdown
                className="p-d-flex w-100"
                value={user?.settings?.default_timezone ?? ''}
                options={timezoneOptions}
                onChange={e => this.setTimeZoneValue(e.value)}
              />
            </span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
          <label htmlFor="enabled" className="p-col-1">
            enabled
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <InputSwitch
                checked={user?.enabled}
                onChange={e => {
                  const value = e.target.value;
                  this.setValue('enabled', value);
                }}
              />
            </span>
          </div>
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={user?.id && user.id !== '' ? 'Edit User' : 'Add User'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={60}
        onShow={this.setUser}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEditUserDialog;
