import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, Role, UserRole} from 'two-core';

class RolesService extends ApiService {
  endpoint: string;
  userEndpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.roles ?? '';
    this.userEndpoint = config().endpoints.users ?? '';
  }

  async getRoles(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateRole(id: string, data: Role): Promise<Role> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Role);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createRole(data: Role): Promise<Role> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Role);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteRole(id: number): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async assignRole(userId: string, roleId: number): Promise<UserRole> {
    const data = {
      role_id: roleId,
    };
    return this.post(`${this.userEndpoint}/${userId}/roles`, data)
      .then(data => {
        return Promise.resolve(data as unknown as UserRole);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async unassignRole(userId: string, roleId: number): Promise<void> {
    return this.delete(`${this.userEndpoint}/${userId}/roles/${roleId}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default RolesService;
