import {ProductDefinitionRevision} from 'two-core/build/cjs/src/product-definition';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import React from 'react';
import {AppContext, TwoAction, TwoEntityComponent, TwoEntityPanel} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import RevisionDetail from './RevisionDetail';
import {ProgressSpinner} from 'primereact/progressspinner';
import {QueryParameter} from 'two-core';
import ProductDefinitionRevisionsService from '../../services/ProductDefinitionRevisionsService';
import RevisionProducts from './RevisionProducts';
import RevisionOrders from './RevisionOrders';
import {faList, faPuzzlePiece} from '@fortawesome/pro-regular-svg-icons';

interface RouteProps {
  id: string;
}

interface State {
  revision?: ProductDefinitionRevision;
}

class RevisionComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;
  revisionService: ProductDefinitionRevisionsService | null = null;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      revision: undefined,
    };

    this.toast = React.createRef();

    this.loadRevision = this.loadRevision.bind(this);
  }

  componentDidMount() {
    this.revisionService = this.context.productDefinitionRevisionsService;

    const id = this.props.match.params.id;

    this.loadRevision(id);
  }

  loadRevision(id: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.revisionService?.getProductDefinitionRevisions(params).then(data => {
      const revision = (data.records as ProductDefinitionRevision[])[0];
      this.setState({
        revision: revision,
      });
    });
  }
  render() {
    const {revision} = this.state;
    const actions: TwoAction[] = [];

    return revision ? (
      <>
        <TwoEntityComponent title={revision.id?.toString() ?? ''} actions={actions}>
          <TwoEntityPanel isPrimary={true}>
            <RevisionDetail revision={revision} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Products" tooltip="products" icon={faPuzzlePiece}>
            <RevisionProducts revision={revision} toast={this.toast} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Orders" tooltip="orders" icon={faList}>
            <RevisionOrders revision={revision} toast={this.toast} />
          </TwoEntityPanel>
        </TwoEntityComponent>

        <Toast ref={this.toast} />
      </>
    ) : (
      <ProgressSpinner />
    );
  }
}

export default withRouter(RevisionComponent);
