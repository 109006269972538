import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, ProductGroup} from 'two-core';

class ProductGroupService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.productGroups ?? '';
  }

  async getProductGroups(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateProductGroup(id: string, data: ProductGroup): Promise<ProductGroup> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as unknown as ProductGroup);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProductGroup(data: ProductGroup): Promise<ProductGroup> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as unknown as ProductGroup);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteProductGroup(id: number): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProductGroupProduct(id: number, productId: number): Promise<void> {
    return this.post(`${this.endpoint}/${id}/product/${productId}`, {})
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteProductGroupProduct(id: number, productId: number): Promise<void> {
    return this.delete(`${this.endpoint}/${id}/product/${productId}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProductGroupCustomer(id: number, customerId: string): Promise<void> {
    return this.post(`${this.endpoint}/${id}/customer/${customerId}`, {})
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
  async deleteProductGroupCustomer(id: number, customerId: string): Promise<void> {
    return this.delete(`${this.endpoint}/${id}/customer/${customerId}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ProductGroupService;
