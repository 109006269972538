import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, SkuDepartment} from 'two-core';

class SkuDepartmentService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.skuDepartment ?? '';
  }

  async getSkuDepartments(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateSkuDepartment(id: number, data: SkuDepartment): Promise<SkuDepartment> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as SkuDepartment);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createSkuDepartment(data: SkuDepartment): Promise<SkuDepartment> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as SkuDepartment);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteSkuDepartment(id: number): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default SkuDepartmentService;
