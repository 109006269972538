import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {TwoEntityComponent, AppContext, TwoAction, TwoEntityPanel, ToastService, MessageService} from 'two-app-ui';
import {faBan, faCopy, faCheck, faFileInvoiceDollar, faQuestion, faTableTree} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import {PriceDefinitionRevision, ProductPriceDefinition, QueryParameter} from 'two-core';
import PriceDefinitionRevisionsService from '../../services/PriceDefinitionRevisionsService';
import {ProgressSpinner} from 'primereact/progressspinner';
import PriceDefinitionRevisionDetail from './PriceDefinitionRevisionDetail';
import InvoiceListComponent from '../Invoices/InvoiceListComponent';
import ProductPriceDefinitionTreeComponent from './ProductPriceDefinitionTreeComponent';
import ProductPriceDefinitionsService from '../../services/ProductPriceDefinitionsService';
import ReleasePriceDefinitionDialog from '../PriceDefinitionRevisions/ReleasePriceDefinitionDialog';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';

interface RouteProps {
  id: string;
}

interface State {
  showReleasePriceDefinition: boolean;
  priceDefinitionRevision: PriceDefinitionRevision | undefined;
  productIds: number[] | undefined;
  customersIds: string[] | undefined;
  loadingPriceDefinitionRevision: boolean;
  loadingSecondaryView: boolean;
  filters: {name: string; search: string};
}

class PriceDefinitionRevisionComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  priceDefinitionRevisionsService: PriceDefinitionRevisionsService | null = null;
  productPriceDefinitionsService: ProductPriceDefinitionsService | null = null;
  toastService: ToastService | null = null;
  toast: React.RefObject<Toast>;
  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      showReleasePriceDefinition: false,
      priceDefinitionRevision: undefined,
      productIds: [],
      customersIds: [],
      loadingPriceDefinitionRevision: false,
      loadingSecondaryView: false,
      filters: {name: '', search: ''},
    };

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.priceDefinitionRevisionsService = this.context.priceDefinitionRevisionsService;
    this.productPriceDefinitionsService = this.context.productPriceDefinitionsService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.priceDefinitionRevisionUpdated) {
        this.loadData();
      }
    });

    this.duplicatePriceDefinition = this.duplicatePriceDefinition.bind(this);
    this.testPriceDefinition = this.testPriceDefinition.bind(this);
    this.releasePriceDefinition = this.releasePriceDefinition.bind(this);
    this.cancelPriceDefinition = this.cancelPriceDefinition.bind(this);

    this.loadData();
  }

  setLoadingSecondaryView(value: boolean) {
    this.setState({loadingSecondaryView: value});
  }

  loadData() {
    const productGroupId = this.props.match.params.id;
    this.setState({loadingPriceDefinitionRevision: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: productGroupId,
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.priceDefinitionRevisionsService
      ?.getPriceDefinitionRevisions(params)
      .then(data => {
        const dataRecords = (data.records as PriceDefinitionRevision[]) ?? [];
        const priceDefinitionRevision = dataRecords ? dataRecords[0] : undefined;

        this.setState({
          priceDefinitionRevision: priceDefinitionRevision as PriceDefinitionRevision,
          loadingPriceDefinitionRevision: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loadingPriceDefinitionRevision: false});
      });
  }

  getActions(): TwoAction[] {
    const action: TwoAction[] = [];
    action.push({
      icon: faCopy,
      label: 'Duplicate',
      main: true,
      action: this.duplicatePriceDefinition,
    });

    action.push({
      icon: faQuestion,
      label: 'Test',
      action: this.testPriceDefinition,
    });

    if (this.state.priceDefinitionRevision && this.state.priceDefinitionRevision.stage === 'Draft') {
      action.push({
        icon: faCheck,
        label: 'Release',
        action: this.releasePriceDefinition,
      });

      action.push({
        icon: faBan,
        label: 'Cancel',
        action: this.cancelPriceDefinition,
      });
    }

    return action;
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  duplicatePriceDefinition() {
    const priceDefinitionRevision = this.state.priceDefinitionRevision as PriceDefinitionRevision;
    const priceDefinitionRevisionToDuplicate: PriceDefinitionRevision = {
      stage: 'Draft',
      created_at: new Date(),
      created_by: this.getCurrentUserId(),
    };

    this.priceDefinitionRevisionsService
      ?.createPriceDefinitionRevision(priceDefinitionRevisionToDuplicate)
      .then((data: PriceDefinitionRevision) => {
        priceDefinitionRevision.product_price_definitions?.forEach((productPriceDefinition: ProductPriceDefinition) => {
          const productPriceDefinitionToUpdate = {...productPriceDefinition};
          productPriceDefinitionToUpdate.revision_id = data.id as number;
          this.productPriceDefinitionsService
            ?.createProductPriceDefinition(productPriceDefinitionToUpdate)
            .then(() => {
              this.toastService?.showSuccess(this.toast, 'Price definition duplicated.');
            })
            .catch(error => {
              this.toastService?.showError(this.toast, 'Price definition duplicate failed, please try again.');
              console.error(error);
            });
        });
        this.props.history.push('/price-definitions');
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Price definition duplicate failed, please try again.');
        console.error(error);
      });
  }

  testPriceDefinition() {
    console.log('testPriceDefinition');
  }

  releasePriceDefinition() {
    this.setState({showReleasePriceDefinition: true});
  }

  cancelPriceDefinition() {
    const priceDefinitionRevisionToCancel = this.state.priceDefinitionRevision as PriceDefinitionRevision;
    priceDefinitionRevisionToCancel.stage = 'Cancelled';
    priceDefinitionRevisionToCancel.cancelled_at = new Date();
    priceDefinitionRevisionToCancel.cancelled_by = this.getCurrentUserId();
    this.priceDefinitionRevisionsService
      ?.updatePriceDefinitionRevision(priceDefinitionRevisionToCancel.id as number, priceDefinitionRevisionToCancel)
      .then(() => {
        this.loadData();
      });
  }

  render() {
    const {priceDefinitionRevision, showReleasePriceDefinition} = this.state;
    return priceDefinitionRevision ? (
      <>
        <TwoEntityComponent title={priceDefinitionRevision?.id?.toString() ?? ''} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingPriceDefinitionRevision ? (
              <PriceDefinitionRevisionDetail priceDefinitionRevision={priceDefinitionRevision} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>

          <TwoEntityPanel label="product-definitions" icon={faTableTree} tooltip="Product definitions">
            {!this.state.loadingSecondaryView ? (
              <ProductPriceDefinitionTreeComponent priceDefinitionRevisionId={priceDefinitionRevision.id as number} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>

          <TwoEntityPanel label="Invoices" icon={faFileInvoiceDollar} tooltip="Invoices">
            {!this.state.loadingSecondaryView ? <InvoiceListComponent /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <ReleasePriceDefinitionDialog
          showDialog={showReleasePriceDefinition}
          onHide={() => this.setState({showReleasePriceDefinition: false})}
          toast={this.toast}
          priceDefinition={this.state.priceDefinitionRevision as PriceDefinitionRevision}
        />
      </>
    ) : (
      <></>
    );
  }
}
export default withRouter(PriceDefinitionRevisionComponent);
