import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, User} from 'two-core';
import {Toast} from 'primereact/toast';
import RolesService from '../../services/RolesService';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  applications: Application[];
  user: User;
}

interface State {
  loading: boolean;
}

class RevokeAccessDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
  }

  onHide() {
    this.setState({loading: false});
    this.props.onHide();
  }

  async onSave() {
    const {user, applications} = this.props;
    const appIds = applications.map(app => app.id);
    const roles = user.roles?.filter(role => appIds.includes(role.application_id)) ?? [];
    if (roles?.length) {
      this.setState({loading: true});

      Promise.all(
        roles.map(role => {
          return this.rolesService?.unassignRole(user.id!, role.id);
        })
      )
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Roles deleted successfully.');
          MessageService.sendMessage(messages.roleUpdated);
          MessageService.sendMessage(messages.userRoleUpdated);
          this.setState({loading: false});
          this.onHide();
        })
        .catch(error => {
          this.toastService?.showError(this.props.toast, 'Sorry, Roles delete failed, please try again.');
          console.error('error: ' + error);
          this.setState({loading: false});
        });
    }
  }

  render() {
    const {applications, user} = this.props;
    const applicationsNames = applications.map(app => app.name).join(', ');

    const dialogBody = (
      <>
        <div className="p-grid p-jc-center p-col-12">
          <span className="p-pr-1">You are to revoke access of</span>
          <span className="p-text-bold">{user.full_name}</span>, that is
          <span className="p-px-1">in the app</span>
          <span className="p-text-bold">{applicationsNames}</span>
        </div>
        <div className="p-grid p-jc-center p-col-12">This will remove all roles they were granted for this app.</div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Revoke Access'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={40}
        onHide={this.onHide}
        onSave={this.onSave}
        loading={this.state.loading}
        saveButtonTitle="Continue"
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default RevokeAccessDialog;
