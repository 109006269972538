import React from 'react';
import './DateColumnFilter.scss';
import {Fieldset} from 'primereact/fieldset';
import {InputText} from 'primereact/inputtext';
import {OverlayPanel} from 'primereact/overlaypanel';
import {ListBox} from 'primereact/listbox';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {DateTime} from 'luxon';
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import formats from '../../config/formats';

library.add(faTimes);

export interface DropdownOption {
  label: string;
  value: string;
}

const filterItems: DropdownOption[] = [
  {value: 'all', label: '-all-'},
  {value: 'today', label: 'Today'},
  {value: 'this_week', label: 'This week'},
  {value: 'tomorrow', label: 'Tomorrow'},
  {value: 'next_week', label: 'Next week'},
  {value: 'next_month', label: 'Next month'},
  {value: 'yesterday', label: 'Yesterday'},
  {value: 'last_week', label: 'Last week'},
  {value: 'last_month', label: 'Last month'},
];

export interface DateColumnFilterChangeEvent {
  target: {
    name: string;
    value: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
}

interface Props {
  onChange: (e: DateColumnFilterChangeEvent) => void;
  value: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  };
  name: string;
}
interface State {
  isOpened: boolean;
  chosenValue: string;
  chosenLabel: string;
  fromDate: DateTime | null;
  toDate: DateTime | null;
}

class DateColumnFilter extends React.Component<Props, State> {
  overlayPanel: React.RefObject<OverlayPanel>;
  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpened: false,
      chosenValue: '',
      chosenLabel: '',
      fromDate: null,
      toDate: null,
    };

    this.overlayPanel = React.createRef();
    this.toast = React.createRef();

    this.setDate('');
    this.onChangeFrom = this.onChangeFrom.bind(this);
    this.onChangeTo = this.onChangeTo.bind(this);
  }

  onChange = () => {
    // create simple response structure for other processing (structure is similar to the input or dropdown structure)
    // the difference is in the value of field
    const e = {
      target: {
        name: this.props.name,
        value: {
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        },
      },
    };

    this.props.onChange(e);
    this.overlayPanel.current?.hide();
  };

  async setChosenValue(value: string) {
    await this.setState({
      chosenValue: value,
      chosenLabel: filterItems.find(item => item.value === value)?.label ?? '',
    });
    this.setDate(value);
    this.onChange();
  }

  async setDate(value: string) {
    let from = null;
    let to = null;
    switch (value) {
      case 'today':
        from = to = DateTime.local();
        break;
      case 'this_week':
        from = DateTime.local().startOf('week');
        to = DateTime.local().endOf('week');
        break;
      case 'tomorrow':
        from = to = DateTime.local().plus({days: 1});
        break;
      case 'next_week':
        from = DateTime.local().plus({weeks: 1}).startOf('week');
        to = DateTime.local().plus({weeks: 1}).endOf('week');
        break;
      case 'next_month':
        from = DateTime.local().plus({months: 1}).startOf('month');
        to = DateTime.local().plus({months: 1}).endOf('month');
        break;
      case 'yesterday':
        from = to = DateTime.local().minus({days: 1});
        break;
      case 'last_week':
        from = DateTime.local().minus({weeks: 1}).startOf('week');
        to = DateTime.local().minus({weeks: 1}).endOf('week');
        break;
      case 'last_month':
        from = DateTime.local().minus({months: 1}).startOf('month');
        to = DateTime.local().minus({months: 1}).endOf('month');
        break;
      case 'all':
        from = null;
        to = null;
        break;
      case '':
        from = null;
        to = null;
        break;
      default:
        return;
    }
    await this.setState({
      fromDate: from,
      toDate: to,
    });
  }

  async setCustomValue() {
    if (
      (this.state.fromDate !== null && this.state.toDate !== null && this.state.fromDate <= this.state.toDate) ||
      (this.state.fromDate !== null && this.state.toDate === null) ||
      (this.state.fromDate === null && this.state.toDate !== null)
    ) {
      console.info('Set dates:', this.state.fromDate, this.state.toDate);
      await this.setState({
        chosenLabel: 'Custom',
      });
      this.onChange();
    } else {
      this.toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, Wrong dates, please try again.',
        life: 3000,
        contentClassName: '',
      });
      console.error('Wrong dates: ', this.state.fromDate, this.state.toDate);
    }
  }

  async onChangeFrom(e: CalendarChangeParams) {
    this.setState({fromDate: DateTime.fromJSDate(e.value as Date)});
  }
  async onChangeTo(e: CalendarChangeParams) {
    this.setState({toDate: DateTime.fromJSDate(e.value as Date)});
  }
  render() {
    return (
      <div className="date-column-filter">
        <InputText
          className="form-filter"
          style={{width: '100%'}}
          value={this.state.chosenLabel}
          onClick={e => this.overlayPanel.current?.toggle(e, null)}
        />
        {this.state.chosenLabel !== '' && (
          <FontAwesomeIcon className={'clear-icon'} icon={faTimes} onClick={() => this.setChosenValue('')} />
        )}

        <OverlayPanel ref={this.overlayPanel} style={{width: 220}} className="date-panel-filter p-fluid">
          <ListBox
            className={'no-border'}
            value={this.state.chosenValue}
            options={filterItems}
            onChange={e => this.setChosenValue(e.value)}
          />
          <Fieldset>
            <div className="p-field p-d-flex p-mt-2">
              <label htmlFor="from" className="p-col-2 p-as-center p-mr-1">
                from
              </label>
              <Calendar
                id="from"
                inputClassName={'form-filter'}
                value={this.state?.fromDate?.toJSDate()}
                dateFormat={formats.calendarInputDate}
                onChange={this.onChangeFrom}
                showIcon
              />
            </div>
            <div className="p-field p-d-flex">
              <label htmlFor="to" className="p-col-2 p-as-center p-mr-1">
                to
              </label>
              <Calendar
                id="to"
                inputClassName={'form-filter'}
                value={this.state?.toDate?.toJSDate()}
                dateFormat={formats.calendarInputDate}
                onChange={this.onChangeTo}
                showIcon
              />
            </div>
            <div className="p-d-flex">
              <Button className={'p-ml-auto'} label="Set interval" onClick={() => this.setCustomValue()} />
            </div>
          </Fieldset>
        </OverlayPanel>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default DateColumnFilter;
