import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, ToastService, TwoDataTable} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {QueryParameter, Application} from 'two-core';
import config from '../../config/config';
import ApplicationsService from '../../services/ApplicationsService';
import AddEditApplicationDialog from './AddEditApplicationDialog';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import './ApplicationListComponent.scss';
import {Tooltip} from 'primereact/tooltip';
import {NavLink} from 'react-router-dom';

interface State {
  loading: boolean;
  items: Application[];
  totalItems: number;
  activeFilters: {};
  filters: {
    name: string;
    description: string;
    url: string;
    permissions: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
  applicationId: number | undefined;
}

class ApplicationListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  applicationsService: ApplicationsService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);

    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        name: '',
        description: '',
        url: '',
        permissions: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
      applicationId: undefined,
    };

    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.permissionsBodyTemplate = this.permissionsBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.menuItems = this.menuItems.bind(this);
    this.showAddDialog = this.showAddDialog.bind(this);
    this.hideAddDialog = this.hideAddDialog.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.applicationsService = this.context.applicationsService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.aplicationUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  menuItems(): AppMenuItem[] {
    return [
      {
        label: 'Add New',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddDialog();
        },
      },
    ];
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.description) {
      filters.push(
        JSON.stringify({
          field: 'description',
          value: this.state.filters.description,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.url) {
      filters.push(
        JSON.stringify({
          field: 'url',
          value: this.state.filters.url,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.permissions) {
      filters.push(
        JSON.stringify({
          field: 'permissions',
          value: this.state.filters.permissions,
          condition: 'iLike',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'name',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.applicationsService
      ?.getApplications(params)
      .then(data => {
        const applications = data.records as Application[];

        this.setState({
          items: applications,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  showAddDialog() {
    this.setState({applicationId: undefined, showAddEditDialog: true});
  }

  hideAddDialog() {
    this.setState({applicationId: undefined, showAddEditDialog: false});
  }

  nameBodyTemplate(rowData: Application) {
    return <NavLink to={'/app/' + rowData.id}>{rowData.name}</NavLink>;
  }

  permissionsBodyTemplate(rowData: Application) {
    const permissions = rowData.permissions ?? [];
    const permissionsLenght = permissions.length;

    if (permissionsLenght > config().system.max_permissions_text_lenght) {
      const element = (
        <>
          <Tooltip target={`#app-${rowData.id}`} position="bottom">
            <div className="p-d-flex p-flex-column">
              <span>{permissions.join(', ')}</span>
            </div>
          </Tooltip>
          <span id={`app-${rowData.id}`}>{`${permissionsLenght}+`}</span>
        </>
      );
      return element;
    } else {
      return <span>{permissions.join(', ')}</span>;
    }
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const descriptionFilter = (
      <InputText
        name="description"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const urlFilter = (
      <InputText
        name="url"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="apps_page_container" className="page-container">
        <TwoDataTable
          id={'apps-table'}
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'apps_page_container'}
          initMenuItems={this.menuItems}
          selectionMode="multiple"
        >
          <Column
            header="Name"
            field="name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={nameFilter}
            className={'p-col-2'}
            sortable
            showFilterMenu={false}
          />

          <Column
            header="Description"
            field="description"
            filter
            filterElement={descriptionFilter}
            className={'p-col-5'}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Url"
            field="url"
            filter
            filterElement={urlFilter}
            className={'p-col-5'}
            sortable
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditApplicationDialog
          toast={this.toast}
          applicationId={this.state.applicationId}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddDialog}
        />
      </div>
    );
  }
}

export default ApplicationListComponent;
