import {
  Address,
  Order,
  OrderItem,
  PaProductDefinition,
  ProductDefinition,
  ProductDefinitionRevision,
  QueryParameter,
} from 'two-core';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AppContext, MessageService, OrderItemsComponent, OrderItemComponentMode} from 'two-app-ui';
import ProductDefinitionRevisionsService from '../../services/ProductDefinitionRevisionsService';
import {OverlayPanel} from 'primereact/overlaypanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {SelectButton, SelectButtonChangeParams} from 'primereact/selectbutton';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import {messages} from '../../config/messages';
import ProductDefinitionsService from '../../services/ProductDefinitionsService';
import {Button} from 'primereact/button';

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  productDefinitionsLoading: boolean;
  revision: ProductDefinitionRevision | undefined;
  mode: OrderItemComponentMode;
  order: Order;
  productDefinitions: PaProductDefinition[];
  invalidItemMessagesMap?: Map<number, string[]>;
}

class RevisionTestComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  revisionsService: ProductDefinitionRevisionsService | null = null;
  productDefinitionsService: ProductDefinitionsService | null = null;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: false,
      revision: undefined,
      mode: 'advanced_edit',
      order: this.createDummyOrder(),
      productDefinitions: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
      productDefinitionsLoading: false,
    };

    this.panelHeaderTemplate = this.panelHeaderTemplate.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.onItemsChanged = this.onItemsChanged.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
  }

  componentDidMount() {
    this.revisionsService = this.context.productDefinitionRevisionsService;
    this.productDefinitionsService = this.context.productDefinitionsService;
    const id = Number(this.props.match.params.id);
    this.loadRevision(id);
    this.loadProductDefinitions(id);
  }

  async loadRevision(revisionId: number) {
    this.setState({loading: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: revisionId,
      })
    );

    const params: QueryParameter = {
      filters: filters,
    };

    this.revisionsService?.getProductDefinitionRevisions(params).then(data => {
      const revision = (data.records as ProductDefinitionRevision[])[0];
      this.setState({revision: revision, loading: false});
      MessageService.sendMessage(messages.reloadOrderItems);
    });
  }

  async loadProductDefinitions(revisionId: number) {
    this.setState({productDefinitionsLoading: true});
    const params: QueryParameter = {
      aggregate: true,
      filters: [
        JSON.stringify({
          field: 'revision_id',
          value: revisionId,
        }),
      ],
    };
    return this.productDefinitionsService
      ?.getProductDefinitions(params)
      .then(data => {
        const productDefinitions = (data.records as ProductDefinition[]) ?? [];
        //convert definitions
        const paProductDefinitions: PaProductDefinition[] = productDefinitions.map(definition => {
          return {
            product_id: definition.product_id,
            product_name: definition.product?.name ?? '',
            revision_id: definition.revision_id,
            field_definitions: definition.field_definitions,
          };
        });
        this.setState({
          productDefinitions: paProductDefinitions,
        });
      })
      .finally(() => this.setState({productDefinitionsLoading: false}));
  }

  onItemsChanged(newItems: OrderItem[]) {
    const order = this.state.order;

    const updatedOrder = {
      ...order,
      items: newItems,
    } as Order;
    this.setState({
      order: updatedOrder,
    });
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  createDummyOrder(): Order {
    const dummyAddress: Address = {
      country: '',
      lat: 0,
      long: 0,
      postCode: '',
      state: '',
      state_short: '',
      street: '',
      suburb: '',
      phoneNumber: '',
    };
    const dummyOrder: Order = new Order({
      checked_by: '',
      despatched_by: '',
      despatched_on: undefined,
      external_id: '',
      is_m2f: false,
      items: [],
      owner: '',
      post_fix_delivery: false,
      priority: '',
      production_bay: undefined,
      reference: '',
      shipping_address: dummyAddress,
      size: 0,
      stage: '',
      submitted_by: '',
      submitted_on: new Date(),
      type: 'Standard',
    });

    return dummyOrder;
  }

  editOrder(order: Order) {
    this.setState({order: order});
  }

  panelHeaderTemplate(options: PanelHeaderTemplateOptions): React.ReactNode {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} p-ml-3`;
    const modeSelectButtonOptions: {
      value: OrderItemComponentMode;
      label: string;
    }[] = [
      {value: 'simple_edit', label: 'Edit (Simple)'},
      {value: 'advanced_edit', label: 'Edit (Advanced)'},
      {value: 'repair', label: 'Repair'},
    ];

    return (
      <div className={className}>
        <div>
          <button className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon} />
          </button>
          <span className={titleClassName}>
            Revision: {this.state.revision?.id} {this.state.revision?.stage}
          </span>
        </div>
        <SelectButton
          value={this.state.mode}
          options={modeSelectButtonOptions}
          onChange={(e: SelectButtonChangeParams) => {
            console.log(e);
            this.setState({mode: e.value});
          }}
        />
      </div>
    );
  }

  render() {
    let orderItemsContent = <></>;
    if (this.state.productDefinitionsLoading) {
      orderItemsContent = <Button label="Add item" className="p-m-2" loading={this.state.productDefinitionsLoading} />;
    } else if (this.state.productDefinitions.length) {
      orderItemsContent = (
        <OrderItemsComponent
          mode={this.state.mode}
          items={this.state.order.items}
          productDefinitions={this.state.productDefinitions}
          onItemsChanged={this.onItemsChanged}
          setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
        />
      );
    }

    return (
      <div className="revision-rest-component">
        {this.state.revision ? (
          <>
            <Panel headerTemplate={this.panelHeaderTemplate} toggleable>
              <div className="p-grid">
                <div className="p-col-2 p-text-right">
                  <label>revision</label>
                </div>
                <div className="p-col-2">
                  <span>{this.state.revision.id}</span>
                </div>
                <div className="p-col-2 p-text-right">
                  <label>stage</label>
                </div>
                <div className="p-col-2">
                  <span>{this.state.revision.stage}</span>
                </div>
                <div className="p-col-2 p-text-right">
                  <label>release notes</label>
                </div>
                <div className="p-col-2">
                  <span>{this.state.revision.release_notes}</span>
                </div>
                <div className="p-col-2 p-text-right">
                  <label>created</label>
                </div>
                <div className="p-col-2">
                  <span>
                    {this.state.revision.created_at &&
                      DateTime.fromISO(this.state.revision.created_at.toString()).toFormat(formats.date)}
                  </span>
                </div>
                <div className="p-col-2 p-text-right">
                  <label>last update</label>
                </div>
                <div className="p-col-2">
                  <span>
                    {this.state.revision.updated_at &&
                      DateTime.fromISO(this.state.revision.updated_at.toString()).toFormat(formats.date)}
                  </span>
                </div>
                <div className="p-col-2 p-text-right">
                  <label>released</label>
                </div>
                <div className="p-col-2">
                  <span>
                    {this.state.revision?.released_at &&
                      DateTime.fromISO(this.state.revision.released_at.toString()).toFormat(formats.date)}
                  </span>
                </div>
              </div>
            </Panel>
            {orderItemsContent}
          </>
        ) : (
          <OverlayPanel>
            <ProgressSpinner />
          </OverlayPanel>
        )}
      </div>
    );
  }
}

export default withRouter(RevisionTestComponent);
