export const companyStages: string[] = [
  'Prospective',
  'Pending Application',
  'Awaiting Approval',
  'Active',
  'Inactive',
];
export const companyStates: string[] = ['ACT', 'NSW', 'NT', 'NZ', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const values = {
  stopTypingDetection: 1000,
};
export default values;
