export const messages = {
  aplicationUpdated: 'aplication-updated',
  roleUpdated: 'role-updated',
  userUpdated: 'user-updated',
  productUpdated: 'product-updated',
  productGroupUpdated: 'product-group-updated',
  productGroupProductUpdated: 'product-group-product-updated',
  productGroupCustomerUpdated: 'product-group-customer-updated',
  revisionUpdated: 'revision-updated',
  userApplicationUpdated: 'user-application-updated',
  userRoleUpdated: 'user-role-updated',
  companyUpdated: 'company-updated',
  priceDefinitionRevisionUpdated: 'price-definition-revision-updated',
  productPriceDefinitionUpdated: 'product-price-definition-updated',
  reloadRoles: 'reload-roles',
  initTableMenuItems: 'init-table-menu-items',
  reloadOrderItems: 'reload-order-items',
  skuDepartmentUpdated: 'sku-department-updated',
};
