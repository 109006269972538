import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {Toast} from 'primereact/toast';
import {Tooltip} from 'primereact/tooltip';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  MessageService,
  ToastService,
  TwoDataTable,
  TwoDialog,
  UsersService,
} from 'two-app-ui';
import {Application, QueryParameter, Role, User, UserRole} from 'two-core';
import config from '../../config/config';
import {messages} from '../../config/messages';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import RolesService from '../../services/RolesService';

interface Props {
  application: Application;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  users: User[];
  roles: Role[];
  selectedUsers: User[];
  selectedRoles: Role[];
  totalItems: number;
  activeFilters: {};
  filters: {
    fullName: string;
    email: string;
    enabled: string;
    application: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class GrantAccessDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  usersService: UsersService | null = null;
  toastService: ToastService | null = null;
  rolesService: RolesService | null = null;

  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      roles: [],
      selectedUsers: [],
      selectedRoles: [],
      totalItems: 0,
      activeFilters: {},
      filters: {
        fullName: '',
        email: '',
        enabled: '',
        application: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
    };

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadRoles = this.loadRoles.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderAppName = this.renderAppName.bind(this);
    this.renderRolesMultiSelect = this.renderRolesMultiSelect.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onRolesChange = this.onRolesChange.bind(this);
  }

  componentDidMount() {
    this.usersService = this.context.usersService;
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  onHide() {
    this.setState({
      loading: false,
      users: [],
      selectedUsers: [],
      selectedRoles: [],
    });
    this.props.onHide();
  }

  onSave() {
    const {selectedUsers, selectedRoles} = this.state;
    if (!selectedUsers.length) {
      this.toastService?.showError(this.props.toast, 'No users were selected!');
      return;
    }
    if (!selectedRoles.length) {
      this.toastService?.showError(this.props.toast, 'No roles were selected!');
      return;
    }

    this.setState({loading: true});

    const newUserRoles: UserRole[] = [];
    for (const user of selectedUsers) {
      for (const role of selectedRoles) {
        newUserRoles.push({user_id: user.id!, role_id: role.id});
      }
    }

    Promise.all(
      newUserRoles.map((userRole: UserRole) => {
        return this.rolesService?.assignRole(userRole.user_id, userRole.role_id);
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Access granted.');
        this.setState({loading: false});
        this.onHide();
        MessageService.sendMessage(messages.userApplicationUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Access grant failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  onShow() {
    this.loadUsers();
    this.loadRoles();
  }

  loadUsers() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.fullName) {
      filters.push(
        JSON.stringify({
          field: 'full_name',
          value: this.state.filters.fullName,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.email) {
      filters.push(
        JSON.stringify({
          field: 'email',
          value: this.state.filters.email,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.enabled) {
      filters.push(
        JSON.stringify({
          field: 'enabled',
          value: this.state.filters.enabled,
        })
      );
    }
    if (this.state.filters.application) {
      filters.push(
        JSON.stringify({
          field: 'application.name',
          value: this.state.filters.application,
          condition: 'iLike',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'username',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users = data.records as User[];

        const appUsers: User[] = [];
        users.map(u => {
          const apps = u.applications?.filter(a => a !== null) ?? [];

          const ids = apps.map(a => a.id.toString()) ?? [];
          if (!ids.includes(this.props.application.id.toString())) {
            appUsers.push(u);
          }
        });
        this.handleSelectedItems(users);

        this.setState({
          users: appUsers,
          totalItems: appUsers.length,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadRoles() {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'application_id',
        value: this.props.application.id,
      })
    );

    const params: QueryParameter = {filters: filters};

    this.rolesService
      ?.getRoles(params)
      .then(data => {
        const roles = data.records as Role[];

        this.setState({
          roles: roles,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadUsers();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadUsers();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadUsers();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  handleSelectedItems(allItems: User[]) {
    const selectedItems = [...this.state.selectedUsers];
    const items: User[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: User[]) {
    this.setState({selectedUsers: items});
  }

  async setChangeSelectedItem(item: User) {
    const items = [...this.state.selectedUsers];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedUsers: items});
    }
  }

  nameBodyTemplate(rowData: User) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        selectedItems={this.state.selectedUsers}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'/user/' + rowData.id}>{rowData.full_name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  enabledBodyTemplate(rowData: User) {
    const enabledText = rowData.enabled ? 'true' : 'false';
    return <span>{enabledText}</span>;
  }

  applicationsBodyTemplate(rowData: User) {
    const applications = rowData.applications?.filter(a => a !== null) ?? [];

    const applicationsText = applications.map((app, i) => [i > 0 && ', ', this.applicationTemplate(rowData, app)]);

    return applicationsText;
  }

  applicationTemplate(user: User, application: Application) {
    const roles =
      user.roles?.filter(r => r !== null && r.application_id.toString() === application.id.toString()) ?? [];
    return (
      <>
        <Tooltip target={`#application-${application.id}`} position="bottom">
          {roles.map(r => r.name).join(', ')}
        </Tooltip>
        <span id={`application-${application.id}`}>{application.name ?? ''}</span>
      </>
    );
  }

  renderTable() {
    const {selectedUsers, pagination, sortBy, loading, users, totalItems, activeFilters} = this.state;

    const fullNameFilter = (
      <InputText
        name="fullName"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const emailFilter = (
      <InputText
        name="email"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const enabledFilter = (
      <InputText
        name="enabled"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const applicationsFilter = (
      <InputText
        name="application"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="users_page_container" className="page-container">
        <TwoDataTable
          selectedItems={selectedUsers}
          rows={pagination.pageSize}
          first={pagination.offset}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={loading}
          activeFilters={activeFilters}
          value={users}
          totalRecords={totalItems}
          selectionMode="multiple"
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as User[])}
        >
          <Column
            header="Full Name"
            field="full_name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={fullNameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />

          <Column
            header="Email"
            field="email"
            filter
            filterElement={emailFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Enabled"
            field="enabled"
            body={item => this.enabledBodyTemplate(item)}
            filter
            filterElement={enabledFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Apps"
            body={item => this.applicationsBodyTemplate(item)}
            filter
            filterElement={applicationsFilter}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
      </div>
    );
  }

  onRolesChange(e: MultiSelectChangeParams) {
    this.setState({selectedRoles: e.value});
  }

  renderRolesMultiSelect() {
    const {selectedRoles, roles} = this.state;
    return (
      <div className="p-grid p-field w-100 p-mt-3 p-mb-0">
        <label className="p-col-fixed p-mb-0" style={{width: '220px'}}>
          And grant them these roles
        </label>
        <div className="p-col">
          <MultiSelect
            className="w-100"
            value={selectedRoles}
            options={roles}
            onChange={this.onRolesChange}
            optionLabel="name"
            dataKey="id"
            display="chip"
          />
        </div>
      </div>
    );
  }

  renderAppName() {
    return (
      <div className="p-mb-3">
        <span className="p-pr-1">Please, select the users you want to grant access to</span>
        <span className="p-text-bold">{`${this.props.application.name}`}</span>
      </div>
    );
  }

  render() {
    return (
      <TwoDialog
        headerTitle={'Grant Access'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={80}
        onHide={this.onHide}
        onSave={this.onSave}
        onShow={this.onShow}
        loading={this.state.loading}
      >
        <>
          {this.renderAppName()}
          {this.renderTable()}
          {this.renderRolesMultiSelect()}
        </>
      </TwoDialog>
    );
  }
}

export default GrantAccessDialog;
