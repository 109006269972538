import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  MessageService,
  TwoAction,
  ToastService,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimelineItem,
  TwoTimeline,
} from 'two-app-ui';
import {QueryParameter, TimeLineEvent, User} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import {ProgressSpinner} from 'primereact/progressspinner';
import UserDetail from './UserDetail';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import AddEditUserDialog from '../Users/AddEditUserDialog';
import TlesService from '../../services/TlesService';
import UserApplications from './UserApplications';
import AdminUsersService from '../../services/AdminUserService';
import GrantAccessDialog from '../Users/GrantAccessDialog';
import {confirmDialog} from 'primereact/confirmdialog';
import SetPasswordDialog from './SetPasswordDialog';

interface RouteProps {
  id: string;
}

interface State {
  user: User | undefined;
  loadingUser: boolean;
  loadingSecondaryView: boolean;
  items: TwoTimelineItem[];
  events: TimeLineEvent[];
  showEditDialog: boolean;
  showGrantAccessDialog: boolean;
  showSetPasswordDialog: boolean;
}

class UserComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  adminUsersService: AdminUsersService | null = null;
  tlesService: TlesService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingUser: false,
      loadingSecondaryView: false,
      user: undefined,
      items: [],
      events: [],
      showEditDialog: false,
      showGrantAccessDialog: false,
      showSetPasswordDialog: false,
    };

    this.toast = React.createRef();

    this.showEditDialog = this.showEditDialog.bind(this);
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.showGrantAccessDialog = this.showGrantAccessDialog.bind(this);
    this.hideGrantAccessDialog = this.hideGrantAccessDialog.bind(this);
    this.hideSetPasswordDialog = this.hideSetPasswordDialog.bind(this);
  }

  componentDidMount() {
    this.tlesService = this.context.tlesService;
    this.toastService = this.context.toastService;
    this.adminUsersService = this.context.adminUsersService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.userUpdated) {
        this.loadUser();
      }
    });

    this.loadUser();
    //this.loadEvents();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  loadUser() {
    const id = this.props.match.params.id;

    this.setState({loadingUser: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.adminUsersService
      ?.getUsers(params)
      .then(data => {
        const user = (data.records as User[])[0];
        this.setState({
          user: user,
          loadingUser: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, User load failed, please try again.');
        this.setState({
          loadingUser: false,
        });
      });
  }

  loadEvents() {
    const id = this.props.match.params.id;

    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'user',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tlesService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  showEditDialog() {
    this.setState({
      showEditDialog: true,
    });
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  showGrantAccessDialog() {
    this.setState({showGrantAccessDialog: true});
  }

  hideGrantAccessDialog() {
    this.setState({showGrantAccessDialog: false});
  }

  hideSetPasswordDialog() {
    this.setState({showSetPasswordDialog: false});
  }

  confirmPasswordReset(id: string) {
    confirmDialog({
      message: "Are you sure you want to reset this user's Password?",
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.adminUsersService?.resetUserPassword(id);
      },
    });
  }

  confirmDisableEnableUser() {
    const action = this.state.user!.enabled ? 'Disable' : 'Enable';
    confirmDialog({
      message: `Are you sure you want to ${action} this user?`,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.disableEnableUser();
      },
    });
  }

  async disableEnableUser() {
    this.setState({loadingUser: true});
    const user = this.state.user!;

    const updatedUser: User = {
      ...user,
      enabled: !(user.enabled ?? false),
    };
    this.adminUsersService
      ?.saveUser(user.id ?? '', updatedUser)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Users update successfully.');
        this.setState({loadingUser: false});
        this.loadUser();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, User update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loadingUser: false});
      });
  }

  getActions(user: User): TwoAction[] {
    const actions: TwoAction[] = [];
    actions.push({
      icon: ['far', 'pencil'] as IconProp,
      label: 'Edit',
      main: true,
      action: () => {
        this.showEditDialog();
      },
    });

    actions.push({
      icon: ['far', 'key'] as IconProp,
      label: 'Reset Password',
      main: false,
      action: () => {
        this.confirmPasswordReset(user.id!);
      },
    });

    actions.push({
      icon: ['far', 'key'] as IconProp,
      label: 'Set Password',
      main: false,
      action: () => {
        this.setState({showSetPasswordDialog: true});
      },
    });

    actions.push({
      icon: user.enabled ? (['far', 'ban'] as IconProp) : (['far', 'check'] as IconProp),
      label: user.enabled ? 'Disable' : 'Enable',
      main: false,
      action: () => {
        this.confirmDisableEnableUser();
      },
    });

    actions.push({
      icon: ['far', 'plus'] as IconProp,
      label: 'Grant Access',
      main: false,
      action: () => {
        this.showGrantAccessDialog();
      },
    });

    return actions;
  }

  render() {
    const {user, items, showGrantAccessDialog, showSetPasswordDialog} = this.state;

    return user ? (
      <>
        <TwoEntityComponent title={user.full_name ?? ''} actions={this.getActions(user)}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingUser ? <UserDetail user={user} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Applications" icon={['fab', 'app-store']} tooltip="Applications">
            {!this.state.loadingSecondaryView ? <UserApplications user={user} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Settings" icon={['far', 'cog']} tooltip="Settings">
            {!this.state.loadingSecondaryView ? <></> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={['far', 'calendar-alt']} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? <TwoTimeline key={user.id} items={items} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <Toast ref={this.toast} />
        <AddEditUserDialog
          userId={user?.id}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
          toast={this.toast}
        />
        <GrantAccessDialog
          user={user}
          showDialog={showGrantAccessDialog}
          onHide={this.hideGrantAccessDialog}
          toast={this.toast}
        />
        <SetPasswordDialog
          user={user}
          showDialog={showSetPasswordDialog}
          onHide={this.hideSetPasswordDialog}
          toast={this.toast}
        />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(UserComponent);
