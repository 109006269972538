import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Tooltip} from 'primereact/tooltip';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {Application, DropdownOption, QueryParameter, User} from 'two-core';
import config from '../../config/config';
import AddEditUserDialog from './AddEditUserDialog';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import {NavLink} from 'react-router-dom';
import {confirmDialog} from 'primereact/confirmdialog';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {faToggleOff, faToggleOn} from '@fortawesome/pro-regular-svg-icons';
import AdminUsersService from '../../services/AdminUserService';
import SetPasswordDialog from '../User/SetPasswordDialog';

export const userTypes: DropdownOption[] = [
  {label: 'Two', value: 'two'},
  {label: 'Dealer', value: 'dealer'},
];

interface State {
  loading: boolean;
  items: User[];
  selectedItems: User[];
  totalItems: number;
  activeFilters: {};
  filters: {
    fullName: string;
    username: string;
    email: string;
    enabled: string;
    application: string;
    type: string[];
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
  userId: string | undefined;
  showPermissionsDialog: boolean;
  showTypeColumn: boolean;
  showSetPasswordDialog: boolean;
}

class UserListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  adminUsersService: AdminUsersService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        fullName: '',
        username: '',
        email: '',
        enabled: '',
        application: '',
        type: [],
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
      userId: undefined,
      showPermissionsDialog: false,
      showTypeColumn: false,
      showSetPasswordDialog: false,
    };

    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.enabledBodyTemplate = this.enabledBodyTemplate.bind(this);
    this.applicationsBodyTemplate = this.applicationsBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.showAddEditDialog = this.showAddEditDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.hideSetPasswordDialog = this.hideSetPasswordDialog.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.adminUsersService = this.context.adminUsersService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.userUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddEditDialog();
      },
    });

    menuItems.push({
      label: `${this.state.showTypeColumn ? 'Hide Dealer Users' : 'Show Dealer Users'}`,
      faIcon: this.state.showTypeColumn ? faToggleOn : faToggleOff,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: async () => {
        await this.setState({showTypeColumn: !this.state.showTypeColumn});
        MessageService.sendMessage(messages.initTableMenuItems);
        this.loadData();
      },
    });

    if (selectedItemsCount > 0) {
      if (selectedItemsCount === 1) {
        const id = selectedItems[0].id!;
        menuItems.push({
          label: 'Reset Password',
          faIcon: ['far', 'key'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.confirmPasswordReset(id);
          },
        });

        menuItems.push({
          label: 'Set Password',
          faIcon: ['far', 'key'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.setState({showSetPasswordDialog: true});
          },
        });
      }

      if (selectedItems.every(user => user.enabled)) {
        menuItems.push({
          label: 'Disable',
          faIcon: ['far', 'ban'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.confirmDisableEnableUsers(false);
          },
        });
      }

      if (selectedItems.every(user => !user.enabled)) {
        menuItems.push({
          label: 'Enable',
          faIcon: ['far', 'check'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.confirmDisableEnableUsers(true);
          },
        });
      }
    }

    return menuItems;
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.fullName) {
      filters.push(
        JSON.stringify({
          field: 'full_name',
          value: this.state.filters.fullName,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.username) {
      filters.push(
        JSON.stringify({
          field: 'username',
          value: this.state.filters.username,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.email) {
      filters.push(
        JSON.stringify({
          field: 'email',
          value: this.state.filters.email,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.enabled) {
      filters.push(
        JSON.stringify({
          field: 'enabled',
          value: this.state.filters.enabled,
        })
      );
    }
    if (this.state.filters.application) {
      filters.push(
        JSON.stringify({
          field: 'application.name',
          value: this.state.filters.application,
          condition: 'iLike',
        })
      );
    }
    if (!this.state.showTypeColumn) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: 'two',
        })
      );
    } else {
      if (this.state.filters.type.length) {
        filters.push(
          JSON.stringify({
            field: 'type',
            value: this.state.filters.type,
            condition: 'in',
          })
        );
      }
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'username',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.adminUsersService
      ?.getUsers(params)
      .then(data => {
        const users = data.records as User[];
        this.handleSelectedItems(users);

        this.setState({
          items: users,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  showAddEditDialog() {
    this.setState({userId: undefined, showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({userId: undefined, showAddEditDialog: false});
  }

  hideSetPasswordDialog() {
    this.setState({showSetPasswordDialog: false});
  }

  confirmPasswordReset(id: string) {
    confirmDialog({
      message: "Are you sure you want to reset this user's Password?",
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.adminUsersService?.resetUserPassword(id);
      },
    });
  }

  confirmDisableEnableUsers(enable: boolean) {
    const action = enable ? 'Enable' : 'Disable';
    confirmDialog({
      message: `Are you sure you want to ${action} this users?`,
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.disableEnableUsers(enable);
      },
    });
  }

  async disableEnableUsers(enable: boolean) {
    this.setState({loading: true});
    const users: User[] = this.state.selectedItems;

    const promises = users.map((user: User) => {
      const updatedUser: User = {
        ...user,
        enabled: enable,
      };
      return this.saveUser(updatedUser);
    });

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Users update successfully.');
        this.setState({loading: false});
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Users update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async saveUser(user: User) {
    return this.adminUsersService?.saveUser(user.id ?? '', user).then(() => {});
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  handleSelectedItems(allItems: User[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: User[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: User[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: User) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  nameBodyTemplate(rowData: User) {
    return (
      <AppColumnMenuBodyTemplate
        key={'x' + rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'/user/' + rowData.id}>{rowData.full_name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  enabledBodyTemplate(rowData: User) {
    const enabledText = rowData.enabled ? 'true' : 'false';
    return <span>{enabledText}</span>;
  }

  applicationsBodyTemplate(rowData: User) {
    const applications = rowData.applications?.filter(a => a !== null) ?? [];

    return applications.map((app, i) => [i > 0 && ', ', this.applicationTemplate(rowData, app)]);
  }

  applicationTemplate(user: User, application: Application) {
    const roles =
      user.roles?.filter(r => r !== null && r.application_id.toString() === application.id.toString()) ?? [];
    const id = `user-app-${user.id}-${application.id}`;
    return (
      <>
        <Tooltip key={id} target={`#${id}`} position="bottom">
          {roles.map(r => r.name).join(', ')}
        </Tooltip>
        <span id={id}>{application.name ?? ''}</span>
      </>
    );
  }

  render() {
    this.initMenuItems();
    const nameFilter = (
      <InputText
        name="username"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const fullNameFilter = (
      <InputText
        name="fullName"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const emailFilter = (
      <InputText
        name="email"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const enabledFilter = (
      <InputText
        name="enabled"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const applicationsFilter = (
      <InputText
        name="application"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const typeFilter = (
      <MultiSelect
        value={this.state.filters.type}
        options={userTypes}
        name="type"
        className="form-filter stage-filter"
        onChange={e => this.onFilterChange(e)}
        showClear
      />
    );

    return (
      <div id="users_page_container" className="page-container">
        <TwoDataTable
          id={'users-table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'users_page_container'}
          initMenuItems={this.initMenuItems}
          selectionMode="multiple"
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as User[])}
        >
          <Column
            header="Full Name"
            field="full_name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={fullNameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />

          <Column
            header="Username"
            field="username"
            filter
            filterElement={nameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Email"
            field="email"
            filter
            filterElement={emailFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          {this.state.showTypeColumn && (
            <Column
              header="Type"
              field="type"
              style={{width: '180px'}}
              sortable
              showFilterMenu={false}
              filter
              filterElement={typeFilter}
            />
          )}
          <Column
            header="Enabled"
            field="enabled"
            body={item => this.enabledBodyTemplate(item)}
            filter
            filterElement={enabledFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Apps"
            body={item => this.applicationsBodyTemplate(item)}
            filter
            filterElement={applicationsFilter}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditUserDialog
          toast={this.toast}
          userId={this.state.userId}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
        />
        <SetPasswordDialog
          user={this.state.selectedItems[0]}
          showDialog={this.state.showSetPasswordDialog}
          onHide={this.hideSetPasswordDialog}
          toast={this.toast}
        />
      </div>
    );
  }
}

export default UserListComponent;
