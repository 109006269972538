import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, Product} from 'two-core';

class ProductsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.products ?? '';
  }

  async getProducts(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateProduct(id: string, data: Product): Promise<Product> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as unknown as Product);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProduct(data: Product): Promise<Product> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as unknown as Product);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ProductsService;
