import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Product, ProductPriceDefinition, ProductPriceDefinitionPriceItem, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import {SurchargeProduct} from 'two-core';
import ProductPriceDefinitionsService from '../../services/ProductPriceDefinitionsService';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown, DropdownFilterParams} from 'primereact/dropdown';
import {priceListScope, priceListTypes} from '../../config/priceListConstants';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import ProductsService from '../../services/ProductsService';
import {ProductDropdownOption} from './Models/ProductDropdownOption';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  currentProductPriceDefinition: ProductPriceDefinition;
  index?: number;
  productPriceDefinitionPriceItem?: ProductPriceDefinitionPriceItem;
}

interface State {
  updatedProductPriceDefinitionPriceItem: ProductPriceDefinitionPriceItem | undefined;
  productDropdownOptionList: ProductDropdownOption[];
}

class AddEditSurchargeDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  productPriceDefinitionsService: ProductPriceDefinitionsService | null = null;
  productsService: ProductsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      updatedProductPriceDefinitionPriceItem: undefined,
      productDropdownOptionList: [],
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.setValue = this.setValue.bind(this);
    this.onProductDropdownFilter = this.onProductDropdownFilter.bind(this);
    this.onProductDropdownChange = this.onProductDropdownChange.bind(this);
    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.productPriceDefinitionsService = this.context.productPriceDefinitionsService;
    this.productsService = this.context.productsService;
    this.toastService = this.context.toastService;

    this.prepareDropdownData('');
  }

  onShow() {
    const productPriceDefinitionPriceItem = this.props.productPriceDefinitionPriceItem;
    if (productPriceDefinitionPriceItem) {
      this.setState({
        updatedProductPriceDefinitionPriceItem: productPriceDefinitionPriceItem,
      });
    }
  }

  hideDialog() {
    this.props.onHide();
    this.setState({updatedProductPriceDefinitionPriceItem: undefined});
  }

  async save() {
    const index = this.props.index;
    const productPriceDefinition: ProductPriceDefinition = this.props.currentProductPriceDefinition;
    if (index === undefined) {
      productPriceDefinition.price_definitions.surcharges.push(
        this.state.updatedProductPriceDefinitionPriceItem as ProductPriceDefinitionPriceItem
      );
    } else {
      productPriceDefinition.price_definitions.surcharges[index] = this.state
        .updatedProductPriceDefinitionPriceItem as ProductPriceDefinitionPriceItem;
    }
    this.productPriceDefinitionsService
      ?.updateProductPriceDefinition(productPriceDefinition)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Surcharge updated.');
        MessageService.sendMessage(messages.productPriceDefinitionUpdated);
        this.hideDialog();
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Surcharge update failed, please try again.');
        console.error(error);
      });
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    );
  }

  async setValue(title: string, value: string) {
    const surcharge = this.state.updatedProductPriceDefinitionPriceItem ?? this.props.productPriceDefinitionPriceItem;
    if (surcharge) {
      const updatedProductPriceItem: ProductPriceDefinitionPriceItem = {
        ...surcharge,
        [title]: value,
      };
      await this.setState({
        updatedProductPriceDefinitionPriceItem: updatedProductPriceItem,
      });
    }
  }

  async prepareDropdownData(productName: string) {
    const dropdownProducts: ProductDropdownOption[] = [];
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'name',
        value: productName,
        condition: 'iLike',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.productsService
      ?.getProducts(params)
      .then(data => {
        const products = (data?.records as Product[]) ?? [];
        products.map((product: Product) =>
          dropdownProducts.push({
            label: product.name,
            product: {
              product_id: product.id as number,
              product_key: product.interpreter_key,
            },
          })
        );

        this.setState({
          productDropdownOptionList: dropdownProducts,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Product load failed, please try again.');
        console.error(error);
      });
  }

  onProductDropdownFilter(e: DropdownFilterParams) {
    this.prepareDropdownData(e.filter);
  }
  onProductDropdownChange(e: MultiSelectChangeParams) {
    const updatedProductPriceFreightSurcharge = this.state
      .updatedProductPriceDefinitionPriceItem as ProductPriceDefinitionPriceItem;
    updatedProductPriceFreightSurcharge.group_with = e.value as SurchargeProduct[];
    this.setState({
      updatedProductPriceDefinitionPriceItem: updatedProductPriceFreightSurcharge,
    });
  }

  render() {
    const {updatedProductPriceDefinitionPriceItem, productDropdownOptionList} = this.state;
    const {index} = this.props;

    return (
      <Dialog
        header={`${index !== undefined ? 'Edit' : 'Add'} Surcharge`}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '70%'}}
        modal
        onHide={() => this.hideDialog()}
        closable={false}
        onShow={this.onShow}
      >
        {
          <>
            <div className="p-grid">
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="title" className="p-col-1 p-p-0">
                  title
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <InputText
                      value={updatedProductPriceDefinitionPriceItem?.title ?? ''}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('title', value);
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="sku" className="p-col-1 p-p-0">
                  sku
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <InputText
                      value={updatedProductPriceDefinitionPriceItem?.sku ?? ''}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('sku', value);
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="scope" className="p-col-1 p-p-0">
                  scope
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      showClear
                      value={updatedProductPriceDefinitionPriceItem?.scope}
                      options={priceListScope}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('scope', value);
                      }}
                      placeholder="Pick scope"
                      optionLabel="label"
                      optionValue="value"
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="price_list_type" className="p-col-1 p-p-0">
                  price list type
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      showClear
                      value={updatedProductPriceDefinitionPriceItem?.price_list_type}
                      options={priceListTypes}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('price_list_type', value);
                      }}
                      placeholder="Pick price list type"
                      optionLabel="label"
                      optionValue="value"
                    />
                  </span>
                </div>
                <label htmlFor="group_with" className="p-col-1 p-p-0">
                  group with
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <MultiSelect
                      filter
                      display="chip"
                      onFilter={this.onProductDropdownFilter}
                      options={productDropdownOptionList}
                      showClear
                      value={updatedProductPriceDefinitionPriceItem?.group_with}
                      onChange={this.onProductDropdownChange}
                      placeholder="group with"
                      optionLabel="label"
                      optionValue="product"
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="when_condition" className="p-col-1 p-p-0">
                  when condition
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="when_condition"
                      name="when_condition"
                      value={updatedProductPriceDefinitionPriceItem?.when_condition ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('when_condition', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="description_script" className="p-col-1 p-p-0">
                  description
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="description_script"
                      name="description_script"
                      value={updatedProductPriceDefinitionPriceItem?.description_script ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('description_script', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="qty_calculation" className="p-col-1 p-p-0">
                  qty calculation
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="qty_calculation"
                      name="qty_calculation"
                      value={updatedProductPriceDefinitionPriceItem?.qty_calculation ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('qty_calculation', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="unit_script" className="p-col-1 p-p-0">
                  unit
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="unit_script"
                      name="unit_script"
                      value={updatedProductPriceDefinitionPriceItem?.unit_script ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('unit_script', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="unit_price_calculation" className="p-col-1 p-p-0">
                  unit price
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="unit_price_calculation"
                      name="unit_price_calculation"
                      value={updatedProductPriceDefinitionPriceItem?.unit_price_calculation ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('unit_price_calculation', value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-flex-wrap p-ai-center p-col-12 p-p-0">
                <label htmlFor="line_total_calculation" className="p-col-1 p-p-0">
                  total
                </label>
                <div className="p-col-11">
                  <span className="p-fluid">
                    <InputTextarea
                      className="w-100"
                      autoResize={true}
                      id="line_total_calculation"
                      name="line_total_calculation"
                      value={updatedProductPriceDefinitionPriceItem?.line_total_calculation ?? ''}
                      rows={5}
                      cols={85}
                      onChange={e => {
                        const value = e.target.value;
                        this.setValue('line_total_calculation', value);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </>
        }
      </Dialog>
    );
  }
}
export default AddEditSurchargeDialog;
