import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {AppContext, MessageService, TwoAction, ToastService, TwoEntityComponent, TwoEntityPanel} from 'two-app-ui';
import {Application, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import {ProgressSpinner} from 'primereact/progressspinner';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import ApplicationsService from '../../services/ApplicationsService';
import AddEditApplicationDialog from '../Applications/AddEditApplicationDialog';
import ApplicationDetail from './ApplicationDetail';
import ApplicationRoles from './ApplicationRoles';
import ApplicationUsers from './ApplicationUsers';
import AddEditRoleDialog from '../Roles/AddEditRoleDialog';
import GrantAccessDialog from './GrantAccessDialog';

interface RouteProps {
  id: string;
}

interface State {
  application: Application | undefined;
  loadingApplication: boolean;
  loadingSecondaryView: boolean;
  showEditDialog: boolean;
  showAddRoleDialog: boolean;
  showGrantAccessDialog: boolean;
}

class ApplicationComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  applicationsService: ApplicationsService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingApplication: false,
      loadingSecondaryView: false,
      application: undefined,
      showEditDialog: false,
      showAddRoleDialog: false,
      showGrantAccessDialog: false,
    };

    this.toast = React.createRef();

    this.showEditDialog = this.showEditDialog.bind(this);
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.showAddRoleDialog = this.showAddRoleDialog.bind(this);
    this.hideAddEditRoleDialog = this.hideAddEditRoleDialog.bind(this);
    this.showGrantAccessDialog = this.showGrantAccessDialog.bind(this);
    this.hideGrantAccessDialog = this.hideGrantAccessDialog.bind(this);
  }

  componentDidMount() {
    this.applicationsService = this.context.applicationsService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.aplicationUpdated) {
        this.loadApplication();
      }
    });

    this.loadApplication();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  loadApplication() {
    const id = this.props.match.params.id;

    this.setState({loadingApplication: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.applicationsService
      ?.getApplications(params)
      .then(data => {
        const application = (data.records as Application[])[0];
        this.setState({
          application: application,
          loadingApplication: false,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, Application load failed, please try again.');
        this.setState({
          loadingApplication: false,
        });
      });
  }

  showEditDialog() {
    this.setState({
      showEditDialog: true,
    });
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  getActions(): TwoAction[] {
    const actions: TwoAction[] = [
      {
        icon: ['far', 'pencil'] as IconProp,
        label: 'Edit',
        main: true,
        action: () => {
          this.showEditDialog();
        },
      },
      {
        icon: ['far', 'plus'] as IconProp,
        label: 'Add Role',
        action: () => {
          this.showAddRoleDialog();
        },
      },
      {
        icon: ['far', 'plus'] as IconProp,
        label: 'Grant Access',
        action: () => {
          this.showGrantAccessDialog();
        },
      },
    ];

    return actions;
  }

  showAddRoleDialog() {
    this.setState({showAddRoleDialog: true});
  }

  hideAddEditRoleDialog() {
    this.setState({showAddRoleDialog: false});
  }

  showGrantAccessDialog() {
    this.setState({showGrantAccessDialog: true});
  }

  hideGrantAccessDialog() {
    this.setState({showGrantAccessDialog: false});
  }

  render() {
    const {application} = this.state;

    return application ? (
      <>
        <TwoEntityComponent title={application.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingApplication ? <ApplicationDetail application={application} /> : <ProgressSpinner />}
          </TwoEntityPanel>

          <TwoEntityPanel label="Roles" icon={['far', 'user-tag']} tooltip="Roles">
            {!this.state.loadingSecondaryView ? <ApplicationRoles application={application} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Users" icon={['far', 'users']} tooltip="Users">
            {!this.state.loadingSecondaryView ? <ApplicationUsers application={application} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <Toast ref={this.toast} />
        <AddEditApplicationDialog
          applicationId={application?.id}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
          toast={this.toast}
        />
        <AddEditRoleDialog
          toast={this.toast}
          application={application}
          showDialog={this.state.showAddRoleDialog}
          onHide={this.hideAddEditRoleDialog}
          roleId={undefined}
        />
        {}
        <GrantAccessDialog
          application={application}
          showDialog={this.state.showGrantAccessDialog}
          onHide={this.hideGrantAccessDialog}
          toast={this.toast}
        />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(ApplicationComponent);
