import {ApiService, AuthService} from 'two-app-ui';
import {User, QueryParameter, ApiListResponse, ApiResponse, TwoType} from 'two-core';
import config from '../config/config';

export class AdminUsersService extends ApiService {
  protected endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints?.users as string;
  }

  async getUsers(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async getUser(uuid: string): Promise<User> {
    return this.get(this.endpoint + '/' + uuid)
      .then((response: ApiResponse | ApiListResponse) => {
        return Promise.resolve(response as unknown as User);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async findUserbyUsername(username: string): Promise<User> {
    const params: QueryParameter = {
      offset: 0,
      page_size: 1,
      filters: [
        JSON.stringify({
          field: 'username',
          value: username,
        }),
      ],
    };

    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        const users = (data as ApiListResponse).records as User[];
        return Promise.resolve(users[0]);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createUser(params: User): Promise<User> {
    return this.post(this.endpoint, params)
      .then((response: unknown) => {
        console.log(response);
        return Promise.resolve(response as User);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async saveUser(uuid: string, params: User): Promise<User> {
    return this.patch(this.endpoint + '/' + uuid, params)
      .then((response: TwoType) => {
        console.log(response);
        return Promise.resolve(response as User);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async resetUserPassword(uuid: string): Promise<void> {
    return this.get(`${this.endpoint}/${uuid}/reset-password`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async setUserPassword(uuid: string, password: string): Promise<void> {
    return this.post(`${this.endpoint}/${uuid}/set-password`, {
      password: password,
    })
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default AdminUsersService;
