import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Tooltip} from 'primereact/tooltip';
import {Divider} from 'primereact/divider';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  UsersService,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {Application, QueryParameter, Role, User} from 'two-core';
import config from '../../config/config';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import {NavLink} from 'react-router-dom';
import GrantAccessDialog from './GrantAccessDialog';
import ApplicationsService from '../../services/ApplicationsService';
import ManageRolesDialog from './ManageRolesDialog';
import RolesService from '../../services/RolesService';
import RevokeAccessDialog from '../User/RevokeAccesDialog';

interface Props {
  application: Application;
}

interface State {
  loading: boolean;
  items: User[];
  selectedItems: User[];
  totalItems: number;
  activeFilters: {};
  filters: {
    fullName: string;
    email: string;
    enabled: string;
    role: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showGrantAccessDialog: boolean;
  showAssignAccessDialog: boolean;
  showRevokeAccessDialog: boolean;
}

class ApplicationUserListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  usersService: UsersService | null = null;
  applicationsService: ApplicationsService | null = null;
  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        fullName: '',
        email: '',
        enabled: '',
        role: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showGrantAccessDialog: false,
      showAssignAccessDialog: false,
      showRevokeAccessDialog: false,
    };

    this.toast = React.createRef();

    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.enabledBodyTemplate = this.enabledBodyTemplate.bind(this);
    this.rolesTemplate = this.rolesTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.initTopMenuItems = this.initTopMenuItems.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.showGrantAccessDialog = this.showGrantAccessDialog.bind(this);
    this.hideGrantAccessDialog = this.hideGrantAccessDialog.bind(this);
    this.showAssignAccessDialog = this.showAssignAccessDialog.bind(this);
    this.hideAssignAccessDialog = this.hideAssignAccessDialog.bind(this);
  }

  componentDidMount() {
    this.usersService = this.context.usersService;
    this.applicationsService = this.context.applicationsService;
    this.toastService = this.context.toastService;
    this.rolesService = this.context.rolesService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (
        message === messages.userUpdated ||
        message === messages.userApplicationUpdated ||
        message === messages.userRoleUpdated
      ) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initTopMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Grant App Access',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showGrantAccessDialog();
      },
    });

    const otherMenuItems = this.initMenuItems();
    menuItems.push(...otherMenuItems);

    return menuItems;
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    const menuItems: AppMenuItem[] = [];

    if (selectedItemsCount) {
      menuItems.push({
        label: 'Manage Roles',
        faIcon: ['far', 'user-tag'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAssignAccessDialog();
        },
      });
    }

    if (selectedItemsCount === 1) {
      menuItems.push({
        label: 'Revoke Access',
        faIcon: ['far', 'minus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.setState({showRevokeAccessDialog: true});
        },
      });
    }

    return menuItems;
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'application.id',
        value: this.props.application.id,
      })
    );

    if (this.state.filters.fullName) {
      filters.push(
        JSON.stringify({
          field: 'full_name',
          value: this.state.filters.fullName,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.email) {
      filters.push(
        JSON.stringify({
          field: 'email',
          value: this.state.filters.email,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.enabled) {
      filters.push(
        JSON.stringify({
          field: 'enabled',
          value: this.state.filters.enabled,
        })
      );
    }
    if (this.state.filters.role) {
      filters.push(
        JSON.stringify({
          field: 'role.name',
          value: this.state.filters.role,
          condition: 'iLike',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'username',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users = data.records as User[];
        this.handleSelectedItems(users);

        this.setState({
          items: users,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  handleSelectedItems(allItems: User[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: User[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: User[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: User) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  showGrantAccessDialog() {
    this.setState({showGrantAccessDialog: true});
  }

  hideGrantAccessDialog() {
    this.setState({showGrantAccessDialog: false});
  }

  showAssignAccessDialog() {
    this.setState({showAssignAccessDialog: true});
  }

  hideAssignAccessDialog() {
    this.setState({showAssignAccessDialog: false});
  }

  async removeUserApplications() {
    this.setState({loading: true});

    const selectedUsers = this.state.selectedItems;
    const promises = [];
    for (const user of selectedUsers) {
      const roles = user.roles ?? [];
      promises.push(
        ...roles.map(role => {
          return this.rolesService?.unassignRole(user.id!, role.id);
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'User Applications deleted successfully.');
        this.setState({loading: false, items: []});
        MessageService.sendMessage(messages.userApplicationUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, User Applications delete failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  nameBodyTemplate(rowData: User) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'/user/' + rowData.id}>{rowData.full_name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  enabledBodyTemplate(rowData: User) {
    const enabledText = rowData.enabled ? 'true' : 'false';
    return <span>{enabledText}</span>;
  }

  rolesTemplate(rowData: User) {
    const roles = rowData.roles?.filter(r => r !== null) ?? [];

    return roles.map((role, i) => [i > 0 && ', ', this.roleTemplate(role)]);
  }

  roleTemplate(role: Role) {
    return (
      <>
        <Tooltip target={`#role-${role.id}`} position="bottom">
          <div className="p-d-flex p-flex-column">
            <div> {role.description ?? ''}</div>
            <Divider />
            <div> {role.permissions?.filter(p => p !== null)?.join(', ')}</div>
          </div>
        </Tooltip>
        <span id={`role-${role.id}`}>{role.name ?? ''}</span>
      </>
    );
  }

  render() {
    const fullNameFilter = (
      <InputText
        name="fullName"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const emailFilter = (
      <InputText
        name="email"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const enabledFilter = (
      <InputText
        name="enabled"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const rolesFilter = (
      <InputText
        name="role"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="app_users_page_container" className="page-container">
        <TwoDataTable
          id={'app-users-table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'app_users_page_container'}
          initMenuItems={this.initTopMenuItems}
          selectionMode="multiple"
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as User[])}
        >
          <Column
            header="Full Name"
            field="full_name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={fullNameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Email"
            field="email"
            filter
            filterElement={emailFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Enabled"
            field="enabled"
            body={item => this.enabledBodyTemplate(item)}
            filter
            filterElement={enabledFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Roles"
            body={item => this.rolesTemplate(item)}
            filter
            filterElement={rolesFilter}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <GrantAccessDialog
          application={this.props.application}
          showDialog={this.state.showGrantAccessDialog}
          onHide={this.hideGrantAccessDialog}
          toast={this.toast}
        />
        <ManageRolesDialog
          application={this.props.application}
          showDialog={this.state.showAssignAccessDialog}
          onHide={this.hideAssignAccessDialog}
          toast={this.toast}
          users={this.state.selectedItems}
        />
        {!!this.state.selectedItems.length && (
          <RevokeAccessDialog
            showDialog={this.state.showRevokeAccessDialog}
            onHide={() => this.setState({showRevokeAccessDialog: false})}
            toast={this.toast}
            applications={[this.props.application]}
            user={this.state.selectedItems[0]}
          />
        )}
      </div>
    );
  }
}

export default ApplicationUserListComponent;
