import React from 'react';
import {InputText} from 'primereact/inputtext';
import {ToastService, AppContext, MessageService, TwoDialog} from 'two-app-ui';
import {ProductGroup, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import ProductGroupService from '../../services/ProductGroupService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  productGroupId: number | undefined;
}

interface State {
  loading: boolean;
  productGroup: ProductGroup | undefined;
}

class AddEditProductGroupDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  productGroupService: ProductGroupService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      productGroup: undefined,
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.save = this.save.bind(this);
    this.setAndLoadData = this.setAndLoadData.bind(this);
    this.loadProductGroup = this.loadProductGroup.bind(this);
  }

  componentDidMount() {
    this.productGroupService = this.context.productGroupService;
    this.toastService = this.context.toastService;
  }

  setAndLoadData() {
    const productGroupId = this.props.productGroupId;
    if (productGroupId) {
      this.loadProductGroup(productGroupId);
    } else {
      const productGroup: ProductGroup = {
        id: 0,
        name: '',
      };
      this.setState({
        productGroup: productGroup,
      });
    }
  }

  hideDialog() {
    this.setState({productGroup: undefined, loading: false});
    this.props.onHide();
  }

  loadProductGroup(id: number) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };

    this.productGroupService
      ?.getProductGroups(params)
      .then(data => {
        const dataRecords = (data?.records as ProductGroup[]) ?? [];
        const productGroup = dataRecords[0];

        this.setState({
          productGroup: productGroup,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Product Group load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const productGroup = this.state.productGroup;
    if (productGroup) {
      if (productGroup.id) {
        this.updateProductGroup(productGroup);
      } else {
        this.createProductGroup(productGroup);
      }
    }
  }

  async createProductGroup(productGroup: ProductGroup) {
    this.setState({loading: true});

    return this.productGroupService
      ?.createProductGroup(productGroup)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Product Group created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.productGroupUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Product Group create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  async updateProductGroup(productGroup: ProductGroup) {
    this.setState({loading: true});

    return this.productGroupService
      ?.updateProductGroup(productGroup?.id?.toString() ?? '', productGroup)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Product Group updated successfully.');

        this.hideDialog();
        MessageService.sendMessage(messages.productGroupUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, ProductGroup update failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  handleNameChange(title: string, value: string) {
    const productGroup = this.state.productGroup;
    if (productGroup) {
      const updatedProductGroup: ProductGroup = {
        ...productGroup,
        [title]: value,
      };
      this.setState({productGroup: updatedProductGroup});
    }
  }

  render() {
    const {productGroup} = this.state;

    const dialogBody = (
      <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
        <label htmlFor="name" className="p-col-1">
          name
        </label>
        <div className="p-col-11 p-p-0">
          <span className="p-fluid">
            <InputText
              value={productGroup?.name ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.handleNameChange('name', value);
              }}
            />
          </span>
        </div>
      </div>
    );

    return (
      <TwoDialog
        headerTitle={productGroup?.id && productGroup.id !== 0 ? 'Edit Product Group' : 'Add Product Group'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={30}
        onShow={this.setAndLoadData}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default AddEditProductGroupDialog;
