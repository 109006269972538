import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {QueryParameter, SkuDepartment} from 'two-core';
import config from '../../config/config';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import {MultiSelectChangeParams} from 'primereact/multiselect';
import AddEditSkuDepartmentDialog from './AddEditSkuDepartmentDialog';
import SkuDepartmentService from '../../services/SkuDepartmentService';

interface State {
  loading: boolean;
  items: SkuDepartment[];
  selectedItems: SkuDepartment[];
  totalItems: number;
  activeFilters: {};
  filters: {
    sku: string;
    department: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
  skuDepartment: SkuDepartment | undefined;
}

class SkuDepartmentListComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  toastService: ToastService | null = null;
  skuDepartmentService: SkuDepartmentService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props = {}) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        sku: '',
        department: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
      skuDepartment: undefined,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.showAddEditDialog = this.showAddEditDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);

    this.toast = React.createRef();
  }

  componentDidMount() {
    this.skuDepartmentService = this.context.skuDepartmentService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.skuDepartmentUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [
      {
        label: 'Add New',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddEditDialog();
        },
      },
    ];

    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;
    if (selectedItemsCount === 1) {
      menuItems.push({
        label: 'Delete',
        faIcon: ['far', 'minus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: async () => {
          await this.skuDepartmentService?.deleteSkuDepartment(selectedItems[0].id as number);
          this.loadData();
        },
      });
    }

    return menuItems;
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.sku) {
      filters.push(
        JSON.stringify({
          field: 'sku',
          value: this.state.filters.sku,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.department) {
      filters.push(
        JSON.stringify({
          field: 'department',
          value: this.state.filters.department,
          condition: 'iLike',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'department',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.skuDepartmentService
      ?.getSkuDepartments(params)
      .then(data => {
        const skuDepartments = data.records as SkuDepartment[];
        this.handleSelectedItems(skuDepartments);

        this.setState({
          items: skuDepartments,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  onPageChange(e: DataTablePageParams) {
    this.setState({pagination: {offset: e.first, pageSize: e.rows}}, () => {
      this.loadData();
    });
  }

  onSort(e: DataTableSortParams) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}}, () => {
      this.loadData();
    });
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  showAddEditDialog() {
    this.setState({showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({showAddEditDialog: false});
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  handleSelectedItems(allItems: SkuDepartment[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: SkuDepartment[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: SkuDepartment[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: SkuDepartment) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  showEditDialog(skuDep: SkuDepartment) {
    this.setState({skuDepartment: skuDep, showAddEditDialog: true});
  }

  render() {
    this.initMenuItems();
    const departmentFilter = (
      <InputText
        name="department"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const skuFilter = (
      <InputText
        name="sku"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const skuBodyTemplate = (rowData: SkuDepartment) => {
      return (
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.sku ?? ''}
          isDynamicMenuItems={false}
          //initMenuItems={() => this.initMenuItems()}
          selectedItems={this.state.selectedItems}
          //handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <a href={'#/'} onClick={() => this.showEditDialog(rowData)}>
            {rowData.sku}
          </a>
        </AppColumnMenuBodyTemplate>
      );
    };

    return (
      <div id="sku_departments_container" className="page-container">
        <TwoDataTable
          id={'sku-departments-table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          //pageSizeIdentifier={'users_page_container'}
          initMenuItems={this.initMenuItems}
          selectionMode="multiple"
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as SkuDepartment[])}
        >
          <Column
            header="sku"
            field="sku"
            body={item => skuBodyTemplate(item)}
            filter
            filterElement={skuFilter}
            //style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="department"
            field="department"
            filter
            filterElement={departmentFilter}
            //style={{width: '180px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditSkuDepartmentDialog
          toast={this.toast}
          sku={this.state.skuDepartment?.sku}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
        />
      </div>
    );
  }
}

export default SkuDepartmentListComponent;
