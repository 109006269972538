import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAppStore} from '@fortawesome/free-brands-svg-icons';
import {
  faSirenOn,
  faUserTag,
  faUsers,
  faPuzzlePiece,
  faCodeBranch,
  faObjectGroup,
  faPlus,
  faMinus,
  faMoneyCheckDollar,
  faCheck,
  faBan,
  faCopy,
  faQuestion,
  faPencil,
  faKey,
  faCalendarAlt,
  faCog,
  faTimes,
  faClone,
} from '@fortawesome/pro-regular-svg-icons';
import {
  TwoAppFrame,
  AppMenuItem,
  AppContext,
  AppMenuItemTemplate,
  AppMenuItemSeparator,
  AuthService,
  ToastService,
  UsersService,
  TwoToast,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import './scss/App.scss';
import ApplicationsService from './services/ApplicationsService';
import ApplicationListComponent from './components/Applications/ApplicationListComponent';
import RolesService from './services/RolesService';
import RoleListComponent from './components/Roles/RoleListComponent';
import UserListComponent from './components/Users/UserListComponent';
import ProductsService from './services/ProductsService';
import ProductListComponent from './components/Products/ProductListComponent';
import UserComponent from './components/User/UserComponent';
import TlesService from './services/TlesService';
import ProductGroupListComponent from './components/ProductGroups/ProductGroupListComponent';
import ProductGroupService from './services/ProductGroupService';
import ProductGroupComponent from './components/ProductGroup/ProductGroupComponent';
import CompaniesService from './services/CompaniesService';
import ApplicationComponent from './components/Application/ApplicationComponent';
import PriceDefinitionRevisionListComponent from './components/PriceDefinitionRevisions/PriceDefinitionRevisionListComponent';
import PriceDefinitionRevisionsService from './services/PriceDefinitionRevisionsService';
import PriceDefinitionRevisionComponent from './components/PriceDefinitionRevision/PriceDefinitionRevisionComponent';
import ProductPriceDefinitionsService from './services/ProductPriceDefinitionsService';
import AdminUsersService from './services/AdminUserService';
import ProductDefinitionRevisionsService from './services/ProductDefinitionRevisionsService';
import OrdersService from './services/OrdersService';
import ProductDefinitionRevisionListComponent from './components/Revisions/ProductDefinitionRevisionListComponent';
import RevisionComponent from './components/Revisions/RevisionComponent';
import RevisionTestComponent from './components/Revisions/RevisionTestComponent';
import ProductDefinitionsService from './services/ProductDefinitionsService';
import SkuDepartmentService from './services/SkuDepartmentService';
import SkuDepartmentListComponent from './components/SkuDepartments/SkuDepartmentListComponent';
import {Toast} from 'primereact/toast';

library.add(
  faSirenOn,
  faAppStore,
  faUserTag,
  faUsers,
  faPuzzlePiece,
  faCodeBranch,
  faObjectGroup,
  faPlus,
  faMinus,
  faMoneyCheckDollar,
  faCheck,
  faBan,
  faCopy,
  faQuestion,
  faPencil,
  faKey,
  faCalendarAlt,
  faCog,
  faTimes,
  faClone
);

const authService = new AuthService();
const applicationsService = new ApplicationsService(authService);
const companiesService = new CompaniesService(authService);
const rolesService = new RolesService(authService);
const tlesService = new TlesService(authService);
const productsService = new ProductsService(authService);
const productGroupService = new ProductGroupService(authService);
const productDefinitionRevisionsService = new ProductDefinitionRevisionsService(authService);
const toastService = new ToastService();
const productPriceDefinitionsService = new ProductPriceDefinitionsService(authService);
const priceDefinitionRevisionsService = new PriceDefinitionRevisionsService(authService);
const usersService = new UsersService(authService);
const adminUsersService = new AdminUsersService(authService);
const ordersService = new OrdersService(authService);
const productDefinitionsService = new ProductDefinitionsService(authService);
const skuDepartmentService = new SkuDepartmentService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);

class App extends React.Component {
  static contextType = AppContext;

  menu: AppMenuItem[];

  constructor(props: {}) {
    super(props);

    this.state = {};

    this.menu = [
      {
        label: 'Alarms',
        faIcon: faSirenOn,
        badgeId: 'alarmBadge',
        to: '/alarms',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Apps',
        faIcon: faAppStore,
        to: '/apps',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      // {
      //   label: 'Roles',
      //   faIcon: faUserTag,
      //   to: '/roles',
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      // },
      {
        label: 'Users',
        faIcon: faUsers,
        to: '/users',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Products',
        faIcon: faPuzzlePiece,
        to: '/products',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Revisions',
        faIcon: faCodeBranch,
        to: '/revisions',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Price Definitions',
        faIcon: faMoneyCheckDollar,
        to: '/price-definitions',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        label: 'Product Groups',
        faIcon: faObjectGroup,
        to: '/product-groups',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Invoice Departments',
        faIcon: faPuzzlePiece,
        to: '/sku-departments',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
    ];
  }

  render() {
    const values = {
      authService: authService,
      applicationsService: applicationsService,
      companiesService: companiesService,
      rolesService: rolesService,
      productsService: productsService,
      productGroupService: productGroupService,
      productDefinitionRevisionsService: productDefinitionRevisionsService,
      toastService: toastService,
      tlesService: tlesService,
      priceDefinitionRevisionsService: priceDefinitionRevisionsService,
      productPriceDefinitionsService: productPriceDefinitionsService,
      usersService: usersService,
      adminUsersService: adminUsersService,
      ordersService: ordersService,
      productDefinitionsService: productDefinitionsService,
      skuDepartmentService: skuDepartmentService,
      twoToast: twoToast,
    };

    return (
      <TwoAppFrame menuItems={this.menu} contextValues={values}>
        <div className="topframe" />
        <>
          <Switch>
            <Route path="/alarms"></Route>
            <Route path="/app/:id">
              <ApplicationComponent />
            </Route>
            <Route path="/apps">
              <ApplicationListComponent />
            </Route>
            <Route path="/roles">
              <RoleListComponent />
            </Route>
            <Route path="/user/:id">
              <UserComponent />
            </Route>
            <Route path="/users">
              <UserListComponent />
            </Route>
            <Route path="/products">
              <ProductListComponent />
            </Route>
            <Route path="/revisions">
              <ProductDefinitionRevisionListComponent />
            </Route>
            <Route path="/revision/:id">
              <RevisionComponent />
            </Route>
            <Route path="/revision-test/:id">
              <RevisionTestComponent />
            </Route>
            <Route path="/product-groups">
              <ProductGroupListComponent />
            </Route>
            <Route path="/product-group/:id">
              <ProductGroupComponent />
            </Route>
            <Route path="/price-definitions">
              <PriceDefinitionRevisionListComponent />
            </Route>
            <Route path="/price-definition/:id">
              <PriceDefinitionRevisionComponent />
            </Route>
            <Route path="/sku-departments">
              <SkuDepartmentListComponent />
            </Route>
          </Switch>
        </>
      </TwoAppFrame>
    );
  }
}

export default App;
