import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Application, Role} from 'two-core';
import {Toast} from 'primereact/toast';
import RolesService from '../../services/RolesService';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  application: Application | undefined;
  role: Role | undefined;
}

interface State {
  loading: boolean;
}

class DeleteRoleDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  rolesService: RolesService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.hideDialog = this.hideDialog.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.rolesService = this.context.rolesService;
    this.toastService = this.context.toastService;
  }

  hideDialog() {
    this.setState({loading: false});
    this.props.onHide();
  }

  async save() {
    const role = this.props.role;
    if (role) {
      this.deleteRole(role);
    }
  }

  deleteRole(role: Role) {
    this.setState({loading: true});

    this.rolesService
      ?.deleteRole(role.id)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Role deleted successfully.');
        MessageService.sendMessage(messages.roleUpdated);
        this.setState({loading: false});
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Role delete failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  render() {
    const {application, role} = this.props;

    const dialogBody = (
      <>
        <div className="p-d-flex p-jc-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
          <span>
            You are about to delete the role &nbsp;
            <span className="p-text-bold">{` ${role?.name}`}</span>, that is still assigned to users of &nbsp;
            <span className="p-text-bold">{` ${application?.name}`}</span>
            &nbsp; application.
          </span>
        </div>
        <div className="p-d-flex p-jc-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
          If you decide to continue anyway, this role will be unassigned from these users.
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Deleting Role'}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={40}
        onHide={this.hideDialog}
        onSave={this.save}
        loading={this.state.loading}
        saveButtonTitle="Continue"
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default DeleteRoleDialog;
