import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  ToastService,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {QueryParameter, Product} from 'two-core';
import config from '../../config/config';
import ProductsService from '../../services/ProductsService';
import {DateTime} from 'luxon';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import AddEditProductDialog from './AddEditProductDialog';
import formats from '../../config/formats';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import ProductGroupService from '../../services/ProductGroupService';

interface Props {
  productGroupId?: number;
  showAssingProductDialog?: () => void;
  setSelectedProducts?: (products: Product[]) => void;
}

interface State {
  loading: boolean;
  items: Product[];
  selectedItems: Product[];
  totalItems: number;
  activeFilters: {};
  filters: {
    id: string;
    name: string;
    key: string;
    enabled: string;
    external_id: string;
    interpreter_key: string;
    updated_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
  productId: number | undefined;
}

class ProductListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  productsService: ProductsService | null = null;
  toastService: ToastService | null = null;
  productGroupService: ProductGroupService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        id: '',
        name: '',
        key: '',
        enabled: '',
        external_id: '',
        interpreter_key: '',
        updated_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddEditDialog: false,
      productId: undefined,
    };

    this.idBodyTemplate = this.idBodyTemplate.bind(this);
    this.enabledBodyTemplate = this.enabledBodyTemplate.bind(this);
    this.updatedAtBodyTemplate = this.updatedAtBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.showAddDialog = this.showAddDialog.bind(this);
    this.showEditDialog = this.showEditDialog.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
    this.duplicateProduct = this.duplicateProduct.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.setChangeSelectedItem = this.setChangeSelectedItem.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.productsService = this.context.productsService;
    this.toastService = this.context.toastService;
    this.productGroupService = this.context.productGroupService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.productUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const selectedItems = this.state.selectedItems;
    const selectedItemsCount = selectedItems.length;

    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddDialog();
      },
    });

    if (selectedItemsCount === 1) {
      menuItems.push({
        label: 'Duplicate',
        faIcon: ['far', 'copy'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.duplicateProduct();
        },
      });
    }

    if (this.props.productGroupId) {
      menuItems.push({
        label: 'Assign New',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          if (this.props.showAssingProductDialog) {
            this.props.showAssingProductDialog();
          }
        },
      });
      if (selectedItemsCount > 0) {
        menuItems.push({
          label: 'Un-Assign',
          faIcon: ['far', 'minus'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.unAssignProductGroup();
          },
        });
      }
    }

    menuItems.push({
      separator: true,
    });

    if (selectedItemsCount > 0) {
      if (selectedItems.every(p => p.enabled)) {
        menuItems.push({
          label: 'Disable',
          faIcon: ['far', 'ban'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.disableEnableProducts(false);
          },
        });
      }

      if (selectedItems.every(p => !p.enabled)) {
        menuItems.push({
          label: 'Enable',
          faIcon: ['far', 'check'],
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => {
            this.disableEnableProducts(true);
          },
        });
      }
    }

    return menuItems;
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.id) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.id,
        })
      );
    }
    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.interpreter_key) {
      filters.push(
        JSON.stringify({
          field: 'interpreter_key',
          value: this.state.filters.interpreter_key,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.external_id) {
      filters.push(
        JSON.stringify({
          field: 'external_id',
          value: this.state.filters.external_id,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.enabled) {
      filters.push(
        JSON.stringify({
          field: 'enabled',
          value: this.state.filters.enabled,
        })
      );
    }

    if (this.state.filters.updated_at.fromDate) {
      const fromDate = this.state.filters.updated_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'updated_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.updated_at.toDate) {
      const toDate = this.state.filters.updated_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'updated_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.props.productGroupId) {
      filters.push(
        JSON.stringify({
          field: 'product_to_group.product_group_id',
          value: this.props.productGroupId,
          condition: '=',
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'id',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.productsService
      ?.getProducts(params)
      .then(data => {
        const products = data.records as Product[];

        this.setState({
          items: products,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  unAssignProductGroup() {
    const productGroupId = this.props.productGroupId as number;
    const products = this.state.selectedItems;
    Promise.all(
      products.map(async product => {
        this.productGroupService
          ?.deleteProductGroupProduct(productGroupId, product.id as number)
          .then(() => {
            this.toastService?.showSuccess(this.toast, 'Product from Product Group deleted successfully.');
          })
          .catch(error => {
            this.toastService?.showError(
              this.toast,
              'Sorry, delete Product from ProductGroup failed, please try again.'
            );
            console.error(error);
          });
      })
    ).then(() => {
      MessageService.sendMessage(messages.productGroupProductUpdated);
    });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  showAddDialog() {
    this.setState({productId: undefined, showAddEditDialog: true});
  }

  showEditDialog(product: Product) {
    this.setState({productId: product.id, showAddEditDialog: true});
  }

  hideAddEditDialog() {
    this.setState({
      productId: undefined,
      showAddEditDialog: false,
      selectedItems: [],
    });
  }

  duplicateProduct() {
    const items = this.state.selectedItems;
    const product = items[0];
    if (product) {
      const newProduct = {
        ...product,
        id: undefined,
      };
      this.createProduct(newProduct);
    } else {
      this.toastService?.showError(this.toast, 'Sorry, Product duplicate failed, please try again.');
    }
  }

  async createProduct(product: Product) {
    this.setState({loading: true});

    return this.productsService
      ?.createProduct(product)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Product created successfully.');
        MessageService.sendMessage(messages.productUpdated);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Product create failed, please try again.');
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  async disableEnableProducts(enable: boolean) {
    this.setState({loading: true});
    const products: Product[] = this.state.selectedItems;

    Promise.all(
      products.map((product: Product) => {
        const updatedProduct: Product = {
          ...product,
          enabled: enable,
        };
        return this.saveProduct(updatedProduct);
      })
    )
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Products update successfully.');
        this.setState({loading: false, selectedItems: []});
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Products update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async saveProduct(product: Product) {
    return this.productsService?.updateProduct(product?.id?.toString() ?? '', product);
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  setChangeSelectedItems(items: Product[]) {
    this.setState({selectedItems: items});
    if (this.props.setSelectedProducts) {
      this.props.setSelectedProducts(items);
    }
  }

  async setChangeSelectedItem(item: Product) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
      if (this.props.setSelectedProducts) {
        this.props.setSelectedProducts(items);
      }
    }
  }

  idBodyTemplate(rowData: Product) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <a href={'#/'} onClick={() => this.showEditDialog(rowData)}>
          {rowData.id}
        </a>
      </AppColumnMenuBodyTemplate>
    );
  }

  enabledBodyTemplate(rowData: Product) {
    const enabledText = rowData.enabled ? 'true' : 'false';
    return <span>{enabledText}</span>;
  }

  updatedAtBodyTemplate(rowData: Product) {
    const formattedUpdated = rowData.updated_at
      ? DateTime.fromISO(rowData.updated_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedUpdated}</span>;
  }

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const idFilter = (
      <InputText
        name="id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const keyFilter = (
      <InputText
        name="interpreter_key"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const enabledFilter = (
      <InputText
        name="enabled"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const externalIdFilter = (
      <InputText
        name="external_id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const updatedAtFilter = (
      <DateColumnFilter
        name="updated_at"
        value={this.state.filters.updated_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    return (
      <div id="products_page_container" className="page-container">
        <TwoDataTable
          id={'products-table'}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'products_page_container'}
          initMenuItems={this.initMenuItems}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Product[])}
          selectionMode="multiple"
        >
          <Column
            header="Id"
            field="id"
            body={item => this.idBodyTemplate(item)}
            filter
            filterElement={idFilter}
            style={{width: '100px'}}
            sortable
            showFilterMenu={false}
          />

          <Column
            header="Name"
            field="name"
            filter
            filterElement={nameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Key"
            field="interpreter_key"
            filter
            filterElement={keyFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="External Id"
            field="external_id"
            filter
            filterElement={externalIdFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Enabled"
            field="enabled"
            filter
            filterElement={enabledFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.enabledBodyTemplate}
          />
          <Column
            header="Updated"
            field="updated_at"
            filter
            filterElement={updatedAtFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={this.updatedAtBodyTemplate}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddEditProductDialog
          toast={this.toast}
          productId={this.state.productId}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
        />
      </div>
    );
  }
}

export default ProductListComponent;
