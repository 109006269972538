import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {ToastService, AppContext, MessageService} from 'two-app-ui';
import {faPlus, faPencil, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import {
  QueryParameter,
  ProductPriceDefinition,
  DropdownOption,
  ProductPriceFreightSurcharge,
  ProductPriceDefinitionItem,
  ProductPriceDefinitionPriceItem,
  ProductPriceItem,
  Product,
} from 'two-core';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import ProductPriceDefinitionsService from '../../services/ProductPriceDefinitionsService';
import {Tree, TreeExpandedKeysType, TreeNodeTemplateOptions} from 'primereact/tree';
import TreeNode from 'primereact/treenode';
import {Button} from 'primereact/button';
import {
  ProductPriceDefinitionTreeItem,
  ProductPriceDefinitionTreeItemButtonData,
} from './Models/ProductPriceDefinitionTreeItem';
import EditProductPriceDialog from './EditProductPriceDialog';
import EditFreightSurchargeDialog from './EditFreightSurchargeDialog';
import AddEditSurchargeDialog from './AddEditSurchargeDialog';
import AddEditDiscountDialog from './AddEditDiscountDialog';
import ProductsService from '../../services/ProductsService';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './ProductPriceDefinitionTreeComponent.scss';

interface Props {
  priceDefinitionRevisionId: number;
}

interface State {
  loading: boolean;
  showEditProductPrice: boolean;
  showAddEditDiscount: boolean;
  showEditFreightSurcharge: boolean;
  showAddEditSurcharge: boolean;
  productPriceDefinitions: ProductPriceDefinition[];
  productPriceItemToEdit: ProductPriceItem | undefined;
  productPriceFreightSurchargeToEdit: ProductPriceFreightSurcharge | undefined;
  currentProduct: DropdownOption | undefined;
  currentProductPriceDefinition: ProductPriceDefinition | undefined;
  products: Product[];
  productOptions: DropdownOption[];
  expandedKeys: TreeExpandedKeysType;
  treeData: TreeNode[];
  productPriceDefinitionPriceItemToEdit: ProductPriceDefinitionPriceItem | undefined;
  productPriceDefinitionPriceItemToEditIndex: number | undefined;
}

class ProductPriceDefinitionTreeComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  productPriceDefinitionsService: ProductPriceDefinitionsService | null = null;
  productsService: ProductsService | null = null;
  toastService: ToastService | null = null;
  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      showEditProductPrice: false,
      showAddEditDiscount: false,
      showEditFreightSurcharge: false,
      showAddEditSurcharge: false,
      productPriceDefinitions: [],
      productPriceItemToEdit: undefined,
      productPriceFreightSurchargeToEdit: undefined,
      currentProduct: undefined,
      currentProductPriceDefinition: undefined,
      products: [],
      productOptions: [],
      expandedKeys: {},
      treeData: [],
      productPriceDefinitionPriceItemToEdit: undefined,
      productPriceDefinitionPriceItemToEditIndex: undefined,
    };

    this.showEditProductPrice = this.showEditProductPrice.bind(this);
    this.showAddSurcharge = this.showAddSurcharge.bind(this);
    this.showEditSurcharge = this.showEditSurcharge.bind(this);
    this.showAddDiscount = this.showAddDiscount.bind(this);
    this.showEditDiscount = this.showEditDiscount.bind(this);
    this.showEditFreightSurcharge = this.showEditFreightSurcharge.bind(this);
    this.onDropdownProductChange = this.onDropdownProductChange.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.productsService = this.context.productsService;
    this.productPriceDefinitionsService = this.context.productPriceDefinitionsService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.productPriceDefinitionUpdated) {
        this.loadData();
      }
    });

    await this.loadProducts();
    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.props.priceDefinitionRevisionId) {
      filters.push(
        JSON.stringify({
          field: 'revision_id',
          value: this.props.priceDefinitionRevisionId,
        })
      );
    }

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.productPriceDefinitionsService
      ?.getProductPriceDefinitions(params)
      .then(async data => {
        const productPriceDefinitions = data.records as ProductPriceDefinition[];
        const productOptions: DropdownOption[] = [];
        productPriceDefinitions.map(productDefinition => {
          productOptions.push({
            label: productDefinition.product ? productDefinition.product.name : '',
            value: productDefinition.product_id,
          });
        });

        await this.setState({
          productPriceDefinitions: productPriceDefinitions,
          productOptions: productOptions,
          currentProduct:
            !this.state.currentProduct && productOptions && productOptions.length > 0
              ? productOptions[0]
              : this.state.currentProduct,
          loading: false,
        });
        this.prepareTree();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadProducts() {
    this.setState({loading: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'enabled',
        value: true,
        condition: '=',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.productsService?.getProducts(params).then(data => {
      const products = (data?.records as Product[]) ?? [];
      this.setState({
        loading: false,
        products: products,
      });
    });
  }

  prepareTree() {
    let tree: TreeNode[] = [];
    const productPriceDefinition: ProductPriceDefinition | undefined = this.state.productPriceDefinitions.find(
      definition => definition.product_id === this.state.currentProduct?.value
    );
    if (!productPriceDefinition) {
      return;
    }
    const priceDefinitionItem: ProductPriceDefinitionItem = productPriceDefinition.price_definitions;

    const productPrice: TreeNode = this.createTreeNode(
      '0',
      'Product Price',
      this.prepareData('edit-product-price', priceDefinitionItem.product_price),
      []
    );

    const surchargesChildrens: TreeNode[] = [];
    priceDefinitionItem.surcharges.forEach((value, index) => {
      const productIdList = value.group_with.map(r => r.product_id);
      const products = this.state.products.filter(product => productIdList.includes(product.id as number));
      const productSurchargesNames = products.map(p => p.name).join(', ');
      surchargesChildrens.push(
        this.createTreeNode(
          `1-${index}`,
          `[${value.title}] [${value.sku}] [${value.scope}] [${productSurchargesNames}] `,
          this.prepareData('edit-remove-surcharge', value as ProductPriceDefinitionPriceItem, index),
          []
        )
      );
    });
    const surcharges: TreeNode = this.createTreeNode(
      '1',
      'Surcharges',
      this.prepareData('add-surcharge'),
      surchargesChildrens
    );

    const discountsChildrens: TreeNode[] = [];
    priceDefinitionItem.discounts.forEach((value, index) => {
      const productIdList = value.group_with.map(r => r.product_id);
      const products = this.state.products.filter(product => productIdList.includes(product.id as number));
      const productDiscountsNames = products.map(p => p.name).join(', ');
      discountsChildrens.push(
        this.createTreeNode(
          `1-${index}`,
          `[${value.title}] [${value.sku}] [${value.scope}] [${productDiscountsNames}] `,
          this.prepareData('edit-remove-discount', value as ProductPriceDefinitionPriceItem, index),
          []
        )
      );
    });
    const discounts = this.createTreeNode('2', 'Discounts', this.prepareData('add-discount'), discountsChildrens);
    const freight_surcharge = this.createTreeNode(
      '3',
      'Freight',
      this.prepareData('edit-freight-surcharge', priceDefinitionItem.freight_surcharge),
      []
    );
    tree = [productPrice, surcharges, discounts, freight_surcharge];
    this.setState({
      currentProductPriceDefinition: productPriceDefinition,
      treeData: tree,
    });
  }

  prepareData(
    type:
      | 'edit-product-price'
      | 'edit-freight-surcharge'
      | 'add-discount'
      | 'add-surcharge'
      | 'edit-remove-discount'
      | 'edit-remove-surcharge',
    data?: ProductPriceItem | ProductPriceDefinitionPriceItem | ProductPriceFreightSurcharge | undefined,
    index?: number
  ): ProductPriceDefinitionTreeItem {
    let labelStyle: string;
    const buttonAddData: ProductPriceDefinitionTreeItemButtonData = {};
    const buttonEditData: ProductPriceDefinitionTreeItemButtonData = {};
    const buttonRemoveData: ProductPriceDefinitionTreeItemButtonData = {};
    switch (type) {
      case 'edit-product-price':
        labelStyle = 'p-col-12 p-md-2';
        buttonEditData.label = 'Edit product price';
        buttonEditData.action = () => this.showEditProductPrice(data as ProductPriceItem);
        break;
      case 'add-surcharge':
        labelStyle = 'p-col-12 p-md-2';
        buttonAddData.label = 'Add surcharge';
        buttonAddData.action = () => this.showAddSurcharge();
        break;
      case 'edit-remove-surcharge':
        labelStyle = 'p-col-12 p-md-6';
        buttonEditData.label = 'Edit';
        buttonEditData.action = () => this.showEditSurcharge(data as ProductPriceDefinitionPriceItem, index as number);
        buttonRemoveData.label = 'Remove';
        buttonRemoveData.action = () => this.removeSurcharge(index as number);
        break;
      case 'add-discount':
        labelStyle = 'p-col-12 p-md-2';
        buttonAddData.label = 'Add discount';
        buttonAddData.action = () => this.showAddDiscount();
        break;
      case 'edit-remove-discount':
        labelStyle = 'p-col-12 p-md-6';
        buttonEditData.label = 'Edit';
        buttonEditData.action = () => this.showEditDiscount(data as ProductPriceDefinitionPriceItem, index as number);
        buttonRemoveData.label = 'Remove';
        buttonRemoveData.action = () => this.removeDiscount(index as number);
        break;
      case 'edit-freight-surcharge':
        labelStyle = 'p-col-12 p-md-2';
        buttonEditData.label = 'Edit freight surcharge';
        buttonEditData.action = () => this.showEditFreightSurcharge(data as ProductPriceFreightSurcharge);
        break;
    }

    const buttonAdd = buttonAddData.label ? (
      <Button onClick={buttonAddData.action} className={'p-ml-auto'}>
        <FontAwesomeIcon icon={faPlus} />
        <span className="p-button-label p-c">{buttonAddData.label}</span>
      </Button>
    ) : undefined;
    const buttonEdit = buttonEditData.label ? (
      <Button onClick={buttonEditData.action} className={'p-ml-auto'}>
        <FontAwesomeIcon icon={faPencil} />
        <span className="p-button-label p-c">{buttonEditData.label}</span>
      </Button>
    ) : undefined;
    const buttonRemove = buttonRemoveData.label ? (
      <Button onClick={buttonRemoveData.action} className={'p-ml-auto'}>
        <FontAwesomeIcon icon={faTrashCan} />
        <span className="p-button-label p-c">{buttonRemoveData.label}</span>
      </Button>
    ) : undefined;
    return {
      item: data,
      labelStyle: labelStyle,
      buttonAdd: buttonAdd,
      buttonEdit: buttonEdit,
      buttonRemove: buttonRemove,
    };
  }

  createTreeNode(key: string, label: string, data: ProductPriceDefinitionTreeItem, childrens: TreeNode[]): TreeNode {
    return {
      key: key,
      label: label,
      data: data,
      children: childrens,
    };
  }

  async onDropdownProductChange(e: DropdownChangeParams) {
    const currentProduct = this.state.productOptions.find(c => c.value === e.value);
    await this.setState({currentProduct: currentProduct, expandedKeys: {}});
    this.loadData();
  }

  showEditProductPrice(productPriceItemToEdit: ProductPriceItem) {
    this.setState({
      showEditProductPrice: true,
      productPriceItemToEdit: productPriceItemToEdit,
    });
  }

  showAddSurcharge() {
    this.setState({
      showAddEditSurcharge: true,
      productPriceDefinitionPriceItemToEdit: {
        title: '',
        sku: '',
        scope: '',
        group_with: [],
        price_list_type: '',
        when_condition: '',
        description_script: '',
        qty_calculation: '',
        unit_script: '',
        unit_price_calculation: '',
        line_total_calculation: '',
      },
      productPriceDefinitionPriceItemToEditIndex: undefined,
    });
  }

  showEditSurcharge(data: ProductPriceDefinitionPriceItem, index: number) {
    this.setState({
      showAddEditSurcharge: true,
      productPriceDefinitionPriceItemToEdit: data,
      productPriceDefinitionPriceItemToEditIndex: index,
    });
  }

  removeSurcharge(index: number) {
    const productPriceDefinition: ProductPriceDefinition = this.state
      .currentProductPriceDefinition as ProductPriceDefinition;
    productPriceDefinition.price_definitions.surcharges.splice(index, 1);
    this.updateProductPriceDefinition(
      productPriceDefinition,
      'Surcharge removed.',
      'Surcharge remove failed, please try again.'
    );
  }

  showAddDiscount() {
    this.setState({
      showAddEditDiscount: true,
      productPriceDefinitionPriceItemToEdit: {
        title: '',
        sku: '',
        scope: '',
        group_with: [],
        price_list_type: '',
        when_condition: '',
        description_script: '',
        qty_calculation: '',
        unit_script: '',
        unit_price_calculation: '',
        line_total_calculation: '',
      },
      productPriceDefinitionPriceItemToEditIndex: undefined,
    });
  }

  showEditDiscount(data: ProductPriceDefinitionPriceItem, index: number) {
    this.setState({
      showAddEditDiscount: true,
      productPriceDefinitionPriceItemToEdit: data,
      productPriceDefinitionPriceItemToEditIndex: index,
    });
  }

  removeDiscount(index: number) {
    const productPriceDefinition: ProductPriceDefinition = this.state
      .currentProductPriceDefinition as ProductPriceDefinition;
    productPriceDefinition.price_definitions.discounts.splice(index, 1);
    this.updateProductPriceDefinition(
      productPriceDefinition,
      'Discount removed.',
      'Discount remove failed, please try again.'
    );
  }

  updateProductPriceDefinition(
    productPriceDefinition: ProductPriceDefinition,
    successMessage: string,
    failureMessage: string
  ) {
    this.productPriceDefinitionsService
      ?.updateProductPriceDefinition(productPriceDefinition)
      .then(() => {
        this.toastService?.showSuccess(this.toast, successMessage);
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(this.toast, failureMessage);
        console.error(error);
      });
  }

  showEditFreightSurcharge(productPriceFreightSurchargeToEdit: ProductPriceFreightSurcharge) {
    this.setState({
      showEditFreightSurcharge: true,
      productPriceFreightSurchargeToEdit: productPriceFreightSurchargeToEdit,
    });
  }

  render() {
    const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
      const data: ProductPriceDefinitionTreeItem = node.data;

      const label = <b>{node.label}</b>;
      return (
        <div className={'p-d-flex w-100'}>
          <span className={options.className + ' ' + data.labelStyle}>{label}</span>
          {data.buttonAdd ? <div className={'p-col-12 p-md-3'}>{data.buttonAdd}</div> : ''}
          {data.buttonEdit ? <div className={'p-col-12 p-md-3'}>{data.buttonEdit}</div> : ''}
          {data.buttonRemove ? <div className={'p-col-12 p-md-3'}>{data.buttonRemove}</div> : ''}
        </div>
      );
    };

    const {
      expandedKeys,
      treeData,
      showEditProductPrice,
      showEditFreightSurcharge,
      showAddEditSurcharge,
      showAddEditDiscount,
      productPriceItemToEdit,
      productPriceFreightSurchargeToEdit,
      productPriceDefinitionPriceItemToEdit,
      productPriceDefinitionPriceItemToEditIndex,
      currentProductPriceDefinition,
    } = this.state;
    return (
      <div id="product_price_definition_container" className="page-container">
        <div className="topframe">
          <div className="dropdown-top">
            <Dropdown
              id={'dropdown-product-price-definition'}
              filter
              showClear
              value={this.state.currentProduct?.value}
              options={this.state.productOptions}
              onChange={this.onDropdownProductChange}
              placeholder="Select option"
              filterPlaceholder="Filter by name"
              optionLabel="label"
              optionValue="value"
            />
          </div>

          <div className="p-fluid">
            <Tree
              value={treeData}
              expandedKeys={expandedKeys}
              nodeTemplate={nodeTemplate}
              onToggle={e => this.setState({expandedKeys: e.value})}
            />
          </div>
        </div>
        <EditProductPriceDialog
          showDialog={showEditProductPrice}
          toast={this.toast}
          onHide={() => this.setState({showEditProductPrice: false})}
          productPriceItem={productPriceItemToEdit as ProductPriceItem}
          currentProductPriceDefinition={currentProductPriceDefinition as ProductPriceDefinition}
        />
        <EditFreightSurchargeDialog
          showDialog={showEditFreightSurcharge}
          onHide={() => this.setState({showEditFreightSurcharge: false})}
          toast={this.toast}
          currentProductPriceDefinition={currentProductPriceDefinition as ProductPriceDefinition}
          productPriceFreightSurcharge={productPriceFreightSurchargeToEdit as ProductPriceFreightSurcharge}
        />
        <AddEditSurchargeDialog
          showDialog={showAddEditSurcharge}
          onHide={() =>
            this.setState({
              showAddEditSurcharge: false,
              productPriceDefinitionPriceItemToEdit: undefined,
            })
          }
          toast={this.toast}
          currentProductPriceDefinition={currentProductPriceDefinition as ProductPriceDefinition}
          index={productPriceDefinitionPriceItemToEditIndex as number}
          productPriceDefinitionPriceItem={productPriceDefinitionPriceItemToEdit as ProductPriceDefinitionPriceItem}
        />
        <AddEditDiscountDialog
          showDialog={showAddEditDiscount}
          onHide={() =>
            this.setState({
              showAddEditDiscount: false,
              productPriceDefinitionPriceItemToEdit: undefined,
            })
          }
          toast={this.toast}
          currentProductPriceDefinition={currentProductPriceDefinition as ProductPriceDefinition}
          index={productPriceDefinitionPriceItemToEditIndex as number}
          productPriceDefinitionPriceItem={productPriceDefinitionPriceItemToEdit as ProductPriceDefinitionPriceItem}
        />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default ProductPriceDefinitionTreeComponent;
