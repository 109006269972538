import {DateTime} from 'luxon';
import React from 'react';
import {ProductDefinitionRevision} from 'two-core/build/cjs/src/product-definition';
import formats from '../../config/formats';

interface Props {
  revision: ProductDefinitionRevision;
}

class RevisionDetail extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {revision} = this.props;

    return (
      <>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">stage</label>
          <div className="p-col-12 p-md-9">
            <span>{revision.stage}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">external id</label>
          <div className="p-col-12 p-md-9">
            <span>{revision.external_id}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">created</label>
          <div className="p-col-12 p-md-9">
            <span>
              {revision.created_at ? DateTime.fromISO(revision.created_at.toString()).toFormat(formats.date) : ''}
            </span>
          </div>
        </div>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">last update</label>
          <div className="p-col-12 p-md-9">
            <span>
              {revision.updated_at ? DateTime.fromISO(revision.updated_at.toString()).toFormat(formats.date) : ''}
            </span>
          </div>
        </div>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">release</label>
          <div className="p-col-12 p-md-9">
            <span>
              {revision.released_at ? DateTime.fromISO(revision.released_at.toString()).toFormat(formats.date) : ''}
            </span>
          </div>
        </div>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">release by</label>
          <div className="p-col-12 p-md-9">
            <span>{revision.released_by?.full_name}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label className="p-col-12 p-md-3">release notes</label>
          <div className="p-col-12 p-md-9">
            <span>{revision.release_notes}</span>
          </div>
        </div>
      </>
    );
  }
}

export default RevisionDetail;
